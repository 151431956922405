import React from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { updateUserProfile } from "../../../features/slices/userProfileSlice";
import ShowError from "../../../errors/ShowError";
import Swal from "sweetalert2";

import * as Yup from "yup";
// const confirmDelete = withReactContent(Swal);

const PersonalDetailsForm = ({ userData }) => {
  const dispatch = useDispatch();

  const onSaveClick = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Profile has been saved",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const rx = "/[A-Z]{5}[0-9]{4}[A-Z]{1}$/";
  const regex = "^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$";
  const gstRegex = "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$";
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const userValidationSchema = Yup.object({
    panNumber: Yup.string()
      .matches(rx, "Pan number is not valid")
      .min(10, "Pan number is too short")
      .max(10, "Pan number is too long"),
    adharNumber: Yup.string()
      .matches(regex, "Aadhar number is not valid")
      .min(12, "Aadhar number is too short")
      .max(12, "Aadhar number is too long"),
    gstNumber: Yup.string()
      .matches(gstRegex, "GST Number is not valid")
      .min(12, "GST number is too short")
      .max(12, "GST number is too long"),
    mobile_number: Yup.string()
      .required("Mobile number is Required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Mobile number is too short")
      .max(10, "Mobile number is too long"),
  });

  const {
    name,
    dob,
    gender,
    aadhaar_number,
    gst_number,
    pancard_number,
    mobile_number,
  } = userData;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name || "",
      dob: dob || "",
      gender: gender || "",
      pancard_number: pancard_number || "",
      aadhaar_number: aadhaar_number || "",
      gst_number: gst_number || "",
      mobile_number: mobile_number || "",
    },
    validationSchema: userValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(updateUserProfile(values));
      onSaveClick();

      setSubmitting(false);
    },
  });

  return (
    <React.Fragment>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-3">
            <p>Personal Details</p>
          </div>
          <div className="col-sm-9">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Full Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="date"
                name="dob"
                placeholder="Date Of Birth"
                value={formik.values.dob}
                onChange={formik.handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="mobile_number"
                placeholder="Mobile Number"
                value={formik.values.mobile_number}
                onChange={formik.handleChange}
              />
              {formik.touched.mobile_number && formik.errors.mobile_number && (
                <ShowError>{formik.errors.mobile_number}</ShowError>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="pancard_number"
                placeholder="PAN Number"
                value={formik.values.pancard_number}
                onChange={formik.handleChange}
              />

              {formik.touched.pancard_number &&
                formik.errors.pancard_number && (
                  <ShowError>{formik.errors.pancard_number}</ShowError>
                )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="aadhaar_number"
                placeholder="Aadhar Number "
                value={formik.values.aadhaar_number}
                onChange={formik.handleChange}
              />
              {formik.touched.aadhaar_number &&
                formik.errors.aadhaar_number && (
                  <ShowError>{formik.errors.aadhaar_number}</ShowError>
                )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="gst_number"
                placeholder="GST Number "
                value={formik.values.gst_number}
                onChange={formik.handleChange}
              />
              {formik.touched.gst_number && formik.errors.gst_number && (
                <ShowError>{formik.errors.gst_number}</ShowError>
              )}
            </Form.Group>

            <div className="custom_radio">
              <p className="img-fluid">Gender</p>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div
                    className="custom-control custom-radio custom-control-inline mr-0"
                    role="group"
                  >
                    <RadioButton
                      label="Male"
                      name="gender"
                      value="Male"
                      checked={formik.values.gender === "Male"}
                      handleChange={formik.setFieldValue}
                    />
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="custom-control custom-radio custom-control-inline mr-0">
                    <RadioButton
                      label="Female"
                      name="gender"
                      value="Female"
                      checked={formik.values.gender === "Female"}
                      handleChange={formik.setFieldValue}
                    />
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="custom-control custom-radio custom-control-inline mr-0">
                    <RadioButton
                      label="Prefer not to say "
                      name="gender"
                      value="other"
                      checked={formik.values.gender === "other"}
                      handleChange={formik.setFieldValue}
                    />
                  </div>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-lg-12">
                  <div className="btn_address">
                    <a href="#" className="btn btn-cancel">
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-success">
                      save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default PersonalDetailsForm;
