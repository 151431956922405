import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PaymentService from "../services/PaymentService";

export const getAllPaymentByUser = createAsyncThunk(
  "payment/getAllPaymentByUser",
  async () => {
    try {
      const res = await PaymentService.getPaymentServiceByUser();

      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const userServicePayment = createAsyncThunk(
  "payment/userServicePayment",
  async (data) => {
    try {
      const res = await PaymentService.userPayment(data);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const userServicePaymentConfirm = createAsyncThunk(
  "payment/userServicePaymentConfirm",
  async (data) => {
    try {
      const res = await PaymentService.userPaymentConfirm(data);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPaymentServiceByUserCompleted = createAsyncThunk(
  "payment/getPaymentServiceByUserCompleted",
  async () => {
    try {
      const res = await PaymentService.getPaymentServiceByUserCompleted();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPaymentServiceByUserClosed = createAsyncThunk(
  "payment/getPaymentServiceByUserClosed",
  async () => {
    try {
      const res = await PaymentService.getPaymentServiceByUserClosed();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getServiceByUserFav = createAsyncThunk(
  "payment/getServiceByUserFav",
  async () => {
    try {
      const res = await PaymentService.getServiceByUserFav();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCompletePaymentsList = createAsyncThunk(
  "payment/getCompletePaymentsList",
  async () => {
    try {
      const res = await PaymentService.getCompletePaymentsList();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPendingPaymentList = createAsyncThunk(
  "payment/getPendingPaymentList",
  async () => {
    try {
      const res = await PaymentService.getPendingPaymentList();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserBuyPlans = createAsyncThunk(
  "payment/getUserBuyPlans",
  async () => {
    try {
      const res = await PaymentService.getUserBuyPlans();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  payment: null || {},
  userServiceList: [],
  userServiceListCompleted: [],
  userServiceListClosed: [],
  userServiceListFav: [],
  userServiceCompleteList: [],
  userPendingPaymentList: [],
  userBuyPlans: [],
  loading: false,
  error: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllPaymentByUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPaymentByUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userServiceList = payload;
      })
      .addCase(getAllPaymentByUser.rejected, (state) => {
        state.loading = false;
      });

    // USER SERVICE  PAYMENT
    builder
      .addCase(userServicePayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(userServicePayment.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.payment = payload;
      })
      .addCase(userServicePayment.rejected, (state) => {
        state.loading = false;
      });

    //USER SERVICE  PAYMENT CONFIRM
    builder
      .addCase(userServicePaymentConfirm.pending, (state) => {
        state.loading = true;
      })
      .addCase(userServicePaymentConfirm.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.payment = payload;
      })
      .addCase(userServicePaymentConfirm.rejected, (state) => {
        state.loading = false;
      });

    // USER SERVICE  COMPLETED
    builder
      .addCase(getPaymentServiceByUserCompleted.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getPaymentServiceByUserCompleted.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.userServiceListCompleted = payload;
        }
      )
      .addCase(getPaymentServiceByUserCompleted.rejected, (state) => {
        state.loading = false;
      });

    // USER SERVICE  CLOSED
    builder
      .addCase(getPaymentServiceByUserClosed.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getPaymentServiceByUserClosed.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.userServiceListClosed = payload;
        }
      )
      .addCase(getPaymentServiceByUserClosed.rejected, (state) => {
        state.loading = false;
      });

    // USER SERVICE  FAV
    builder
      .addCase(getServiceByUserFav.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServiceByUserFav.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userServiceListFav = payload;
      })
      .addCase(getServiceByUserFav.rejected, (state) => {
        state.loading = false;
      });

    //Payments LIST  completes

    builder
      .addCase(getCompletePaymentsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompletePaymentsList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userServiceCompleteList = payload;
      })
      .addCase(getCompletePaymentsList.rejected, (state) => {
        state.loading = false;
      });

    //Payments LIST PENDING And PENDING

    builder
      .addCase(getPendingPaymentList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPendingPaymentList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userPendingPaymentList = payload;
      })
      .addCase(getPendingPaymentList.rejected, (state) => {
        state.loading = false;
      });

    //USER BUY PLANS

    builder
      .addCase(getUserBuyPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserBuyPlans.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userBuyPlans = payload;
      })
      .addCase(getUserBuyPlans.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default paymentSlice.reducer;
