import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ShowError from "../../../errors/ShowError";
import login from "../../../assets/images/login.png";
import { Link, useNavigate } from "react-router-dom";
import {
  authLogin,
  resetError,
  googleLoginService,
} from "../../../features/slices/authSlice";
import { loginValidationSchema } from "../../../helpers/validators/loginSchema";
import { Button, Form } from "react-bootstrap";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import googleLogo from "../../../assets/images/google.png";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  const { settings } = useSelector(state => state.home);
  const { error, token, errorCode } = useSelector(state => state.auth);

  const { header_logo } = settings;

  const tkn = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetError());
  }, [dispatch]);

  // useEffect(()=>{
  //   setInterval(() => {
  //     if (!tkn) {
  //       navigate("/login");
  //     } else {
  //       navigate("/dashboard");
  //       window.location.reload();
  //     }
  //   }, 1500);
  // }, [tkn])

  useEffect(() => {
    setInterval(() => {
      if (tkn && role === "Customer") {
        navigate("/dashboard");
        window.location.reload();
      }
      if (role === "Admin" || role === "Sub Admin" || role === "Partner") {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        navigate("/login");
      }
    }, 1500);
  }, [tkn]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      await dispatch(authLogin(values));
      setSubmitting(false);
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      setUser(codeResponse);
    },
    onError: error => console.log("Login Failed:", error),
  });

  async function googleApiCall() {
    try {
      const res = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      );
      setProfile(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user) {
      googleApiCall();
    }
  }, [user]);

  async function googleApiCallProfile(data) {
    await dispatch(googleLoginService(data));
  }

  useEffect(() => {
    if (profile) {
      googleApiCallProfile(profile);
    }
  }, [profile]);

  return (
    <section className="login_sec">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 text-center ">
            <div className="logo">
              <img
                src={process.env.REACT_APP_IMAGE_URL + header_logo}
                className="img-fluid"
                alt="logo"
              />
            </div>

            <div className="login_info">
              <div className="col-lg-9 mx-auto">
                <h1>Welcome back!</h1>
                <p>
                  Don't have an account?{" "}
                  <Link to="/register">Create an account</Link>
                </p>

                <Form onSubmit={formik.handleSubmit} method="POST">
                  <div className="row">
                    <div className="col-lg-12">
                      <Form.Group className="form-group">
                        <Form.Control
                          type="email"
                          id="email"
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          placeholder="Email"
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <ShowError>{formik.errors.email}</ShowError>
                        )}
                      </Form.Group>

                      <div className="form-group">
                        <Form.Control
                          type="password"
                          id="password"
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          placeholder="Password"
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <ShowError>{formik.errors.password}</ShowError>
                        )}
                      </div>
                      {error && (
                        <div className="text-danger text-left">{error}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-12 col-lg-6">
                      <Form.Group className="mb-3 custom_checkbox">
                        <Form.Check
                          className="p-0"
                          type="checkbox"
                          label="Remember Me"
                          // name="isRemember"
                          onChange={e =>
                            formik.setFieldValue("isRemember", e.target.checked)
                          }
                          checked={formik.values.isRemember}
                        />
                      </Form.Group>
                    </div> */}

                    <div className="col-12 col-lg-6">
                      <Link to="/forgot" className="forgot_link">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>

                  <button className="btn btn-success" type="submit">
                    login
                  </button>
                </Form>
              </div>
            </div>

            <div className="m-3">
              <button
                className="btn btn-outline-success"
                onClick={() => googleLogin()}
              >
                {/* <i className="fa fa-google text-danger"></i> */}
                <img src={googleLogo} alt="googleLogo" height={30} width={30} />
                {"  "}Continue With Google
              </button>
            </div>
          </div>

          <div className="col-lg-6 text-right px-0">
            <div className="login_img">
              <img src={login} className="img-fluid" alt="login" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
