import baseAPI from "../../api/userApi";

const getAboutusById = async (id) => {
    try {
        const res = await baseAPI.get(`/aboutPageById/${id}`,);
        return res.data
    } catch (error) {
        console.log(error)
    }
}
const AboutUsService = {
    getAboutusById
}
export default AboutUsService