import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import ShowError from "../../errors/ShowError";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addGetStarted } from "../../features/slices/getStartedSlice";
import Loading from "../GIF/Loading";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const GetStartedForm = props => {
  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is Required").email(),
    mobileNumber: Yup.string()
      .required("Mobile Number Required")
      .matches(phoneRegExp, "Mobile Number is not valid")
      .min(10, "Enter 10 digit Mobile Number")
      .max(10, "Enter 10 digit Mobile Number"),
    state: Yup.string().required("State Selection is Required"),
    language: Yup.string().required("Language is Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      mobileNumber: "",
      state: "",
      language: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      setIsLoading(true);
      resetForm({});
      setSubmitting(false);
      setIsLoading(true);
      if (token) {
        dispatch(addGetStarted(values));
        setIsLoading(false);
        navigate("/dashboard");
      } else {
        dispatch(addGetStarted(values));
        setIsLoading(false);
        navigate("/login");
      }
    },
  });

  return (
    <Row className="align-items-center">
      <Col sm={5} md={12} lg={12}>
        <Form onSubmit={formik.handleSubmit}>
          <h4>Get Started</h4>
          <Row>
            <Col lg={12} className="mb-3">
              <Form.Group>
                <Form.Control
                  type="email"
                  className="form-control"
                  placeholder="Email*"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <ShowError>{formik.errors.email}</ShowError>
                )}
              </Form.Group>
            </Col>

            <Col lg={12} className="mb-3">
              <Form.Group>
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number*"
                  name="mobileNumber"
                  value={formik.values.mobileNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <ShowError>{formik.errors.mobileNumber}</ShowError>
                )}
              </Form.Group>
            </Col>

            <Col lg={12} className="mb-3">
              <Form.Group>
                <Form.Select
                  className="form-control"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option selected>Select State</option>
                  {props.states &&
                    props.states.map(s => {
                      return (
                        <option value={s.name} key={s.id}>
                          {s.name}
                        </option>
                      );
                    })}
                </Form.Select>
                {formik.touched.state && formik.errors.state && (
                  <ShowError>{formik.errors.state}</ShowError>
                )}
              </Form.Group>
            </Col>
            <Col lg={12} className="mb-3">
              <Form.Group>
                <Form.Select
                  className="form-control"
                  name="language"
                  value={formik.values.language}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option selected>Select Language</option>
                  <option value="Hindi">Hindi</option>
                  <option value="English">English</option>
                </Form.Select>
                {formik.touched.language && formik.errors.language && (
                  <ShowError>{formik.errors.language}</ShowError>
                )}
              </Form.Group>
            </Col>

            <Col lg={12} className="mb-3">
              <Button className="btn btn-primary w-100" type="submit">
                {isLoading ? <Loading /> : "Get Started"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default GetStartedForm;
