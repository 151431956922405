import React, { useEffect } from "react";
import videoshape2 from "../../../assets/images/h2-video-shape1.svg";
import shape2 from "../../../assets/images/h2-shape2.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLegalService,
  getAllLegalService,
} from "../../../features/slices/HomeSlice";
import Carousel from "../../../components/OwlCarousel/Carousel";
import { useNavigate } from "react-router-dom";
// import { getAllSubCategories } from "../../../features/slices/subCategory";

const HomeService = ({ LegaleServiceSubTitle, LegalServiceTitle }) => {
  const { legalServices, subCategorys } = useSelector(state => state.home);
  // const { subCategorys } = useSelector(state => state.subCat);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllLegalService());
    // dispatch(getAllSubCategories());
  }, [dispatch]);

  const options = {
    loop: true,
    margin: 10,
    items: 1,
    dots: true,
    autoplay: true,
    autoWidth: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 4,
      },
      1000: {
        items: 4,
      },
      2000: {
        items: 4,
      },
    },
  };
  const navigate = useNavigate();

  const onHandleClick = path => {
    navigate(`/${path}`);
  };
  return (
    <section className="services">
      <div className="shape1">
        <img src={videoshape2} className="img-fluid" alt="" />
      </div>

      <div className="shape2">
        <img src={shape2} className="img-fluid" alt="" />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="title">
              <h2>{LegalServiceTitle}</h2>
              <p
                dangerouslySetInnerHTML={{ __html: LegaleServiceSubTitle }}
              ></p>
            </div>
          </div>
        </div>
        <div className="row">
          <Carousel options={options}>
            {subCategorys.map((item, index) => {
              const { title, subCategoryImage, id, path } = item;
              return (
                <div
                  className="service_box"
                  key={id}
                  onClick={() => onHandleClick(path)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="service_img">
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL + `${subCategoryImage}`
                      }
                      className="img-fluid"
                      alt={title}
                    />
                  </div>
                  <div className="desc">
                    <p>{title}</p>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default HomeService;
