import baseAPI from "../../api/userApi";
import { authHeader } from "../../helpers/authHeaders";
const getCategoryById = async (id) => {
  try {
    return await baseAPI.get(`/subCategoryById/${id}`);
  } catch (error) {
    console.log(error);
  }
};

const getFaqByCategoryId = async (id) => {
  try {
    return await baseAPI.post("/subCategoryWise-faqList", {
      sub_category_id: id,
    });
  } catch (error) {
    console.log(error);
  }
};
const CategoryService = {
  getCategoryById,
  getFaqByCategoryId,
};
export default CategoryService;
