import baseAPI from "../../api/userApi"

const getMenuService = async () => {
    try {
        return await baseAPI.get("/master-category")
    } catch (error) {
        console.log(error)
    }
}
const MenuService = {
    getMenuService
}
export default MenuService