import React from "react";
import { Button, Row } from "react-bootstrap";
import baseAPIPDF from "../../../api/baseApiPdf";

const DownloadDocuments = ({ userServiceList }) => {
  const documents = userServiceList?.filter(s => {
    return s.partner_doc_pdf !== null;
  });
  const token = localStorage.getItem("token");

  const downloadPdf = filename => {
    fetch(`${baseAPIPDF}/download/${filename}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename;
        alink.click();
      });
    });
  };

  return (
    <div>
      {documents?.map(d => (
        <Row>
          <Button
            variant="success"
            className="mb-2"
            onClick={() => downloadPdf(d.partner_doc_pdf)}
          >
            {d.service_name} Documents <i className="fa fa-download"></i>
          </Button>
        </Row>
      ))}
    </div>
  );
};

export default DownloadDocuments;
