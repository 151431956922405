import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GetStartedService from "../services/GetStartedService";

export const addGetStarted = createAsyncThunk(
  "get-started/addGetStarted",
  async data => {
    try {
      const res = await GetStartedService.addGetStarted(data);
      if (res.data.code === 200) {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  getStarted: {},
};
const getStartedSlice = createSlice({
  name: "get-started",
  initialState,
  extraReducers(builder) {
    //GET ALL SERVICES
    builder
      .addCase(addGetStarted.pending, state => {
        state.loading = true;
      })
      .addCase(addGetStarted.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.getStarted = payload;
      })
      .addCase(addGetStarted.rejected, (state, payload) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default getStartedSlice.reducer;
