import React from "react";
import errorImage from "../../assets/images/404Error.png";
import { Image } from "react-bootstrap";

const PageNotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center m-5 py-5">
      <Image
        src={errorImage}
        alt="Page Not Found Image"
        className="h-100"
        fluid
        height={400}
        width={500}
      />
    </div>
  );
};

export default PageNotFound;
