import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MenuService from "../services/MenuService";

export const getAllMenuService = createAsyncThunk(
  "menuService/getAllMenuService",
  async () => {
    try {
      const res = await MenuService.getMenuService();
      return res?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const initialState = {
  loading: false,
  error: "",
  categorys: [],
  sub_category: {
    id: undefined,
    title: "",
    description: "",
    category_id: undefined,
    status: undefined,
  },
  sub_category: [],
  sub_sub_category: {
    id: undefined,
    title: "",
    description: "",
    sub_category_id: undefined,
    status: undefined,
  },
};

const menuServiceSlice = createSlice({
  name: "menuService",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllMenuService.pending, state => {
        state.loading = true;
      })
      .addCase(getAllMenuService.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.categorys = payload;
      })
      .addCase(getAllMenuService.rejected, state => {
        state.loading = false;
      });
  },
});

export default menuServiceSlice.reducer;
