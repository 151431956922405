import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import ShowError from "../../errors/ShowError";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addGetStarted } from "../../features/slices/getStartedSlice";
import baseAPI from "../../api/userApi";
import { authHeader } from "../../helpers/authHeaders";
import Swal from "sweetalert2";
import Loading from "../GIF/Loading";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const MainComponent = props => {
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const formRef = useRef();

  const [encRequestRes, setEncRequestRes] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const access_code = process.env.REACT_APP_ACCESS_CODE;

  async function displayPayment(obj) {
    try {
      setIsLoading(true);
      if (obj.serviceId !== null && obj.serviceId !== undefined) {
        const result = await baseAPI.post(
          "/single-service-booking",
          {
            state: obj.state,
            email: obj.email,
            mobileNumber: obj.mobileNumber,
            serviceId: obj.serviceId,
            payment_status: "pending",
            status: "OnGoing",
          },
          { headers: authHeader() }
        );

        if (result.data.code === 404) {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "You Have Already Buy This Service",
            showConfirmButton: false,
            timer: 3000,
          });
          return;
        }

        const payload = {
          merchant_id: process.env.REACT_APP_MERCHENT_ID,
          order_id: result.data.data.dataValues.order_id,
          currency: "INR",
          amount: result.data.data.dataValues.total,
          redirect_url: `${process.env.REACT_APP_API_BASE_URL}/plan-handle-response`,
          cancel_url: `${process.env.REACT_APP_API_BASE_URL}/plan-handle-response`,
          language: "EN",
          serviceId: result.data.data.dataValues.serviceId,
          billing_name: "",
          billing_state: obj.state,
          billing_country: "India",
          billing_tel: "",
          billing_email: obj.email,
        };

        const response = await baseAPI.post("/payments", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setEncRequestRes(response.data.data);

        setTimeout(() => {
          formRef.current.submit();
        }, 3000);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "No Service Selected for Purchase",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }
    } catch (error) {
      console.log("Error occurred:", error);
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is Required").email(),
    mobileNumber: Yup.string()
      .required("Mobile Number is Required")
      .matches(phoneRegExp, "Mobile Number is not valid")
      .min(10, "Enter 10 Digit Mobile Number")
      .max(10, "Enter 10 Digit Mobile Number"),
    state: Yup.string().required("State Selection is Required"),
    language: Yup.string().required("Language is Required"),
  });

  let finalService = props.service;

  let sId = finalService[0]?.id;
  let sName = finalService.length == 1 ? finalService[0]?.title : "";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      mobileNumber: "",
      state: "",
      language: "",
      serviceId: sId?.toString(),
      serviceName: sName,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      resetForm({});
      setSubmitting(false);
      if (
        token &&
        values.serviceId !== null &&
        values.serviceId !== undefined
      ) {
        displayPayment({ ...values });
      } else if (token) {
        dispatch(addGetStarted(values));
        navigate("/dashboard");
      } else {
        dispatch(addGetStarted(values));
        navigate("/login");
      }
    },
  });

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    setSelectedValue(
      finalService?.find(
        service => service.id === Number(formik.values.serviceId)
      )
    );
  }, [formik.values.serviceId]);

  let subtractValue =
    finalService.length == 1
      ? finalService[0]?.market_price - finalService[0]?.price
      : selectedValue?.market_price - selectedValue?.price;

  let percentage =
    finalService.length == 1
      ? (finalService[0]?.market_price * 100) / finalService[0]?.price - 100
      : (selectedValue?.market_price * 100) / selectedValue?.price - 100;

  return (
    <Row className="align-items-center">
      <Col sm={5} md={12} lg={12}>
        <form
          ref={formRef}
          id="nonseamless"
          action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
          method="POST"
        >
          <div className="col-sm-12 col-lg-8">
            <input
              type="hidden"
              id="encRequestRes"
              name="encRequest"
              value={encRequestRes}
            />
            <input
              type="hidden"
              name="access_code"
              id="access_code"
              value={access_code}
            />
          </div>
        </form>
        <Form onSubmit={formik.handleSubmit}>
          {props.service.length > 0 ? (
            <Row className="mb-2">
              <Col>
                <h4>Pricing Summury</h4>
                <h5>
                  Market Price :{" "}
                  <span>
                    <b>
                      ₹
                      <s>
                        {finalService.length == 1
                          ? finalService[0]?.market_price.toFixed(2)
                          : selectedValue?.market_price}
                      </s>
                    </b>
                  </span>
                </h5>
                <h5>
                  Ensurekar Price :{" "}
                  <b>
                    ₹
                    {finalService.length == 1
                      ? finalService[0]?.price.toFixed(2)
                      : selectedValue?.price}{" "}
                    Exclude
                  </b>
                </h5>
                <h5>
                  You Save Upto :{" "}
                  <b className="text-success">
                    ₹{`${subtractValue.toFixed(2)} (${percentage.toFixed(2)}%)`}{" "}
                  </b>
                </h5>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <h4>Get Started</h4>
          <Row>
            {props.service && (
              <>
                <Col lg={12} className="mb-3">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="form-control"
                      name="serviceId"
                      placeholder="Service*"
                      value={formik.values.serviceId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      hidden={true}
                      disabled={true}
                    />
                    {/* {formik.touched.serviceId && formik.errors.serviceId && (
                      <ShowError>{formik.errors.serviceId}</ShowError>
                    )} */}
                  </Form.Group>

                  {finalService.length == 1 ? (
                    <Form.Group>
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="serviceName"
                        placeholder="Service Name"
                        value={formik.values.serviceName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                      />
                    </Form.Group>
                  ) : props.service.length === 0 ? (
                    <></>
                  ) : (
                    <Form.Group>
                      <Form.Select
                        className="form-control"
                        name="serviceId"
                        value={formik.values.serviceId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        defaultValue={finalService[0]}
                      >
                        {finalService.map((s, i) => {
                          return (
                            <option value={s.id} key={i}>
                              {s.title}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  )}
                </Col>
              </>
            )}
            <Col lg={12} className="mb-3">
              <Form.Group>
                <Form.Control
                  type="email"
                  className="form-control"
                  placeholder="Email*"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <ShowError>{formik.errors.email}</ShowError>
                )}
              </Form.Group>
            </Col>

            <Col lg={12} className="mb-3">
              <Form.Group>
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number*"
                  name="mobileNumber"
                  value={formik.values.mobileNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <ShowError>{formik.errors.mobileNumber}</ShowError>
                )}
              </Form.Group>
            </Col>

            <Col lg={12} className="mb-3">
              <Form.Group>
                <Form.Select
                  className="form-control"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option selected>Select State</option>
                  {props.states &&
                    props.states.map(s => {
                      return (
                        <option value={s.name} key={s.id}>
                          {s.name}
                        </option>
                      );
                    })}
                </Form.Select>
                {formik.touched.state && formik.errors.state && (
                  <ShowError>{formik.errors.state}</ShowError>
                )}
              </Form.Group>
            </Col>
            <Col lg={12} className="mb-3">
              <Form.Group>
                <Form.Select
                  className="form-control"
                  name="language"
                  value={formik.values.language}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option selected>Select Language</option>
                  <option value="Hindi">Hindi</option>
                  <option value="English">English</option>
                </Form.Select>
                {formik.touched.language && formik.errors.language && (
                  <ShowError>{formik.errors.language}</ShowError>
                )}
              </Form.Group>
            </Col>

            <Col lg={12} className="mb-3">
              <Button className="btn btn-primary w-100" type="submit">
                {isLoading ? <Loading /> : "Get Started"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default MainComponent;
