import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Carousel from "../../../components/OwlCarousel/Carousel";
import { useDispatch, useSelector } from "react-redux";
import ServiceModal from "../../../components/Modal/ServiceModal";
import Servcies from "../../../components/Services/Servcies";
import HelpDashboard from "../../../components/HelpDashboard/HelpDashboard";
import UserDashboardLayout from "../UserDashboardLayout";
import {
  addFavServices,
  getAllServices,
  removeFavService,
} from "../../../features/slices/serviceSlice";
import StateModal from "../../../components/Modal/StateModal";
import { getDasboardImages } from "../../../features/slices/settingSlice";
import ImagePreview from "../../../components/ImagePreview/ImagePreview";
import { getCartItems } from "../../../features/slices/myCartSlice";

const Dashboard = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: false,
    autoWidth: false,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,
      },
      2000: {
        items: 2,
      },
    },
  };
  const options1 = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: false,
    autoWidth: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
      2000: {
        items: 1,
      },
    },
  };
  const [svrs, setSvrs] = useState([]);
  const [getId, setGetId] = useState();
  const [state, setState] = useState("");
  const [modalState, setModalState] = useState("close");

  // const [modalShow, setModalShow] = useState(false);
  // const [serviceModalShow, setServiceModalShow] = useState(false);

  const dispatch = useDispatch();

  const { dashboardImages } = useSelector(state => state.settings);
  const { services } = useSelector(state => state.services);

  useEffect(() => {
    dispatch(getAllServices());
    dispatch(getDasboardImages());
    // dispatch(getCartItems());
  }, [dispatch]);

  const filterService = services?.find(service => service.id === getId);

  const topService = services?.filter(service => service.top_service === true);

  const findTopService = topService?.find(service => service.id === getId);

  const handleShowServiceModal = (id, state) => {
    setModalState("service-modal");
    setState(state);
  };

  const handleShowStateModal = id => {
    setModalState("state-modal");
    setGetId(id);
  };

  const handleClose = () => {
    setModalState("close");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setSvrs(topService);
  }, [JSON.stringify(topService)]);

  function handleFavorite(id, favorite) {
    let newServices;
    if (favorite === 1) {
      newServices = svrs.map(item => {
        return item.id === id ? { ...item, is_fav: 0 } : item;
      });
      dispatch(removeFavService(id));
      setSvrs(newServices);
    } else {
      newServices = svrs.map(item => {
        return item.id === id ? { ...item, is_fav: 1 } : item;
      });
      dispatch(addFavServices(id));
      setSvrs(newServices);
    }
  }

  return (
    <React.Fragment>
      {modalState === "state-modal" && (
        <StateModal
          showServiceModal={handleShowServiceModal}
          show={modalState === "state-modal"}
          onHide={() => handleClose()}
          id={getId}
        />
      )}

      {findTopService
        ? findTopService && (
            <ServiceModal
              show={modalState === "service-modal"}
              onHide={() => handleClose()}
              filterService={findTopService}
              state={state}
            />
          )
        : filterService && (
            <ServiceModal
              show={modalState === "service-modal"}
              onHide={() => handleClose()}
              filterService={filterService}
              state={state}
            />
          )}
      <UserDashboardLayout>
        <div className="row">
          <div className="col-lg-12">
            <div className="banner_dashboard">
              <Carousel options={options1}>
                {dashboardImages.image && (
                  <div className="item">
                    <ImagePreview
                      width={800}
                      height={120}
                      src={dashboardImages.image}
                      alt="Image-1"
                    />
                  </div>
                )}
                {dashboardImages.image1 && (
                  <div className="item">
                    <ImagePreview
                      width={800}
                      height={120}
                      src={dashboardImages.image1}
                      alt="Image-2"
                    />
                  </div>
                )}
                {dashboardImages.image2 && (
                  <div className="item">
                    <ImagePreview
                      width={800}
                      height={120}
                      src={dashboardImages.image2}
                      alt="Image-1"
                    />
                  </div>
                )}
                {dashboardImages.image3 && (
                  <div className="item">
                    <ImagePreview
                      width={800}
                      height={120}
                      src={dashboardImages.image3}
                      alt="Image-1"
                    />
                  </div>
                )}
              </Carousel>{" "}
            </div>

            <div className="services">
              <h5>Top Services</h5>
              <Row>
                <Col lg={12}>
                  <Carousel options={options}>
                    {svrs.map(s => {
                      const { id, price, title, description } = s;
                      return (
                        <div className="item" key={id}>
                          <div className="dashboard_service_box">
                            <h5>{title}</h5>
                            <h3 className="price">₹{price}/-</h3>
                            <p>(Exclude)</p>
                            <div
                              className="fav_icon"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i
                                className={
                                  !s.is_fav ? "fa fa-heart-o" : "fa fa-heart"
                                }
                                onClick={() => handleFavorite(s.id, s.is_fav)}
                                id={s.id}
                              ></i>
                            </div>
                            <p className="desc">{description}</p>
                            <Button
                              onClick={() => handleShowStateModal(id)}
                              className="btn btn-success"
                            >
                              buy now
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </Col>
              </Row>
            </div>

            <Servcies serviceModal={handleShowStateModal} />

            <HelpDashboard />
          </div>
        </div>
      </UserDashboardLayout>
    </React.Fragment>
  );
};

export default Dashboard;
