import React from "react";
import { Link } from "react-router-dom";

const ContactLocation = props => {
  return (
    <section className="section_content contact_location">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="title">
              <h2>Our Location</h2>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center align-items-center">
          {props.Address1?.replace(/<(.|\n)*?>/g, "").trim().length !== 0 ? (
            <div className="col-md-6 col-lg-4">
              <div className="location_box text-center">
                <h4>Registered Office</h4>

                <div dangerouslySetInnerHTML={{ __html: props.Address1 }}></div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {props.Address2?.replace(/<(.|\n)*?>/g, "").trim().length !== 0 ? (
            <div className="col-md-6 col-lg-4">
              <div className="location_box text-center">
                <h4>Registered Office</h4>

                <div dangerouslySetInnerHTML={{ __html: props.Address2 }}></div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {props.Address3?.replace(/<(.|\n)*?>/g, "").trim().length !== 0 ? (
            <div className="col-md-6 col-lg-4">
              <div className="location_box text-center">
                <h4>Registered Office</h4>

                <div dangerouslySetInnerHTML={{ __html: props.Address3 }}></div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContactLocation;
