import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// import { getAllSuppoters } from "../../../features/slices/HomeSlice";

const HomeSupporterSection = () => {
  const { suppoters } = useSelector(state => state.home);

  const investor = suppoters.filter(
    suppoter => suppoter.suppoter === "Investors"
  );
  const patners = suppoters.filter(
    suppoter => suppoter.suppoter === "Partners"
  );
  const advisors = suppoters.filter(
    suppoter => suppoter.suppoter === "Advisors"
  );
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getAllSuppoters());
  // }, [dispatch]);

  return (
    <section className="supporter_sec">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="title">
              <h2>Our Supporter</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ul
              className="nav nav-pills mb-3 justify-content-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="pills-Investors-tab"
                  data-toggle="pill"
                  href="#pills-Investors"
                  role="tab"
                  aria-controls="pills-Investors"
                  aria-selected="true"
                >
                  Investors
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-Partners-tab"
                  data-toggle="pill"
                  href="#pills-Partners"
                  role="tab"
                  aria-controls="pills-Partners"
                  aria-selected="false"
                >
                  Partners
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-Advisors-tab"
                  data-toggle="pill"
                  href="#pills-Advisors"
                  role="tab"
                  aria-controls="pills-Advisors"
                  aria-selected="false"
                >
                  Advisors
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-Investors"
                role="tabpanel"
                aria-labelledby="pills-Investors-tab"
              >
                <div className="row d-flex justify-content-center align-items-center">
                  {investor.map((item, index) => {
                    const { suppoter_name, image } = item;
                    return (
                      <div className="col-sm-2" key={index}>
                        <div className="support_img">
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + `${image}`}
                            className="img-fluid"
                            alt={suppoter_name}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-Partners"
                role="tabpanel"
                aria-labelledby="pills-Partners-tab"
              >
                <div className="row d-flex justify-content-center align-items-center">
                  {patners.map((item, index) => {
                    const { suppoter_name, image } = item;
                    return (
                      <div className="col-sm-2" key={index}>
                        <div className="support_img">
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + `${image}`}
                            className="img-fluid"
                            alt={suppoter_name}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-Advisors"
                role="tabpanel"
                aria-labelledby="pills-Advisors-tab"
              >
                <div className="row d-flex justify-content-center align-items-center">
                  {advisors.map((item, index) => {
                    const { suppoter_name, image } = item;
                    return (
                      <div className="col-sm-2" key={index}>
                        <div className="support_img">
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + `${image}`}
                            className="img-fluid"
                            alt={suppoter_name}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSupporterSection;
