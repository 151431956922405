import moment from "moment";
import React from "react";

const Title = props => {
  return (
    <section className="bloginner_head">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="title">
              <h2>{props?.blogTitle}</h2>
            </div>
            <p>Post Date : {moment(props?.postDate).format("MMMM DD, YYYY")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Title;
