import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const ContactContentSchema = Yup.object({
  name: Yup.string().required("Required Name"),
  email: Yup.string().required("Required Email").email(),
  mobile_number: Yup.string()
    .required("Mobile number is Required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Add 10 digit mobile number")
    .max(10, "Add 10 digit mobile number"),
  massage: Yup.string().required("Required Message"),
  service_id: Yup.string().required("Required"),
});
