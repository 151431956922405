import React from "react";
import { Navigate } from "react-router-dom";
let token = localStorage.getItem("token");
let role = localStorage.getItem("role");

const PrivateRoutes = ({ children }) => {
  return token && role === "Customer" ? children : <Navigate to="/login" />;
};

export default PrivateRoutes;
