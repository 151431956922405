import { combineReducers, configureStore } from "@reduxjs/toolkit";
import aboutusSlice from "../slices/aboutusSlice";
import authSlice from "../slices/authSlice";
import categorySlice from "../slices/categorySlice";
import contactusSlice from "../slices/contactusSlice";
import eventSlice from "../slices/eventSlice";
import getStartedSlice from "../slices/getStartedSlice";
import HomeSlice from "../slices/HomeSlice";
import menuServiceSlice from "../slices/menuServiceSlice";
import partnerUsSlice from "../slices/partnerUsSlice";
import paymentSlice from "../slices/paymentSlice";
import serviceSlice from "../slices/serviceSlice";
import settingSlice from "../slices/settingSlice";
import subCategory from "../slices/subCategory";
import userProfileSlice from "../slices/userProfileSlice";
import myCartSlice from "../slices/myCartSlice";
import blogSlice from "../slices/blogSlice";

const rootReducer = combineReducers({
  contacts: contactusSlice,
  home: HomeSlice,
  menuService: menuServiceSlice,
  auth: authSlice,
  settings: settingSlice,
  about: aboutusSlice,
  subCategory: categorySlice,
  services: serviceSlice,
  payments: paymentSlice,
  userProfile: userProfileSlice,
  events: eventSlice,
  partners: partnerUsSlice,
  getStarted: getStartedSlice,
  subCat: subCategory,
  myCart: myCartSlice,
  blogs: blogSlice,
});
const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
