import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import CommonService from "../services/CommonService";

export const getAllSubCategories = createAsyncThunk(
  "category/getAllCategories",
  async () => {
    try {
      const res = await CommonService.getCommonDataService();
      return res.data.data.subCategory;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  subCategorys: [],

  subCategory: {},
};

const subCategorySlice = createSlice({
  name: "sub-category",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllSubCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSubCategories.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.subCategorys = payload;
      })
      .addCase(getAllSubCategories.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default subCategorySlice.reducer;
