import baseAPI from "../../api/userApi";
import { authHeader } from "../../helpers/authHeaders";

const user = localStorage.getItem("token");

const upadteUserProfile = async (data) => {
  const obj = {
    ...data,
    name: data.name,
  };
  try {
    return await baseAPI.post("/user-detail-update", obj, {
      headers: authHeader(),
    });
  } catch (error) {
    console.log(error);
  }
};

const upadteUserAddress = async (data) => {
  try {
    return await baseAPI.post("/user-detail-update", data, {
      headers: authHeader(),
    });
  } catch (error) {
    console.log(error);
  }
};

const upadteKYC = async (data) => {
  try {
    return await baseAPI.post("/image", data, {
      headers: {
        Authorization: `Bearer ${user}`,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const getUserProfileById = async () => {
  try {
    return await baseAPI.get(
      "/user-profile",

      { headers: authHeader() }
    );
  } catch (error) {
    console.log(error);
  }
};
const UserProfileService = {
  upadteUserProfile,
  getUserProfileById,
  upadteUserAddress,
  upadteKYC,
};
export default UserProfileService;
