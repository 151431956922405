import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPaymentByUser,
  getPaymentServiceByUserClosed,
  getPaymentServiceByUserCompleted,
  getUserBuyPlans,
} from "../../../features/slices/paymentSlice";
import UserDashboardLayout from "../UserDashboardLayout";
import Ongoing from "./Sections/Ongoing";
import Complete from "./Sections/Complete";
import Closed from "./Sections/Closed";
import Fav from "./Sections/Fav";
import MyPlans from "./Sections/MyPlans";
import { getUserFavServices } from "../../../features/slices/serviceSlice";

const MyServices = () => {
  const dispatch = useDispatch();

  const {
    userServiceList,
    userServiceListCompleted,
    userServiceListClosed,
    userBuyPlans,
  } = useSelector(state => state.payments);

  const { favServices } = useSelector(state => state.services);

  useEffect(() => {
    dispatch(getAllPaymentByUser());
    dispatch(getPaymentServiceByUserCompleted());
    dispatch(getPaymentServiceByUserClosed());
    dispatch(getUserBuyPlans());
    dispatch(getUserFavServices());
  }, [dispatch]);

  return (
    <React.Fragment>
      <UserDashboardLayout>
        <h4>My Service</h4>
        <hr />
        <div className="row">
          <div className="col-lg-12">
            <div className="all_service">
              <div className="service_tab">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-OnGoing"
                      data-toggle="pill"
                      href="#OnGoing"
                      role="tab"
                      aria-controls="pills-myhome"
                      aria-selected="true"
                    >
                      Ongoing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-Completed"
                      data-toggle="pill"
                      href="#Completed"
                      role="tab"
                      aria-controls="pills-Completed"
                      aria-selected="false"
                    >
                      Completed
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-Closed"
                      data-toggle="pill"
                      href="#Closed"
                      role="tab"
                      aria-controls="pills-Closed"
                      aria-selected="false"
                    >
                      Closed
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-Fav"
                      data-toggle="pill"
                      href="#Fav"
                      role="tab"
                      aria-controls="pills-Fav"
                      aria-selected="false"
                    >
                      Favourite
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-Plan"
                      data-toggle="pill"
                      href="#Plans"
                      role="tab"
                      aria-controls="pills-Plan"
                      aria-selected="false"
                    >
                      Plans
                    </a>
                  </li>
                </ul>
                <div
                  className="tab-content service_tabcontent"
                  id="pills-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="OnGoing"
                    role="tabpanel"
                    aria-labelledby="pills-myhome-tab"
                  >
                    <Ongoing userServiceList={userServiceList} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Completed"
                    role="tabpanel"
                    aria-labelledby="pills-myprofile-tab"
                  >
                    <Complete
                      userServiceListCompleted={userServiceListCompleted}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Closed"
                    role="tabpanel"
                    aria-labelledby="pills-Closed"
                  >
                    <Closed userServiceListClosed={userServiceListClosed} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Fav"
                    role="tabpanel"
                    aria-labelledby="pills-mycontact1-tab"
                  >
                    <Fav favServices={favServices} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Plans"
                    role="tabpanel"
                    aria-labelledby="pills-mycontact1-tab"
                  >
                    <MyPlans userBuyPlans={userBuyPlans} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UserDashboardLayout>
    </React.Fragment>
  );
};

export default MyServices;
