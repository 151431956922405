import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EventService from "../services/EventService";

export const getAllEvent = createAsyncThunk("event/getAllEvent", async () => {
  try {
    const res = await EventService.getAllEvent();
    return res.data.data;
  } catch (error) {
    console.log(error);
  }
});

export const getEventById = createAsyncThunk("event/getEventById", async id => {
  try {
    const res = await EventService.getEventById(id);
    return res.data.data;
  } catch (error) {
    console.log(error);
  }
});

export const userEventInterested = createAsyncThunk(
  "event/userEventInterested",
  async id => {
    try {
      const res = await EventService.userEventInterested(id);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const initialState = {
  events: [],
  loading: false,
  error: "",
  event: {},
  userInterestEvent: {},
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  extraReducers(builder) {
    ///GET ALL EVENT
    builder
      .addCase(getAllEvent.pending, state => {
        state.loading = true;
      })
      .addCase(getAllEvent.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.events = payload;
      })
      .addCase(getAllEvent.rejected, state => {
        state.loading = false;
      });

    ///GET EVENT BY ID

    builder
      .addCase(getEventById.pending, state => {
        state.loading = true;
      })
      .addCase(getEventById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.event = payload;
      })
      .addCase(getEventById.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(userEventInterested.pending, state => {
        state.loading = true;
      })
      .addCase(userEventInterested.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInterestEvent = payload;
      })
      .addCase(userEventInterested.rejected, state => {
        state.loading = false;
      });
  },
});
export default eventSlice.reducer;
