import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ServiceDisplayModal from "../../../../components/Modal/ServiceDisplayModal";
import { getAllServices } from "../../../../features/slices/serviceSlice";

const Ongoing = ({ userServiceList }) => {
  const [show, setShow] = useState(false);
  const [getId, setGetId] = useState();
  const onShowServiceDetails = id => {
    setShow(!show);
    setGetId(id);
  };

  const handleClose = () => {
    setShow(false);
  };
  const dispatch = useDispatch();
  const { services } = useSelector(state => state.services);
  useEffect(() => {
    dispatch(getAllServices());
  }, [dispatch]);

  const filterService = services.find(s => s.id === getId);

  return (
    <React.Fragment>
      {filterService && (
        <ServiceDisplayModal
          show={show}
          onHide={handleClose}
          filterService={filterService}
        />
      )}
      {userServiceList.map((userservice, index) => {
        const {
          service_name,
          price,
          duration,
          end_date,
          service_id,
          start_date,
        } = userservice;

        const end_Date = new Date(end_date);
        const start_Date = new Date(start_date);
        const endDate = moment(end_Date).format("DD/MM/YYYY");
        const startDate = moment(start_Date).format("DD/MM/YYYY");

        return (
          <div className="service_block" key={index}>
            <div className="row align-items-center">
              <div className="fav_icon"></div>
              <div className="col-lg-8">
                <div className="service_block_left">
                  <p className="font-weight-bold">{service_name}</p>
                  <div dangerouslySetInnerHTML={{ __html: duration }}></div>
                  {/* <p>Expiry Date: {endDate}</p> */}

                  <h4 className="price">
                    ₹{price}/- <span> only (Exclusive)</span>{" "}
                  </h4>
                </div>
              </div>
              <div className="col-lg-4">
                <Button
                  className="btn btn-success mb-3"
                  onClick={() => {
                    onShowServiceDetails(service_id);
                  }}
                >
                  ongoing
                </Button>
                <p>
                  <b>Start Date: </b>
                  <br />
                  {startDate}
                </p>
                <p>
                  <b>Expiry Date: </b> <br />
                  {endDate}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default Ongoing;
