import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import UserDashboardLayout from "../UserDashboardLayout";
import EventListModal from "./EventListModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvent, getEventById } from "../../../features/slices/eventSlice";
import EventCalendar from "../../../components/EventCalendar/EventCalendar";
import moment from "moment";
import Swal from "sweetalert2";

const EventList = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [eventData, setEventData] = useState({});

  const { events, event } = useSelector(state => state.events);
  const { userProfile } = useSelector(state => state.userProfile);

  const dispatch = useDispatch();

  const onInterested = async id => {
    if (userProfile.mobile_number) {
      await dispatch(getEventById(id));
      setModalShow(!modalShow);
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please Update Mobile Number",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    dispatch(getAllEvent());
    if (event) {
      setEventData(event);
    }
  }, [dispatch, event]);

  const myEvents = events.map((e, index) => {
    // return {
    //   title: e.title,
    //   start: new Date(e.start_date),
    //   end: new Date(e.end_date),
    //   desc: e.description,
    // };
    return {
      title: e.title,
      start: new Date(e.start_date),
      end: new Date(e.end_date),
      desc: e.description,
    };
  });
  return (
    <React.Fragment>
      <UserDashboardLayout>
        <EventListModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          eventData={eventData}
        />
        <h4>Event List</h4>
        <hr />
        <div className="row">
          <div className="col-lg-12">
            <div className="all_service">
              <div className="event_calender">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="title">
                      <p>
                        Calendar {moment().subtract("year", 1).format("YYYY")} -{" "}
                        {moment().format("YYYY")}
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-lg-4">
                    <form>
                      <select id="inputState" className="form-control">
                        <option selected>Select Financial Year</option>
                        <option>...</option>
                      </select>
                    </form>
                  </div> */}
                </div>

                <div className="row event_list">
                  <div className="col-md-12 col-lg-7">
                    <div className="row">
                      <div className="event_block_sec">
                        <div className="col-lg-12">
                          {events.map(e => {
                            const {
                              title,
                              description,
                              start_date,
                              end_date,
                              id,
                            } = e;
                            const startData =
                              moment(start_date).format("DD MMM YYYY");
                            const endDate =
                              moment(end_date).format("DD MMM YYYY");

                            return (
                              <div className="event_block" key={id}>
                                <div className="row align-items-center">
                                  <div className="col-12 col-md-7 col-lg-6">
                                    <h4 className="font-weight-bold">
                                      {title}
                                    </h4>

                                    <div>
                                      <b>Start Date:</b>
                                      {startData}
                                    </div>
                                    <div>
                                      <b>End Date :</b>
                                      {endDate}
                                    </div>
                                    <p>{description}</p>
                                  </div>
                                  <div className="col-12 col-md-5 col-lg-6">
                                    <div className="event_btn">
                                      <a href="#">Know More</a>
                                      <Button
                                        variant="success"
                                        onClick={() => onInterested(id)}
                                      >
                                        i'm Intrested
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div className="calender my-4">
                      <EventCalendar events={myEvents} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UserDashboardLayout>
    </React.Fragment>
  );
};

export default EventList;
