import baseAPI from "../../api/userApi";

const getAllBlogs = async () => {
  try {
    return await baseAPI.get("/list-blogs");
  } catch (e) {
    console.log(e);
  }
};

const getBlogById = async id => {
  try {
    return await baseAPI.get(`/blogById/${id}`);
  } catch (e) {
    console.log(e);
  }
};

const BlogService = {
  getAllBlogs,
  getBlogById,
};

export default BlogService;
