import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import p1 from "../../assets/images/p1.png";
import p2 from "../../assets/images/p2.png";
import p3 from "../../assets/images/p3.png";
import p4 from "../../assets/images/p4.png";
import p5 from "../../assets/images/p5.png";
import p6 from "../../assets/images/p6.png";
import p7 from "../../assets/images/p7.png";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getAllMenuService } from "../../features/slices/menuServiceSlice";
const Footer = () => {
  const { categorys } = useSelector(state => state.menuService);
  const { settings } = useSelector(state => state.home);
  const {
    footer_logo,
    helpline_no,
    helpline_text,
    linkedin_url,
    twitter_url,
    facebook_url,
    instagram_url,
  } = settings;

  // const categories = categorys.map((category) => {

  //   return category.sub_categories.map((c) => {

  //     return c.sub_sub_categories.map((s) => {
  //       return s.title
  //     })
  //   })
  // })

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getAllMenuService());
  // }, [dispatch]);

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="call_box">
                <div className="row align-items-center">
                  <div className="col-12 col-sm-7">
                    <h3 className="mb-0">
                      <span>Have a Question?</span>
                      {helpline_text}
                    </h3>
                  </div>
                  <div className="col-12 col-sm-5 text-right">
                    <div className="call">
                      <h2 className="mb-0">
                        {" "}
                        <i className="fa fa-phone"></i> {helpline_no}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="footer_logo">
                <Link to="/">
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + `${footer_logo}`}
                    className="img-fluid"
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-6 text-right">
              <div className="footer_social">
                <ul className="list-unstyled pl-0">
                  <li>
                    {" "}
                    <a href={facebook_url} target="_blank">
                      <FaFacebookF />
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href={twitter_url} target="_blank">
                      <FaTwitter />
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href={instagram_url} target="_blank">
                      <FaInstagram />
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href={linkedin_url} target="_blank">
                      <FaLinkedin />
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />

          <div className="row">
            {categorys
              .filter(c => c.status == 1)
              .map((category, index) => {
                const { id, title, sub_categories } = category;
                return (
                  <div className="col-sm-12 col-md-6 col-lg-3" key={index}>
                    <div className="footer_info">
                      <p>{title}</p>
                      {sub_categories
                        .filter(s => s.status == 1)
                        .map((sub_categorie, index) => {
                          const { id, title, sub_sub_categories, path } =
                            sub_categorie;
                          const to = `/${path}`;
                          return (
                            <ul className="list-unstyled pl-0" key={index}>
                              <Link
                                className="dropdown-item footer_info"
                                to={to}
                              >
                                {title}{" "}
                              </Link>
                            </ul>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12 text-center">
              <div className="footer_nav">
                <ul className="list-unstyled pl-0 mb-0">
                  <li>
                    <Link className="dropdown-item" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/aboutus">
                      About Us
                    </Link>{" "}
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/contactus">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/partner">
                      Partner with us
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/terms-of-service">
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/cookie-policy">
                      Cookie Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/refund-policy">
                      Refund Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12 text-center">
              <div className="footer_payment">
                <ul className="list-unstyled pl-0">
                  <li>
                    <img src={p1} className="img-fluid" />
                  </li>
                  <li>
                    <img src={p2} className="img-fluid" />
                  </li>
                  <li>
                    <img src={p3} className="img-fluid" />
                  </li>
                  <li>
                    <img src={p4} className="img-fluid" />
                  </li>
                  <li>
                    <img src={p5} className="img-fluid" />
                  </li>
                  <li>
                    <img src={p6} className="img-fluid" />
                  </li>
                  <li>
                    <img src={p7} className="img-fluid" />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row copyright">
            <div className="col-sm-6">
              <p>Copyright © 2022 Ensurekar</p>
            </div>
            <div className="col-sm-6 text-right">
              <p>Developed & Designed by Redspark</p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
