import React, { useEffect } from "react";
import baseAPI from "../../api/userApi";
const CancelPayment = () => {
  return (
    <div class="thankyou_wrap">
      <div class="container">
        <div class="card text-center ">
          <div class="circle-shape-1"></div>
          <div class="circle-shape-2"></div>
          <div class="circle-shape-3"></div>
          <div class="circle-shape-4"></div>
          <div class="checkmark mb-4">
            <i class="fa fa-times" id="checkmark" style={{ color: "red" }}></i>
          </div>
          <h1>YOUR PAYMENT IS FAILED</h1>

          <p>Please try again. Something went wrong!</p>
          <a href="https://ensurekar.com/">Go To Home</a>
        </div>
      </div>
    </div>
  );
};

export default CancelPayment;
