import React, { useEffect } from "react";
import { Field, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from 'react-router-dom';
import ShowError from "../../../errors/ShowError";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { becomePartnerSchema } from "../../../helpers/validators/becomePartnerSchema";
import {
  getAllCityByState,
  // getAllState,
} from "../../../features/slices/settingSlice";
import {
  createInquiryForPartner,
  getAllOccupations,
} from "../../../features/slices/partnerUsSlice";
import { getAllService } from "../../../features/slices/contactusSlice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormControl from "rsuite/esm/FormControl";
import FormRange from "react-bootstrap/esm/FormRange";
import { Checkbox } from "rsuite";

const confirmDelete = withReactContent(Swal);
const PartnerModal = props => {
  const dispatch = useDispatch();
  const { states, services, occupations } = useSelector(state => state.home);

  const { cities } = useSelector(state => state.settings);
  // const { services } = useSelector(state => state.contacts);
  // const { occupations } = useSelector(state => state.partners);

  // useEffect(() => {
  //   // dispatch(getAllState());
  //   dispatch(getAllOccupations());
  //   // dispatch(getAllService());
  // }, [dispatch]);

  const onStateChange = id => {
    dispatch(getAllCityByState(id));
  };
  const onScheduleCall = values => {
    dispatch(createInquiryForPartner(values));
    Swal.fire({
      position: "center",
      icon: "success",
      title: "You Have Schedule a Call",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile_number: "",
      massage: "",
      state: "",
      occupation_id: undefined,
      city: "",
      service_id: undefined,
      termsAndConditions: false,
    },
    validationSchema: becomePartnerSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      props.onHide();
      onScheduleCall(values);
      resetForm({});
      setSubmitting(false);
    },
  });

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h5 className="modal-title text-center" id="exampleModalCenterTitle">
          Become an Associate
        </h5>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6} classsName="mb-3">
              <Col lg={12} className="mb-3">
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder="Name*"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <ShowError>{formik.errors.name}</ShowError>
                )}
              </Col>

              <Col lg={12} className="mb-3">
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number*"
                  id="mobile_number"
                  name="mobile_number"
                  onChange={formik.handleChange}
                  value={formik.values.mobile_number}
                />
                {formik.touched.mobile_number &&
                  formik.errors.mobile_number && (
                    <ShowError>{formik.errors.mobile_number}</ShowError>
                  )}
              </Col>

              <Col lg={12} className="mb-3">
                <Form.Select
                  id="state"
                  name="state"
                  className="form-control"
                  onChange={e => {
                    formik.handleChange(e);
                    onStateChange(e.currentTarget.value);
                  }}
                  value={formik.values.state}
                >
                  <option selected>Select State</option>
                  {states.map(s => {
                    return (
                      <option value={s.id} key={s.id}>
                        {s.name}
                      </option>
                    );
                  })}
                </Form.Select>
                {/* <ShowError>{formik.errors.state}</ShowError> */}
              </Col>
              <Col lg={12} className="mb-3">
                <Form.Select
                  id="city"
                  name="city"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  disabled={formik.values.state ? false : true}
                >
                  <option selected>Select City</option>
                  {cities &&
                    cities.map((c, index) => {
                      return (
                        <option value={c.id} key={index}>
                          {c.city}
                        </option>
                      );
                    })}
                </Form.Select>
                {/* <ShowError>{formik.errors.city}</ShowError> */}
              </Col>

              <Col lg={12} className="mb-3">
                <Form.Select
                  id="occupation_id"
                  name="occupation_id"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.occupation_id}
                >
                  <option selected>Select Occupation</option>
                  {occupations.map(o => {
                    const { id, name } = o;

                    return (
                      <option value={id} key={id}>
                        {" "}
                        {name}
                      </option>
                    );
                  })}
                </Form.Select>
                {/* <ShowError>{formik.errors.occupation_id}</ShowError> */}
              </Col>

              <Col lg={12} className="mb-3">
                {/* <div className="custom_checkbox">
                  <Form.Check type="checkbox" id="agree" />
                  <Form.Label htmlFor="agree">
                  I Agree with Terms and Conditions
                  </Form.Label>
                </div> */}
                <Form.Check
                  inline
                  label="I Agree with Terms and Conditions"
                  name="termsAndConditions"
                  onChange={formik.handleChange}
                  value={formik.values.termsAndConditions}
                  type={"Checkbox"}
                />
                {formik.touched.termsAndConditions &&
                  formik.errors.termsAndConditions && (
                    <ShowError>{formik.errors.termsAndConditions}</ShowError>
                  )}
                {/* <ShowError>{formik.errors.termsAndConditions}</ShowError> */}
              </Col>
            </Col>

            <Col lg={6} className="mb-3">
              <Col lg={12} className="mb-3">
                <Form.Control
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <ShowError>{formik.errors.email}</ShowError>
                )}
              </Col>
              <Col lg={12} className="mb-3">
                <Form.Select
                  id="service_id"
                  name="service_id"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.service_id}
                >
                  <option>Become an Associate For Service</option>
                  {services.map(s => {
                    const { id, title } = s;

                    return (
                      <option value={id} key={id}>
                        {title}
                      </option>
                    );
                  })}
                </Form.Select>
                {/* <ShowError>{formik.errors.service_id}</ShowError> */}
              </Col>

              <Col lg={12} className="mb-3">
                <Form.Control
                  type="text"
                  as="textarea"
                  id="massage"
                  name="massage"
                  placeholder="Message"
                  rows="3"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.massage}
                />
                {/* <ShowError>{formik.errors.massage}</ShowError> */}
              </Col>
            </Col>

            <Col lg={12} className="text-center">
              <Button type="submit" id="button" name="button" variant="success">
                SCHEDULE A CALL
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PartnerModal;
