import baseAPI from "../../api/userApi";
import { authHeader } from "../../helpers/authHeaders";

const createInquiryForPartner = async (data) => {
  const obj = {
    name: data.name,
    email: data.email,
    mobile_number: data.mobile_number,
    state: data.state,
    occupation_id: parseInt(data.occupation_id),
    city: data.city,
    service_id: parseInt(data.service_id),
    massage: data.massage,
  };
  try {
    return await baseAPI.post("/create-partner-inquiry", obj);
  } catch (error) {
    console.log(error);
  }
};

const PartnerService = {
  createInquiryForPartner,
};
export default PartnerService;
