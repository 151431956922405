import * as yup from "yup";
import Swal from "sweetalert2";
import { Formik } from "formik";
import { useDropzone } from "react-dropzone";
import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import ImageFilePreview from "../ImageFilePreview/ImageFilePreview";
import Loading from "../GIF/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  addCartItem,
  addToCart,
  getCartItems,
} from "../../features/slices/myCartSlice";
import { getAllPaymentByUser } from "../../features/slices/paymentSlice";

export let encReq;
const ServiceModal = ({ show, onHide, filterService, state }) => {
  const formRef = useRef();
  const [encRequest, setEncRequest] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const access_code = "AVTB18KB63AS79BTSA";

  const user = localStorage.getItem("token");
  const email = localStorage.getItem("email");

  const dispatch = useDispatch();

  const { cart } = useSelector(state => state.myCart);
  const { userServiceList } = useSelector(state => state.payments);

  const {
    id,
    benefits,
    deliverables,
    description,
    documents,
    duration,
    penalty,
    price,
    title,
  } = filterService;

  useEffect(() => {
    dispatch(getAllPaymentByUser());
  }, []);

  let ser = userServiceList.find(s => s.service_id === filterService.id);

  // async function displayRazor(serviceId, state, values) {
  //   if (values.files.length > 0) {
  //     setIsLoading(true);
  //     const result = await baseAPI.post(
  //       "/service-booking",
  //       {
  //         service_id: serviceId,
  //         payment_status: "pending",
  //         status: "Pending",
  //         state: state,
  //         documents: values.files,
  //         service_name: filterService.title,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${user}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     if (!result) {
  //       console.log("No Data.. Some Error Occured");
  //       return;
  //     }

  //     if (result.data.code === 404) {
  //       onHide();
  //       setIsLoading(false);
  //       Swal.fire({
  //         position: "center",
  //         icon: "warning",
  //         title: "You have already buy this Service",
  //         showConfirmButton: false,
  //         timer: 3000,
  //       });
  //     } else {
  //       const { total, order_id, currency, id } = result.data.data;
  //       let payload = {
  //         merchant_id: "2064927",
  //         order_id: order_id,
  //         currency: "INR",
  //         amount: total.toString(),
  //         // redirect_url: "https://ensurekar.com:4000/user/handle-response",
  //         // cancel_url: "https://ensurekar.com:4000/user/handle-response",
  //         language: "EN",
  //         service_id: serviceId.toString(),
  //         billing_name: "",
  //         billing_state: state,
  //         billing_country: "India",
  //         billing_tel: "",
  //         billing_email: email,
  //       };

  //       await baseAPI
  //         .post("/payments", payload, {
  //           headers: {
  //             Authorization: `Bearer ${user}`,
  //           },
  //         })
  //         .then(response => {
  //           setEncRequest(response.data.data);
  //           encReq = payload;
  //           setTimeout(() => {
  //             formRef.current.submit();
  //           }, 3000);
  //           return response;
  //         });

  //       setIsLoading(false);
  //       onHide();
  //     }
  //   } else {
  //     Swal.fire({
  //       position: "center",
  //       icon: "warning",
  //       title: "Please upload the documents",
  //       showConfirmButton: false,
  //       timer: 3000,
  //     });
  //   }
  // }

  return (
    <React.Fragment>
      {/* <form
        ref={formRef}
        id="nonseamless"
        action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
        method="POST"
      >
        <div className="col-sm-12 col-lg-8">
          <input
            type="hidden"
            id="encRequest"
            name="encRequest"
            value={encRequest}
          />
          <input
            type="hidden"
            name="access_code"
            id="access_code"
            value={access_code}
          />
        </div>
      </form> */}
      <Formik
        initialValues={{
          files: [],
        }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          // displayRazor(id, state, values);

          if (values.files.length > 0) {
            if (ser) {
              Swal.fire({
                position: "center",
                icon: "warning",
                title: "You Have Already Booked This Service!!!",
                showConfirmButton: false,
                timer: 3000,
              });
              onHide();
              values.files = [];
            } else {
              const findItem = cart?.find(
                c => c?.serviceObj?.id === filterService?.id
              );

              if (findItem) {
                Swal.fire({
                  position: "center",
                  icon: "warning",
                  title: "Service is already in the cart!!!",
                  showConfirmButton: false,
                  timer: 3000,
                });
                onHide();
                values.files = [];
              } else {
                dispatch(
                  addCartItem({
                    serviceObj: filterService,
                    state: state,
                    values,
                  })
                );
                resetForm({});
                setSubmitting(false);
                onHide();
                values.files = [];
                // setInterval(() => {
                dispatch(getCartItems());
                // }, 1500);
              }
            }
          } else {
            Swal.fire({
              position: "center",
              icon: "warning",
              title: "Please upload the documents!!!",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }}
        validationSchema={yup.object().shape({
          recaptcha: yup.array(),
        })}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          return (
            <Modal
              show={show}
              onHide={onHide}
              size="lg"
              centered
              className="modal_buy2"
            >
              <Modal.Body>
                <form onSubmit={handleSubmit} encType="multipart-form">
                  <div className="row">
                    <div className="col-sm-12 col-lg-8">
                      <h5 className="modal-title" id="buynowTitle">
                        {title}
                      </h5>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                      <div className="price">
                        <h3>₹{price}/-</h3>
                        <p> only (Excluded)</p>
                      </div>
                    </div>
                  </div>
                  <div className="service_tab">
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="documents-tab"
                          data-toggle="pill"
                          href="#documents"
                          role="tab"
                          aria-controls="documents"
                          aria-selected="false"
                        >
                          Documents
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="overview-tab"
                          data-toggle="pill"
                          href="#overview"
                          role="tab"
                          aria-controls="overview"
                          aria-selected="true"
                        >
                          Overview
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="benefits-tab"
                          data-toggle="pill"
                          href="#benefits"
                          role="tab"
                          aria-controls="benefits"
                          aria-selected="false"
                        >
                          Benefits
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="deliverables-tab"
                          data-toggle="pill"
                          href="#deliverables"
                          role="tab"
                          aria-controls="deliverables"
                          aria-selected="false"
                        >
                          Deliverables
                        </a>
                      </li>
                    </ul>
                    <div
                      className="tab-content service_tabcontent"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane fade  active show"
                        id="documents"
                        role="tabpanel"
                        aria-labelledby="documents-tab"
                      >
                        <div className="form-group">
                          <h4 className="">Upload Documents Listed Below</h4>
                          <UploadComponent
                            setFieldValue={setFieldValue}
                            values={values.files}
                          >
                            {values.files &&
                              values.files.map((file, i) => (
                                <ImageFilePreview
                                  key={i}
                                  file={file}
                                  heigth={100}
                                  width={120}
                                />
                              ))}
                          </UploadComponent>
                        </div>
                        <p className="font-weight-bold">Documents</p>
                        <div dangerouslySetInnerHTML={{ __html: documents }} />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="overview"
                        role="tabpanel"
                        aria-labelledby="overview-tab"
                      >
                        <p className="font-weight-bold">Description</p>
                        <p>{description}</p>

                        <p className="font-weight-bold">Duration</p>
                        <div
                          dangerouslySetInnerHTML={{ __html: duration }}
                        ></div>

                        <p className="font-weight-bold">Penalty</p>
                        <p>{penalty}</p>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="benefits"
                        role="tabpanel"
                        aria-labelledby="benefits-tab"
                      >
                        <p className="font-weight-bold">Benefits</p>
                        <div dangerouslySetInnerHTML={{ __html: benefits }} />
                      </div>

                      <div
                        className="tab-pane fade"
                        id="deliverables"
                        role="tabpanel"
                        aria-labelledby="deliverables-tab"
                      >
                        <p className="font-weight-bold">Deliverables</p>
                        <div
                          dangerouslySetInnerHTML={{ __html: deliverables }}
                        />
                      </div>
                    </div>
                  </div>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <Row>
                      <Col md={5} className="offset-7">
                        <button
                          type="submit"
                          className="btn btn-success "
                          // onClick={() => {
                          //   displayRazor(id, state);
                          // }}
                        >
                          Add To Cart
                        </button>
                      </Col>
                    </Row>
                  )}
                </form>
              </Modal.Body>

              {/* <Modal.Footer></Modal.Footer> */}
            </Modal>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

const UploadComponent = props => {
  const { setFieldValue, values, children } = props;

  const dropzoneStyle = {
    padding: "8px",
    width: "100%",
    height: "auto",
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // acceptedFiles: ".jpeg,.jpg,.png",
    accept: "image/*",
    onDrop: acceptedFiles => {
      setFieldValue("files", values.concat(acceptedFiles));
    },
  });
  return (
    <div style={dropzoneStyle}>
      {}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
      {children}
    </div>
  );
};

export default ServiceModal;
