import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { NavDropdown } from "react-bootstrap";
import { getUserProfileById } from "../../features/slices/userProfileSlice";

const NavigationBar = () => {
  const email = localStorage.getItem("email");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { categorys } = useSelector(state => state.menuService);
  const { settings } = useSelector(state => state.home);

  const { header_logo } = settings;

  const onLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("cart");
    localStorage.removeItem("totalAmount");
    navigate("/");
    window.location.reload();
  };

  const { userProfile } = useSelector(state => state.userProfile);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    dispatch(getUserProfileById());
  }, [dispatch]);

  useEffect(() => {
    setUserData(userProfile);
  }, [userProfile]);

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg">
        <Link className="navbar-brand" to="/">
          <img
            src={process.env.REACT_APP_IMAGE_URL + header_logo}
            className="img-fluid"
            alt="logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <FaBars size={25} />
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="aboutus">
                AboutUs
              </Link>
            </li>
            <li className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
              </div>
              <div
                className="dropdown-menu mega-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <div className="row no-gutters">
                  {/* {categorys?.map(c => (
                    <p key={c.id}> {c.title}</p>
                  ))} */}
                  {categorys
                    ?.filter(c => c.status == 1)
                    .map((category, index) => {
                      const { id, title, sub_categories } = category;

                      return (
                        <div
                          className="col-sm-12 col-md-6 col-lg-3"
                          key={index}
                        >
                          <h6
                            style={{ color: "black" }}
                            className="dropdown-item"
                          >
                            {" "}
                            {title}
                          </h6>
                          {sub_categories
                            ?.filter(c => c.status == 1)
                            .map((sub_categorie, index) => {
                              const { id, title, path, sub_sub_categories } =
                                sub_categorie;
                              const to = `/${path}`;
                              return (
                                <div key={index}>
                                  <Link
                                    className="dropdown-item"
                                    to={to}
                                    key={id}
                                  >
                                    {title}
                                  </Link>
                                  {/* {sub_sub_categories.map(
                                (sub_sub_categorie, index) => {
                                  return (
                                    <Link className="dropdown-item" key={id}>
                                      {sub_sub_categorie.title}
                                    </Link>
                                  );
                                }
                              )} */}
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contactus">
                ContactUs
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blogs">
                Blogs
              </Link>
            </li>
            <li className="nav-item">
              <div className="my-2 my-lg-0 ">
                {userProfile && userProfile ? (
                  <>
                    {/* <Link to="/dashboard">
                     
                    </Link> */}
                    {/* <img src={user} alt="user" height={20} width={20} /> */}
                    <NavDropdown
                      title={userProfile.name ? userProfile.name : email}
                      className="dropdown_profile"
                    >
                      <NavDropdown.Item>
                        <Link className="nav-link" to="/dashboard">
                          User Dashboard
                        </Link>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <Link className="nav-link" to="/profile">
                          User Profile
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item>
                        <Link
                          className="nav-link"
                          onClick={() => {
                            onLogOut();
                          }}
                        >
                          Logout
                        </Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* <Link to="/dashboard" className="px-2">
                  <img src={notify} alt="Notification" className="img-fluid" />
                </Link> */}
                  </>
                ) : (
                  <Link to="/login" className="btn btn-success my-2 my-sm-0">
                    Login
                  </Link>
                )}
              </div>
            </li>
            <li className="nav-item">
              <NavLink to="/partner" className="btn btn-primary my-2 my-sm-0">
                partner with us
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  );
};
export default NavigationBar;
