import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAddress } from "../../../features/slices/userProfileSlice";
import Swal from "sweetalert2";
// import { getAllState } from "../../../features/slices/settingSlice";

const AddressForm = ({ userData }) => {
  const { states } = useSelector(state => state.home);

  const dispatch = useDispatch();

  const onSaveClick = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Address has been saved",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const { address1, address2, city, pincode, state, district } = userData;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address1: address1 || "",
      address2: address2 || "",
      pincode: pincode || "",
      district: district || "",
      city: city || "",
      state: state || "",
    },
    onSubmit: (values, { setSubmitting }) => {
      dispatch(updateUserAddress(values));
      onSaveClick();
      setSubmitting(false);
    },
  });

  // useEffect(() => {
  //   dispatch(getAllState());
  // }, [dispatch]);

  return (
    <React.Fragment>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            id="address1"
            name="address1"
            onChange={formik.handleChange}
            value={formik.values.address1}
            placeholder="Address Line1*"
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            id="address2"
            placeholder="Address Line2*"
            name="address2"
            onChange={formik.handleChange}
            value={formik.values.address2}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="number"
            className="form-control"
            id="pincode"
            name="pincode"
            placeholder="Pincode*"
            onChange={formik.handleChange}
            value={formik.values.pincode}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            name="district"
            id="district"
            placeholder="District*"
            onChange={formik.handleChange}
            value={formik.values.district}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            id="city"
            placeholder="City / Town / Locality / Village *"
            name="city"
            onChange={formik.handleChange}
            value={formik.values.city}
          />
        </Form.Group>
        <Form.Group>
          {/* <Form.Control
            type="text"
            className="form-control"
            id="state"
            placeholder="State*"
            name="state"
            onChange={formik.handleChange}
            value={formik.values.state}
          /> */}
          <Form.Select
            name="state"
            onChange={formik.handleChange}
            value={formik.values.state}
            aria-label="Default select example"
            className="form-control"
          >
            <option>State</option>
            {states.map(s => {
              return (
                <option key={s.id} value={s.name}>
                  {s.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>

        <div className="row">
          <div className="col-lg-12">
            <div className="btn_address">
              <a href="#" className="btn btn-cancel">
                Cancel
              </a>
              <button type="submit" className="btn btn-success">
                save
              </button>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default AddressForm;
