import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import OnlineForm from "../../../components/Form/OnlineForm";
import { getFaqByCategoryId } from "../../../features/slices/categorySlice";

const CategoryInfo = ({
  id,
  title,
  description,
  overview,
  banefit,
  basic_requirement,
  capital_structure,
  company_registration,
  cropbiz_assistance,
  documents,
  importance,
  moa_aoa,
  procedure,
  states,
  extra_head_des,
}) => {
  const dispatch = useDispatch();
  const { faqs } = useSelector(state => state.subCategory);
  useEffect(() => {
    dispatch(getFaqByCategoryId(id));
  }, [id]);

  // let heading_description = JSON.parse(extra_head_des);

  return (
    <section className="section_content pvt_info">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="title">
              <h2>Fastest {title} Registration In India | Same Day Process</h2>
            </div>
            <p>{description}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 pvt_desc">
            {overview?.replace(/<(.|\n)*?>/g, "").trim().length !== 0 ? (
              <React.Fragment>
                <h3>An Overview on {title}</h3>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: overview }}></div>
              </React.Fragment>
            ) : null}
            {banefit?.replace(/<(.|\n)*?>/g, "").trim().length !== 0 ? (
              <React.Fragment>
                <h3>Benefits of {title} Registration in India</h3>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: banefit }}></div>
              </React.Fragment>
            ) : null}
            {basic_requirement?.replace(/<(.|\n)*?>/g, "").trim().length !==
            0 ? (
              <React.Fragment>
                <h4>Basic requirements of Online {title}</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: basic_requirement }}
                ></div>
              </React.Fragment>
            ) : null}
            {company_registration?.replace(/<(.|\n)*?>/g, "").trim().length !==
            0 ? (
              <React.Fragment>
                <h4>
                  {title} Filing Form - Manual to New Web-based SPICe + Form
                </h4>
                <div
                  dangerouslySetInnerHTML={{ __html: company_registration }}
                ></div>
              </React.Fragment>
            ) : null}

            {/* <OnlineForm states={states} title={title} /> */}

            {documents?.replace(/<(.|\n)*?>/g, "").trim().length !== 0 ? (
              <React.Fragment>
                <h3>Documents for Online {title}</h3>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: documents }}></div>
              </React.Fragment>
            ) : null}
            {procedure?.replace(/<(.|\n)*?>/g, "").trim().length !== 0 ? (
              <React.Fragment>
                <h3>Procedure for {title} Online</h3>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: procedure }}></div>
              </React.Fragment>
            ) : null}

            {moa_aoa?.replace(/<(.|\n)*?>/g, "").trim().length !== 0 ? (
              <React.Fragment>
                <h3>MOA and AOA of a Company</h3>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: moa_aoa }}></div>
              </React.Fragment>
            ) : null}
            {importance?.replace(/<(.|\n)*?>/g, "").trim().length !== 0 ? (
              <React.Fragment>
                <h3>Importance of choosing the right business structure</h3>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: importance }}></div>
              </React.Fragment>
            ) : null}
            {capital_structure?.replace(/<(.|\n)*?>/g, "").trim().length !==
            0 ? (
              <React.Fragment>
                <h3>Capital Structure of Company</h3>
                <hr />
                <div
                  dangerouslySetInnerHTML={{ __html: capital_structure }}
                ></div>
              </React.Fragment>
            ) : null}
            {cropbiz_assistance?.replace(/<(.|\n)*?>/g, "").trim().length !==
            0 ? (
              <React.Fragment>
                <h3>CorpBiz Assistance</h3>
                <hr />
                <div
                  dangerouslySetInnerHTML={{ __html: cropbiz_assistance }}
                ></div>
              </React.Fragment>
            ) : null}
            {extra_head_des?.map((head_des, index) => {
              return (
                <React.Fragment key={index}>
                  <h3>{head_des.heading}</h3>
                  <hr />
                  <div
                    dangerouslySetInnerHTML={{ __html: head_des.description }}
                  ></div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <React.Fragment>
          {faqs?.length > 0 && (
            <div className="row">
              <h3>Frequently Asked Questions</h3>
              <hr />
            </div>
          )}

          <div className="row">
            <div className="col-sm-12">
              <div className="accordion_sec">
                <Accordion className="accordion" id="faq">
                  {faqs &&
                    faqs?.map(f => {
                      const { answer, question, id } = f;
                      return (
                        <Accordion.Item className="card" eventKey={id} key={id}>
                          <Accordion.Header className="card-header">
                            <a
                              href="/callusnow"
                              className="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target={id}
                              aria-expanded="true"
                              aria-controls="faq1"
                            >
                              {question}
                            </a>
                          </Accordion.Header>

                          <Accordion.Body className="card-body">
                            {answer}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                </Accordion>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </section>
  );
};

export default CategoryInfo;
