import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dashboard1 from "../../assets/images/dashboard.png";
import doc from "../../assets/images/doc.png";
import event from "../../assets/images/event.png";
import pay from "../../assets/images/pay.png";
import user from "../../assets/images/user_dashboard.png";
import { useSelector } from "react-redux";

const UserBoard = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const location = useLocation();

  const { cart } = useSelector(state => state.myCart);

  const onLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("cart");
    localStorage.removeItem("totalAmount");
    window.location.reload();
    navigate("/login");
  };

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);
  // var isActive = context.router.route.location.pathname === this.props.to;
  // var className = isActive ? "active" : "";

  return (
    <div className="menu_dashboard">
      <ul className="list-unstyled">
        <li>
          {" "}
          <Link
            to="/profile"
            className={"underline" + (url === "/profile" ? " active" : "")}
          >
            {" "}
            <img src={user} className="img-fluid" alt="dashboard" /> My Profile
          </Link>
        </li>
        <li>
          {" "}
          <Link
            to="/dashboard"
            className={"underline" + (url === "/dashboard" ? " active" : "")}
          >
            {" "}
            <img src={dashboard1} className="img-fluid" alt="dashboard" />{" "}
            Dashboard
          </Link>
        </li>
        <li>
          {" "}
          <Link
            to="/all-services"
            className={"underline" + (url === "/all-services" ? " active" : "")}
          >
            {" "}
            <img src={doc} className="img-fluid" alt="dashboard" /> All Services
          </Link>
        </li>
        <li>
          {" "}
          <Link
            to="/my-services"
            className={"underline" + (url === "/my-services" ? " active" : "")}
          >
            {" "}
            <img src={doc} className="img-fluid" alt="dashboard" /> My Services
          </Link>
        </li>
        <li>
          {" "}
          <Link
            to="/event-list"
            className={"underline" + (url === "/event-list" ? " active" : "")}
          >
            {" "}
            <img src={event} className="img-fluid" alt="dashboard" /> Event List
          </Link>
        </li>
        <li>
          {" "}
          <Link
            to="/payment"
            className={"underline" + (url === "/payment" ? " active" : "")}
          >
            {" "}
            <img src={pay} className="img-fluid" alt="dashboard" /> Payments
          </Link>
        </li>
        <li>
          <Link
            to="/my-cart"
            className={"underline" + (url === "/my-cart" ? " active" : "")}
          >
            {" "}
            <img src={pay} className="img-fluid" alt="dashboard" /> My Cart{" "}
            <span className="px-2 py-1 bg-success rounded-circle text-light font-weight-bold">
              {cart.length > 0 && cart ? cart.length : 0}
            </span>
          </Link>
        </li>
      </ul>

      <Button className="btn btn-dark" onClick={onLogOut}>
        Logout
      </Button>
    </div>
  );
};

export default UserBoard;
