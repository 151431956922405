// import React from "react";
// import { Calendar, dateFnsLocalizer } from "react-big-calendar";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import { format, parse, startOfWeek, getDay } from "date-fns";
// // moment.locale("en-GB");
// // Calendar.momentLocalizer(moment);
// const EventCalendar = ({ events }) => {
//   const locales = {
//     "en-US": require("date-fns"),
//   };
//   const localizer = dateFnsLocalizer({
//     format,
//     parse,
//     startOfWeek,
//     getDay,
//     locales,
//   });
//   return (
//     <React.Fragment>
//       <Calendar
//         events={events}
//         localizer={localizer}
//         defaultDate={new Date()}
//         style={{ height: 400 }}
//       />
//     </React.Fragment>
//   );
// };

// export default EventCalendar;

// import {
//   ScheduleComponent,
//   Month,
//   Inject,
//   ViewsDirective,
//   ViewDirective,
// } from "@syncfusion/ej2-react-schedule";

// import React from "react";

// const EventCalendar = ({ events }) => {
//   const eventSettings = { dataSource: events };

//   return (
//     <ScheduleComponent
//       width="100%"
//       height="550px"
//       selectedDate={new Date()}
//       eventSettings={eventSettings}
//     >
//       <ViewsDirective>
//         <ViewDirective option="Month" readonly={true} />
//       </ViewsDirective>

//       <Inject services={[Month]} />
//     </ScheduleComponent>
//   );
// };

// export default EventCalendar;

import React from "react";
import FullCalender from "@fullcalendar/react";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const EventCalendar = ({ events }) => {
  return (
    <FullCalender
      defaultView="dayGridMonth"
      header={{
        left: "prev,next",
        center: "title",
        right: "dayGridMonth",
      }}
      themeSystem="bootstrap"
      plugins={[bootstrapPlugin, dayGridPlugin]}
      events={events}
      displayEventEnd="true"
      eventColor={"#359784"}
      height="40vh"
    />
  );
};

export default EventCalendar;
