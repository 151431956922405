import React, { useEffect } from "react";

const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    //window.scrollY(0);
  }, []);
  return (
    <React.Fragment>
      <section className="terms_title">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title">
                <h2>We don't share personal information.</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section_content terms_content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul>
                <p>
                  By using this website, you're confirming that you're happy to
                  accept our use of cookies. This page tells you more about what
                  cookies are and how we use them.
                </p>

                <h5>What are cookies?</h5>
                <li>
                  Cookies are small data files that are placed onto your
                  computer's hard drive or in your web browser memory when you
                  visit a website. Almost every website uses cookies.
                </li>

                <h5>What are cookies for?</h5>
                <li>
                  Cookies help to make your experience of using a website
                  better. For example, they can remember the information you
                  supply as you register with a website so that you can sign in
                  with one click next time. What cookies don't do is store any
                  confidential information about you personally.
                </li>
                <li>
                  The cookies on our website simply allow us to track generic
                  usage of our website - not your individual usage behaviour.
                </li>

                <h5>Are cookies safe?</h5>
                <li>
                  Yes, they are. Cookies are small text files. They can't look
                  into your computer or read any personal information or other
                  material on your hard drive. Cookies can't transmit viruses or
                  install anything harmful on your computer.
                </li>

                <h5>Why should I keep cookies turned on?</h5>
                <li>
                  We would like you to keep cookies active on your computer
                  during your visits to our website because parts of the site
                  rely on them to work properly. For example, you would not be
                  able to view a location map without them.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CookiePolicy;
