import React from "react";

const BlogTitle = () => {
  return (
    <section className="page_title">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="title">
              <h1>Blogs</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogTitle;
