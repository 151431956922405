import React, { useEffect } from "react";
import CategoryBanner from "./Sections/CategoryBanner";
import CategoryInfo from "./Sections/CategoryInfo";
import CategoryPartner from "./Sections/CategoryPartner";
import CategorySteps from "./Sections/CategorySteps";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryById } from "../../features/slices/categorySlice";
import { getAllState } from "../../features/slices/settingSlice";
import HelmetBase from "../../components/Helmet/Helmetbase";
import { getAllServices } from "../../features/slices/HomeSlice";

const Category = ({ id }) => {
  const dispatch = useDispatch();

  const { category } = useSelector(state => state.subCategory);

  // const { states } = useSelector(state => state.settings);
  const { services, states } = useSelector(state => state.home);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCategoryById(id));
    // dispatch(getAllState());
    dispatch(getAllServices());
  }, [dispatch, id]);

  const findService = services?.filter(
    service => service.sub_category_id === id
  );

  const {
    title,
    banefit,
    basic_requirement,
    capital_structure,
    company_registration,
    cropbiz_assistance,
    description,
    documents,
    importance,
    moa_aoa,
    overview,
    path,
    procedure,
    heading1,
    heading2,
    heading3,
    heading4,
    heading5,
    market_price,
    ensurekar_price,
    page_description,
    page_title,
    extra_head_des,
  } = category;

  return (
    <React.Fragment>
      {category && (
        <HelmetBase
          title={page_title}
          description={page_description}
          link={path}
        />
      )}
      <CategoryBanner
        id={id}
        title={title}
        heading1={heading1}
        heading2={heading2}
        heading3={heading3}
        heading4={heading4}
        heading5={heading5}
        states={states}
        marketPrice={market_price}
        ensurekarPrice={ensurekar_price}
        categoryId={category.id}
        service={findService}
      />
      <CategorySteps states={states} />
      <CategoryInfo
        states={states}
        id={category.id}
        title={title}
        banefit={banefit}
        description={description}
        overview={overview}
        basic_requirement={basic_requirement}
        capital_structure={capital_structure}
        company_registration={company_registration}
        cropbiz_assistance={cropbiz_assistance}
        documents={documents}
        importance={importance}
        moa_aoa={moa_aoa}
        procedure={procedure}
        extra_head_des={extra_head_des}
      />

      <CategoryPartner />
      {/* <div className="d-flex justify-content-center align-items-center my-5">
        <iframe
          id="frame"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdMDtuOcqwJJgxVmlcqp3n5jIn08_EOJ3146GtiFTunpR6UGg/viewform?embedded=true"
          width="760"
          height="900"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading...
        </iframe>
      </div> */}
    </React.Fragment>
  );
};

export default Category;
