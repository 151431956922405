import baseAPI from "../../api/userApi";

const HomeService = {
  getAllTestimonial: async () => {
    try {
      const res = await baseAPI.get("/list-testimonial");
      const data = res.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  },
};
export default HomeService;
