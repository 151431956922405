import React, { useEffect } from "react";
import Description from "./Sections/Description";
import Title from "./Sections/Title";
import { useDispatch, useSelector } from "react-redux";
import { getBlogById } from "../../features/slices/blogSlice";
import HelmetBase from "../../components/Helmet/Helmetbase";

const BlogDetails = ({ id }) => {
  const { blog } = useSelector(state => state.blogs);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getBlogById(id));
  }, [dispatch]);

  // const desc = blog?.description.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "");

  return (
    <>
      <HelmetBase
        title={blog?.title}
        description={blog?.description}
        link={`/blogs/${blog?.slug}`}
      />
      <Title blogTitle={blog?.title} postDate={blog?.createdAt} />
      <Description
        blogImage={blog?.image}
        blogDescription={blog?.description}
      />
    </>
  );
};

export default BlogDetails;
