import baseAPI from "../../api/userApi";
import { authHeader } from "../../helpers/authHeaders";

const getPaymentServiceByUser = async () => {
  try {
    return await baseAPI.post(
      "/user-service-list",
      { status: "OnGoing" },
      { headers: authHeader() }
    );
  } catch (error) {
    console.log(error);
  }
};
const getPaymentServiceByUserCompleted = async () => {
  try {
    return await baseAPI.post(
      "/user-service-list",
      { status: "Completed" },
      { headers: authHeader() }
    );
  } catch (error) {
    console.log(error);
  }
};
const getPaymentServiceByUserClosed = async () => {
  try {
    return await baseAPI.post(
      "/user-service-list",
      { status: "Closed" },
      { headers: authHeader() }
    );
  } catch (error) {
    console.log(error);
  }
};

const getServiceByUserFav = async () => {
  try {
    return await baseAPI.get("/user-favourite-service-list", {
      headers: authHeader(),
    });
  } catch (error) {
    console.log(error);
  }
};

const userPayment = async data => {
  try {
    return await baseAPI.post("/service-booking", data, {
      headers: authHeader(),
    });
  } catch (error) {
    console.log(error);
  }
};

const userPaymentConfirm = async data => {
  try {
    return await baseAPI.post("/confirm-booking", data, {
      headers: authHeader(),
    });
  } catch (error) {
    console.log(error);
  }
};

const getCompletePaymentsList = async () => {
  try {
    return await baseAPI.post(
      "/user-service-list",
      { payment_status: "succeeded" },
      {
        headers: authHeader(),
      }
    );
  } catch (error) {
    console.log(error);
  }
};

const getPendingPaymentList = async () => {
  try {
    return await baseAPI.post(
      "/user-service-list",
      { payment_status: "pending" },
      {
        headers: authHeader(),
      }
    );
  } catch (error) {
    console.log(error);
  }
};

const getUserBuyPlans = async () => {
  try {
    return await baseAPI.post(
      "/user-plan-list",
      { payment_status: "succeeded" },
      {
        headers: authHeader(),
      }
    );
  } catch (error) {
    console.log(error);
  }
};
const PaymentService = {
  getPaymentServiceByUser,
  userPayment,
  userPaymentConfirm,
  getPaymentServiceByUserCompleted,
  getPaymentServiceByUserClosed,
  getServiceByUserFav,
  getCompletePaymentsList,
  getPendingPaymentList,
  getUserBuyPlans,
};
export default PaymentService;
