import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
const ContactHelp = props => {
  return (
    <section className="section_content contact_help">
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <div className="title">
              <h2>Need more help? </h2>
              <p>For any other product related queries, email us at:</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={4}>
            <div className="help_box text-center">
              <p>{props.Title}</p>
              <Link to="#">{props.Email}</Link>
            </div>
          </Col>

          <Col md={6} lg={4}>
            <div className="help_box text-center">
              <p>{props.Title1}</p>
              <Link to="#">{props.Email1}</Link>
            </div>
          </Col>

          <Col md={6} lg={4}>
            <div className="help_box text-center">
              <p>{props.Title2}</p>
              <Link to="#">{props.Email2}</Link>
            </div>
          </Col>

          {/* <Col md={6} lg={4}>
            <div className="help_box text-center">
              <p>{props.Title3}</p>
              <Link to="#">{props.Email3}</Link>
            </div>
          </Col>

          <Col md={6} lg={4}>
            <div className="help_box text-center">
              <p>{props.Title4}</p>
              <Link to="#">{props.Email4}</Link>
            </div>
          </Col>

          <Col md={6} lg={4}>
            <div className="help_box text-center">
              <p>{props.Title5}</p>
              <Link to="#">{props.Email5}</Link>
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default ContactHelp;
