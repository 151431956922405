import React, { useEffect } from "react";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <section className="terms_title">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title">
                <h2>We don't share personal information.</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section_content terms_content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>
                We thank you and appreciate your service or product purchase
                with us on our Website www.Ensurekar.com (hereinafter referred
                to as “Ensurekar”). Please read this policy and the Ensurekar
                terms and conditions carefully as they will give you important
                information and guidelines about your rights and obligations as
                our customer, with respect to any purchase or service we provide
                to you
              </p>
              <p>
                We make every effort to provide the service to you as per the
                specifications and timelines mentioned against each service or
                product purchased by you from Ensurekar, however if, due to any
                reason, we are unable to provide to you the service or product
                you purchased from us, then you shall be entitled to a refund
                which shall be subject on the following situations:
              </p>
              <ol>
                <li>
                  The Refund shall be only considered in the event there is a
                  clear, visible deficiency with the service or product
                  purchased from Ensurekar.
                </li>

                <li>
                  In the event a customer has paid for a service and then
                  requests for a refund only because there was a change in mind,
                  the refund shall not be considered as there is no fault,
                  defect, or onus on Ensurekar.
                </li>

                <li>
                  Refund requests shall not be entertained after the work has
                  been shared with you in the event of change of mind. However,
                  we shall give you the option of using the amount paid for by
                  you, for an alternative service in Ensurekar amounting to the
                  same value and the said amount could be applied in part or
                  whole towards the said new service; and
                </li>

                <li>
                  If the request for a refund has been raised 30 (thirty) days
                  after the purchase of a service or product has been completed
                  and the same has been intimated and indicated via email or
                  through any form of communication stating that the work has
                  been completed, then, such refund request shall be deemed
                  invalid and shall not be considered.
                </li>
              </ol>
              <p>
                If the request for the refund has been approved by Ensurekar,
                the same shall be processed and intimated to you via email. This
                refund process could take a minimum of 15 (fifteen) business
                days to process and shall be credited to your bank account
                accordingly. We shall handle the refund process with care and
                ensure that the money spent by you is returned to you at the
                earliest.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default RefundPolicy;
