import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CallUsModal from "../../components/Modal/CallUsModal";
// import { getAllSetting, getAllState } from "../../features/slices/settingSlice";
import { getAllData } from "../../features/slices/HomeSlice";
import HomeAchivement from "./Sections/HomeAchivement";
import HomeBanner from "./Sections/HomeBanner";
import HomeReasonSection from "./Sections/HomeReasonSection";
import HomeService from "./Sections/HomeService";
import HomeServicePackage from "./Sections/HomeServicePackage";
import HomeSupporterSection from "./Sections/HomeSupporterSection";
import HomeTestimonialSection from "./Sections/HomeTestimonialSection";
import HelmetBase from "../../components/Helmet/Helmetbase";
// import HomeCustomerlogo from "./Sections/HomeCustomerlogo";

export const Home = () => {
  const dispatch = useDispatch();

  const { settings, states } = useSelector(state => state.home);
  // const { states } = useSelector(state => state.settings);
  const [show, setShow] = useState(false);

  const onShowModal = () => {
    setShow(!show);
  };
  const onHide = () => {
    setShow(false);
  };

  // ===============================================================================
  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getAllSetting());
    dispatch(getAllData());
  }, [dispatch]);

  // const st = useSelector(state => state.home);
  // const st = useSelector(state => state.plan)
  // console.log("state is home.jsx", st.plans);
  // ==================================================================================
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   dispatch(getAllSetting());
  //   dispatch(getAllState());
  // }, [dispatch]);

  const {
    banner_image,
    banner_title,
    banner_sub_title,
    l_title,
    l_service_sub_title,
    image1,
    image1_subtitle,
    image1_description,
    image2,
    image2_subtitle,
    image2_description,
    image3,
    image3_subtitle,
    image3_description,
    image4,
    image4_subtitle,
    image4_description,
    o_title,
    o_sub_title,
    r_title,
    r_sub_title,
    achivement_title,
    achivement_description,
    page_description,
    page_title,
    google_form_link,
  } = settings;
  console.log("banner_img ", settings.banner_image);
  return (
    <React.Fragment>
      <HelmetBase title={page_title} description={page_description} link={""} />
      <CallUsModal show={show} onHide={onHide} states={states} />
      <HomeBanner
        bannerImage={banner_image}
        bannerTitle={banner_title}
        bannerSubTitle={banner_sub_title}
      />
      {/* <HomeCustomerlogo /> */}

      <HomeService
        LegalServiceTitle={l_title}
        LegaleServiceSubTitle={l_service_sub_title}
      />

      <HomeServicePackage title={o_title} sub_title={o_sub_title} />

      <HomeReasonSection
        ReasonTitle={r_title}
        ReasonSubTitle={r_sub_title}
        image1={image1}
        SubTitle1={image1_subtitle}
        Description1={image1_description}
        image2={image2}
        SubTitle2={image2_subtitle}
        Description2={image2_description}
        image3={image3}
        SubTitle3={image3_subtitle}
        Description3={image3_description}
        image4={image4}
        SubTitle4={image4_subtitle}
        Description4={image4_description}
      />

      <HomeAchivement
        title={achivement_title}
        description={achivement_description}
      />

      <HomeTestimonialSection />

      <HomeSupporterSection />

      <Button className="getstarted_btn" onClick={onShowModal}>
        Get Started
      </Button>
      <a
        className="btn btn-success feedback_btn p-3 rounded-circle text-light"
        href={google_form_link}
        target="_blank"
      >
        <i className="fa fa-file"></i>
      </a>
    </React.Fragment>
  );
};
