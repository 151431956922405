import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <section className="terms_title">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title">
                <h2>We don't share personal information.</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section_content terms_content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>1. GENERAL</h5>
              <p>
                <b>a)</b> This document is an electronic record in terms of
                Information Technology Act, 2000 and rules there under as
                applicable and the amended provisions pertaining to electronic
                records in various statutes as amended by the Information
                Technology Act, 2000. This electronic record is generated by a
                computer system and does not require any physical or digital
                signatures.
              </p>
              <p>
                <b>b)</b> This document is published in accordance with the
                provisions of Rule 3 (1) of the Information Technology
                (Intermediaries guidelines) Rules, 2011 that require publishing
                the rules and regulations, privacy policy and Terms of Use for
                access or usage of Ensurekar.com
              </p>
              <p>
                <b>c)</b> The domain name www.Ensurekar.com("Website"), is owned
                and operated by Ensurekar Legal solutions Pvt. Ltd.(“Company”) a
                Private Company limited by shares, incorporated under the
                provisions of the Companies Act, 2013, and having its registered
                office at Prince Info Park, No.81-B, 5th floor, A-Block, 2nd
                main road, Ambattur Industrial Estate Chennai - 600058, where
                such expression shall, unless repugnant to the context thereof,
                be deemed to include its respective representatives,
                administrators, employees, directors, officers, agents and their
                successors and assigns.
              </p>
              <p>
                <b>d)</b> For the purpose of this Privacy Policy (“Policy”),
                wherever the context so requires,
              </p>
              <p>
                <b>i)</b> The term ‘You’ &‘User’ shall mean any legal person or
                entity accessing or using the services provided on this Website,
                who is competent to enter into binding contracts, as per the
                provisions of the Indian Contract Act, 1872;
              </p>
              <p>
                <b>ii)</b> The terms ‘We’, ‘Us’& ‘Our’ shall mean the Website
                and/or the Company, as the context so requires.
              </p>
              <p>
                <b>iii)</b> The terms ‘Party’ & ‘Parties’ shall respectively be
                used to refer to the User and the Company individually and
                collectively, as the context so requires.
              </p>
              <p>
                <b>e)</b> The headings of each section in this Policy are only
                for the purpose of organizing the various provisions under this
                Policy in an orderly manner, and shall not be used by either
                Party to interpret the provisions contained herein in any
                manner. Further, it is specifically agreed to by the Parties
                that the headings shall have no legal or contractual value.
              </p>
              <p>
                <b>f)</b> The use of the Website by the User is solely governed
                by this Policy as well as the Terms of Use of the Website
                (“Terms”, available at www.Ensurekar.com), and any modifications
                or amendments made thereto by the Company from time to time, at
                its sole discretion. Visiting the home page of the Website
                and/or using any of the services provided on the Website shall
                be deemed to signify the User’s unequivocal acceptance of this
                Policy and the aforementioned Terms, and the User expressly
                agrees to be bound by the same. The User expressly agrees and
                acknowledges that the Terms and Policy are co-terminus, and that
                expiry / termination of either one will lead to the termination
                of the other.
              </p>
              <p>
                <b>g)</b> The User unequivocally agrees that this Policy and the
                aforementioned Terms constitute a legally binding agreement
                between the User and the Company, and that the User shall be
                subject to the rules, guidelines, policies, terms, and
                conditions applicable to any service that is provided by the
                Website, and that the same shall be deemed to be incorporated
                into the Terms, and shall be treated as part and parcel of the
                same. The User acknowledges and agrees that no signature or
                express act is required to make these Terms and the Policy
                binding on the User, and that the User’s act of visiting any
                part of the Website constitutes the User’s full and final
                acceptance of the Policy and the aforementioned Terms.
              </p>
              <p>
                <b>h)</b> The Parties expressly agree that the Company retains
                the sole and exclusive right to amend or modify the Policy and
                the aforementioned Terms without any prior permission or
                intimation to the User, and the User expressly agrees that any
                such amendments or modifications shall come into effect
                immediately. The User has a duty to periodically check the
                Policy and Terms, and stay updated on theirprovisions and
                requirements. If the User continues to use the Website following
                such a change, the User will be deemed to have consented to any
                and all amendments / modifications made to the Policy and Terms.
                In so far as the User complies with the Policy and Terms, he/she
                is granted a personal, non-exclusive, non-transferable,
                revocable, limited privilege to enter, access and use the
                Website.
              </p>

              <h5>2. COLLECTION OF PERSONAL AND OTHER INFORMATION</h5>
              <p>
                a) The User expressly agrees and acknowledges that the Company
                collects and storestheUser’s personal information, which is
                provided by the User from time to time on the Website, including
                but not limited to the User’s user name, passwords, email
                address, name, address, age, date of birth, sex, nationality,
                shopping preferences, browsing history, etc., as well as any
                images or other information uploaded/published by the User on
                the Website. The User is aware that this information will be
                used by the Company/Website to provide services and features
                targeted at the User, that are most likely to meet the User’s
                needs, and also to customize and improve the Website to make its
                users’ experiences safer and easier.
              </p>
              <p>
                b) The User is aware that the Company/Website may automatically
                track certain information about the User based upon the User’s
                IP address and the User’s behaviour on the Website, and the User
                expressly consents to the same. The User is aware that this
                information is used to do internal research on user
                demographics, interests, and behaviour, to enable the
                Company/Website to better understand, and cater to the interests
                of its users. The User is expressly made aware that such
                information may include the URL that the Uservisited prior to
                accessing the Website, the URL which the User subsequently
                visits (whether or not these URLs form a part of the Website),
                the User’s computer & web browser information, the User’s IP
                address, etc.
              </p>
              <p>
                c) If the User chooses to purchase products / services from the
                Website, the User consents to allowing the Company/Website to
                collect information about the User’s buying behaviour and
                trends.
              </p>

              <h5>3. COOKIES</h5>
              <p>
                a) The User is aware that a‘Cookie’ is a small piece of
                information stored by a web server on a web browser so it can
                later be traced back from that particular browser, and
                thatcookies are useful for enabling the browser to remember
                information specific to a given user, including but not limited
                to a User’s login identification, password, etc. The User is
                aware that the Website places both permanent and temporary
                cookies in the User’s computer's hard drive and web browser, and
                does hereby expressly consent to the same.
              </p>
              <p>
                b) The User is further aware that the Websiteuses data
                collection devices such as cookies on certain pages of the
                Website to help analyse web page flow, measure promotional
                effectiveness, and promote trust and safety, and that certain
                features of the Website are only available through the use of
                such cookies. While the User is free to decline the Website’s
                cookies if the User’s browser permits, the User may consequently
                be unable to use certain features on the Website
              </p>
              <p>
                c) Additionally, the User is aware that he/she might encounter
                ‘cookies’ or other similar devices on certain pages of the
                Website that are placed by third parties or affiliates of the
                Company/Website. The User expressly agrees and acknowledges that
                the Company/Website does not control the use of such
                cookies/other devices by third parties, that the Company/Website
                is in no way responsible for the same, and that the User assumes
                any and all risks in this regard.{" "}
              </p>

              <h5>4. DIVULGING/SHARING OF PERSONAL INFORMATION</h5>
              <p>
                a) The User is aware that the Website/Company may share the
                User’s personal information with other corporate entities and
                affiliates to help detect and prevent identity theft, fraud and
                other potentially illegal acts; correlate related or multiple
                accounts to prevent abuse of the Website’s services; and to
                facilitate joint or co-branded services, where such services are
                provided by more than one corporate entity. urna.
              </p>
              <p>
                b) The User is aware that the Website/Company may disclose
                personal information if required to do so by law or if the
                Website/Company in good faith believes that such disclosure is
                reasonably necessary to respond to subpoenas, courtorders, or
                other legal processes. The Website/Company may also disclose the
                User’s personal information to law enforcement offices, third
                party rights owners, or other third parties if it believes that
                such disclosure is reasonably necessary to enforce the Terms or
                Policy; respond to claims that an advertisement, posting or
                other content violates the rights of a third party; or protect
                the rights, property or personal safety of its users, or the
                general public.
              </p>
              <p>
                c) The User is further aware that the Website/Company and its
                affiliates may share / sell some or all of the User’s personal
                information with other business entities should the
                Company/Website (or its assets) plan to merge with, or be
                acquired by such business entity, or in the event of
                re-organization, amalgamation, or restructuring of the Company’s
                business. Such business entity or new entity will continue to be
                bound be the Terms and Policy, as may be amended from time to
                time.
              </p>

              <h5>5. SECURITY</h5>
              <p>
                Transactions on the Website are secure and protected. Any
                information entered by the User when transacting on the Website
                is encrypted to protect the User against unintentional
                disclosure to third parties. The User’s credit and debit card
                information is not received, stored by or retained by the
                Company / Website in any manner. This information is supplied by
                the User directly to the relevant payment gateway which is
                authorized to handle the information provided, and is compliant
                with the regulations and requirements of various banks and
                institutions and payment franchisees that it is associated with.
              </p>

              <h5>6. THIRD PARTY ADVERTISEMENTS / PROMOTIONS</h5>
              <p>
                The User is aware that the Company/Website uses third-party
                advertising companies to serve ads to the users of the Website.
                The User is aware that these companies may use information
                relating to the User’s visits to the Website and other websites
                in order to provide customised advertisements to the User.
                Furthermore, the Website may contain links to other websites
                that may collect personally identifiable information about the
                User. The Company/Website is not responsible for the privacy
                practices or the content of any of the aforementioned linked
                websites, and the User expressly acknowledges the same and
                agrees that any and all risks associated will be borne entirely
                by the User.
              </p>

              <h5>7. USER’S CONSENT</h5>
              <p>
                By using the Website and/ or by providing information to the
                Company through the Website, the User consents to the collection
                and use of the information disclosedby the User on the Website
                in accordance with this Policy, including but not limited to the
                User’s consent the Company/Website sharing/divulging the User’s
                information, as per the terms contained hereinabove in Section 4
                of the Policy.
              </p>

              <h5>8. GRIEVANCE OFFICER</h5>
              <p>
                In accordance with Information Technology Act 2000 and rules
                made there under, the name and contact details of the Grievance
                Officer are provided below: Nishant Shah
                (E-mail:nishant@vakilsearch.com)
              </p>

              <h5>9. DISPUTE RESOLUTION AND JURISDICTION</h5>
              <p>
                It is expressly agreed to by the Parties hereto that the
                formation, interpretation and performance of this Policy and any
                disputes arising herefrom will be resolved through a two-step
                Alternate Dispute Resolution (“ADR”) mechanism. It is further
                agreed to by the Parties that the contents of this Section shall
                survive even after the termination or expiry of the Policy
                and/or Terms.
              </p>
              <p>
                a) Mediation: In case of any dispute between the parties, the
                Parties will attempt to resolve the same amicably amongst
                themselves, to the mutual satisfaction of both Parties. In the
                event that the Parties are unable to reach such an amicable
                solution within thirty (30) days of one Party communicating the
                existence of a dispute to the other Party, the dispute will be
                resolved by arbitration, as detailed hereinbelow.
              </p>
              <p>
                b) Arbitration: In the event that the Parties are unable to
                amicably resolve a dispute by mediation, said dispute will be
                referred to arbitration by a sole arbitrator to be appointed by
                the Company, and the award passed by such sole arbitrator will
                be valid and binding on both Parties. The Parties shall bear
                their own costs for the proceedings, although the sole
                arbitrator may, in his/her sole discretion, direct either Party
                to bear the entire cost of the proceedings. The arbitration
                shall be conducted in English, and the seat of Arbitration shall
                be the city of Mumbai in the state of Maharashtra, India.
              </p>
              <p>
                The Parties expressly agree that the Terms, Policy and any other
                agreements entered into between the Parties are governed by the
                laws, rules and regulations of India, and that the Courts at
                Chennai, Tamil Nadu, shall have exclusive jurisdiction over any
                disputes arising between the Parties.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
