import React from "react";
import loading from "../../assets/images/loading.gif";
const Loading = () => {
  return (
    <div>
      <div className="spinner-container">
        <div className="loading-spinner">
          {" "}
          <img src={loading} alt="Loading..." />
        </div>
      </div>
    </div>
  );
};

export default Loading;
