import React from "react";
import AboutTitle from "./Sections/AboutTitle";
import AboutContent from "./Sections/AboutContent";
import AboutAchivement from "./Sections/AboutAchievment";
import AboutInfo from "./Sections/AboutInfo";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAboutusById } from "../../features/slices/aboutusSlice";
import HelmetBase from "../../components/Helmet/Helmetbase";

const AboutUs = () => {
  const { about } = useSelector(state => state.about);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAboutusById(1));
  }, [dispatch]);

  return (
    <>
      <HelmetBase
        title={about?.page_title}
        description={about?.page_description}
        link={"aboutus"}
      />
      <AboutTitle bannerImage={about?.image1} bannerTitle={about?.title} />
      <AboutContent
        contentImage={about?.image2}
        description={about?.description}
      />
      <AboutInfo
        infoImage={about?.image3}
        shortDescription={about?.shot_description}
      />
      <AboutAchivement />
    </>
  );
};

export default AboutUs;
