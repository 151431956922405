import moment from "moment";
import React from "react";
import { Button } from "react-bootstrap";

const MyPlans = ({ userBuyPlans }) => {
  return (
    <React.Fragment>
      {userBuyPlans.map((userPlans, index) => {
        const { plan_name, plan_fee, createdAt, end_date } = userPlans;
        const startDate = moment(createdAt).format("DD-MM-YYYY");
        const endDate = moment(end_date).format("DD-MM-YYYY");
        return (
          <div className="service_block" key={index}>
            <div className="row align-items-center">
              <div className="fav_icon"></div>
              <div className="col-lg-9">
                <div className="service_block_left">
                  <p className="font-weight-bold">{plan_name}</p>
                  <p>Start Date: {startDate}</p>
                  <p>End Date: {endDate}</p>
                  <h4 className="price">
                    ₹{plan_fee}/- <span> only (Excluded)</span>{" "}
                  </h4>
                </div>
              </div>
              <div className="col-lg-3">
                <Button className="btn btn-success">ongoing</Button>
              </div>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default MyPlans;
