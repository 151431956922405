import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BlogService from "../services/BlogsService";

export const getBlogById = createAsyncThunk("blog/getBlogById", async id => {
  try {
    const res = await BlogService.getBlogById(id);
    return await res.data.data;
  } catch (error) {
    console.log(error);
  }
});
export const getAllBlogs = createAsyncThunk("blog/getAllBlogs", async () => {
  try {
    const res = await BlogService.getAllBlogs();
    return res.data.data;
  } catch (error) {
    console.log(error);
  }
});

const initialState = {
  blogs: [],
  loading: false,
  error: "",
  blog: {},
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  extraReducers(builder) {
    // GET ALL BLOGS
    builder
      .addCase(getAllBlogs.pending, state => {
        state.loading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload;
      })
      .addCase(getAllBlogs.rejected, (state, action) => {
        state.loading = false;
        state.blogs = [];
      });

    //GET BY ID BLOG
    //
    builder
      .addCase(getBlogById.pending, state => {
        state.loading = true;
      })
      .addCase(getBlogById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.blog = payload;
      })
      .addCase(getBlogById.rejected, (state, { payload }) => {
        state.loading = false;
        state.blog = {};
        state.error = payload;
      });
  },
});

export default blogSlice.reducer;
