import React from "react";
import UserBoard from "./UserBoard";

const UserDashboardLayout = ({ children }) => {
  return (
    <React.Fragment>
      <section className="dashboard_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="left_sec">
                <UserBoard />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="left_sec">{children}</div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default UserDashboardLayout;
