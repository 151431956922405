import { useFormik } from "formik";
import React from "react";
import ShowError from "../../../errors/ShowError";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { addContact } from "../../../features/slices/contactusSlice";
import { ContactContentSchema } from "../../../helpers/validators/ContactContentSchema";
import ContactModal from "../../../components/Modal/ContactModal";
import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

const SectionContent = props => {
  const [modalShow, setModalShow] = React.useState(false);
  const { services } = useSelector(state => state.home);
  const { states } = useSelector(state => state.home);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getAllService());
  // }, [dispatch]);

  const sendMessage = values => {
    dispatch(addContact(values));
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Thank You For Contact Us",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      state: "",
      mobile_number: "",
      massage: "",
      service_id: undefined,
    },
    validationSchema: ContactContentSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      sendMessage(values);
      // setModalShow(true);
      resetForm({});
      setSubmitting(false);
    },
  });

  return (
    <>
      <ContactModal show={modalShow} onHide={() => setModalShow(false)} />
      <section className="section_content">
        <Container>
          <Row className="align-items-center">
            <Col md={12} lg={4}>
              <div className="info">
                <div className="title">
                  <h2>{props.Title}</h2>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: props.Description }}
                ></div>
              </div>
            </Col>

            <Col md={12} lg={8}>
              <div className="contact_form">
                <Form onSubmit={formik.handleSubmit} method="POST">
                  <Row>
                    <Col sm={12} md={6} className="mb-3">
                      <Form.Control
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        className="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <ShowError>{formik.errors.name}</ShowError>
                      )}
                    </Col>
                    <Col sm={12} md={6} className="mb-3">
                      <Form.Control
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <ShowError>{formik.errors.email}</ShowError>
                      )}
                    </Col>
                    <Col sm={12} md={6} className="mb-3">
                      <Form.Control
                        type="text"
                        id="mobile_number"
                        name="mobile_number"
                        placeholder="Phone"
                        className="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.mobile_number}
                      />
                      {formik.touched.mobile_number &&
                        formik.errors.mobile_number && (
                          <ShowError>{formik.errors.mobile_number}</ShowError>
                        )}
                    </Col>
                    <Col sm={12} md={6} className="mb-3">
                      <Form.Select
                        id="state"
                        name="state"
                        className="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.state}
                      >
                        <option selected>Select State</option>
                        {states.map(s => {
                          return (
                            <option value={s.name} key={s.id}>
                              {s.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                    <Col sm={12} md={6} className="mb-3">
                      <Form.Select
                        id="service_id"
                        name="service_id"
                        className="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.service_id}
                      >
                        {" "}
                        <option>Services </option>
                        {services?.map(service => {
                          const { id, title } = service;
                          return (
                            <option value={id} key={id}>
                              {title}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {formik.touched.service_id &&
                        formik.errors.service_id && (
                          <ShowError>{formik.errors.service_id}</ShowError>
                        )}
                    </Col>
                    <Col sm={12} md={6} className="mb-3">
                      <Form.Control
                        type="text"
                        id="massage"
                        name="massage"
                        as="textarea"
                        placeholder="Message"
                        rows="3"
                        className="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.massage}
                      />
                      {formik.touched.massage && formik.errors.massage && (
                        <ShowError>{formik.errors.massage}</ShowError>
                      )}
                    </Col>
                  </Row>
                  <Button variant="success" type="submit">
                    send message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SectionContent;
