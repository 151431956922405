import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import MainComponent from "../Form/MainComponent";
import GetStartedForm from "../Form/GetStartedForm";

const CallUsModal = ({ show, onHide, states }) => {
  return (
    <>
      <Modal
        show={show}
        size="md-4"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onHide}
      >
        <Modal.Body>
          <GetStartedForm states={states} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CallUsModal;
