import { useFormik } from "formik";
import React, { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ImageFilePreview from "../../../components/ImageFilePreview/ImageFilePreview";
import ImagePreview from "../../../components/ImagePreview/ImagePreview";

import { updateKYC } from "../../../features/slices/userProfileSlice";
import Swal from "sweetalert2";
const KYCForm = ({ userData }) => {
  const onSaveClick = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "KYC has been saved",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const dispatch = useDispatch();
  const pancard_picture = useRef();
  const aadhaar_picture = useRef();
  const profile_picture = useRef();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pancard_picture: "",
      aadhaar_picture: "",
      profile_picture: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      dispatch(updateKYC(values));
      onSaveClick();
      setSubmitting(false);
    },
  });

  return (
    <React.Fragment>
      <Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Form.Group>
          <Form.Label>Pan Card</Form.Label>
          <div>
            <Button
              variant="success"
              onClick={() => pancard_picture.current.click()}
            >
              Upload Pan Card
            </Button>
          </div>
          <Form.Control
            hidden
            ref={pancard_picture}
            name="pancard_picture"
            type="file"
            accept="image/*"
            onChange={e =>
              formik.setFieldValue("pancard_picture", e.currentTarget.files[0])
            }
          />{" "}
          {userData && !formik.values.pancard_picture ? (
            <div className="mt-2">
              {userData.pancard_picture ? (
                <ImagePreview
                  width={200}
                  height={100}
                  src={userData.pancard_picture}
                  alt="PAN CARD"
                />
              ) : null}
            </div>
          ) : (
            formik.values.pancard_picture && (
              <ImageFilePreview
                width={200}
                height={100}
                file={formik.values.pancard_picture}
              />
            )
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>Aadhar Card</Form.Label>
          <div>
            <Button
              variant="success"
              onClick={() => aadhaar_picture.current.click()}
            >
              Aadhar Number
            </Button>
          </div>
          <Form.Control
            hidden
            ref={aadhaar_picture}
            name="aadhaar_picture"
            type="file"
            accept="image/*"
            onChange={e =>
              formik.setFieldValue("aadhaar_picture", e.currentTarget.files[0])
            }
          />{" "}
          {userData && !formik.values.aadhaar_picture ? (
            <div className="mt-2">
              {userData.aadhaar_picture ? (
                <ImagePreview
                  width={200}
                  height={100}
                  src={userData.aadhaar_picture}
                  alt="AADHAR CARD"
                />
              ) : null}
            </div>
          ) : (
            formik.values.aadhaar_picture && (
              <ImageFilePreview
                width={200}
                height={100}
                file={formik.values.aadhaar_picture}
              />
            )
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>Profile Image</Form.Label>
          <div>
            <Button
              variant="success"
              onClick={() => profile_picture.current.click()}
            >
              Profile Image
            </Button>
          </div>
          <Form.Control
            hidden
            ref={profile_picture}
            name="profile_picture"
            type="file"
            accept="image/*"
            onChange={e =>
              formik.setFieldValue("profile_picture", e.currentTarget.files[0])
            }
          />{" "}
          {userData && !formik.values.profile_picture ? (
            <div className="mt-2">
              {userData.profile_picture ? (
                <ImagePreview
                  width={120}
                  height={120}
                  src={userData.profile_picture}
                  alt="PROFILE IMAGE"
                />
              ) : null}
            </div>
          ) : (
            formik.values.profile_picture && (
              <ImageFilePreview
                width={120}
                height={120}
                file={formik.values.profile_picture}
              />
            )
          )}
        </Form.Group>
        <div className="row">
          <div className="col-lg-12">
            <div className="btn_address mt-5">
              <a className="btn btn-cancel">Cancel</a>
              <Button className="btn btn-success" type="submit">
                save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default KYCForm;
