import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getAllAchivement } from "../../../features/slices/HomeSlice";
import { getAllSetting } from "../../../features/slices/settingSlice";
const AboutAchivement = () => {
  const { achivements } = useSelector(state => state.home);
  const { settings } = useSelector(state => state.home);
  const { achivement_title, achivement_description } = settings;
  // const dispatch = useDispatch();

  // useEffect(() => {
  // dispatch(getAllAchivement());
  // dispatch(getAllSetting());
  // }, [dispatch]);

  return (
    <section className="achievements_sec about_achievement">
      <div className="container">
        <div className="row">
          <div className="col-sm-7 ">
            <div className="row justify-content-center">
              {achivements.map((item, index) => {
                const { sub_title, achviment_number } = item;
                if (sub_title && achviment_number) {
                  return (
                    <div className="col-sm-4 col-md-6 col-lg-4" key={index}>
                      <div className="count_box">
                        <h2>{achviment_number}</h2>
                        <p>{sub_title}</p>
                      </div>
                    </div>
                  );
                } else {
                  return;
                }
              })}
            </div>
          </div>
          <div className="col-sm-5">
            <div className="title">
              <h2 className="mb-0">{achivement_title}</h2>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: achivement_description }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutAchivement;
