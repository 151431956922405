import React, { useEffect } from "react";
import UserDashboardLayout from "../UserDashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompletePaymentsList,
  getPendingPaymentList,
} from "../../../features/slices/paymentSlice";

// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import loadScript from "../../../helpers/razorpay/loadscript";
// import { authHeader } from "../../../helpers/authHeaders";

const Payment = () => {
  // const navigate = useNavigate();

  const { userServiceCompleteList } = useSelector(state => state.payments);

  const dispatch = useDispatch();

  // const email = localStorage.getItem("email");

  useEffect(() => {
    dispatch(getCompletePaymentsList());
    dispatch(getPendingPaymentList());
  }, [dispatch]);

  // async function displayRazor(serviceId, price) {
  //   console.log(serviceId);
  //   // onHide();
  //   const res = await loadScript(
  //     "https://checkout.razorpay.com/v1/checkout.js"
  //   );

  //   if (!res) {
  //     console.log("No Result");
  //     return;
  //   }

  //   const result = await axios.post(
  //     "https://redspark.a2hosted.com:30005/user/service-booking",
  //     {
  //       service_id: serviceId,
  //       payment_status: "pending",
  //       status: "OnGoing",
  //       state: "GUJARAT",
  //     },
  //     { headers: authHeader() }
  //   );

  //   if (!result) {
  //     console.log("No Data.. Some Error Occured");
  //     return;
  //   }

  //   const { amount, order_id, currency, id } = result.data.data;
  //   const options = {
  //     key: "rzp_test_58z0T5FmdF7ryD",
  //     currency: "INR",
  //     amount: price,
  //     name: "EnsureKar",
  //     order_id: order_id,
  //     handler: async function (response) {
  //       const payment_id = response.razorpay_payment_id;
  //       const order_id = response.razorpay_order_id;
  //       const signature = response.razorpay_signature;
  //       await axios.post(
  //         "https://redspark.a2hosted.com:30005/user/confirm-booking",
  //         {
  //           payment_id: payment_id,
  //           order_id: order_id,
  //           razorpay_signature: signature,
  //         },
  //         { headers: authHeader() }
  //       );
  //       window.scrollTo(0, 0);
  //       navigate("/thank-you");
  //       // alert("PAYMENT ID ::" + response.razorpay_payment_id);
  //       // alert("ORDER ID :: " + response.razorpay_order_id);
  //       // alert("SIGNATURE :: " + response.razorpay_signature);
  //     },
  //     prefill: {
  //       email: email,
  //     },
  //     theme: {
  //       color: "#359784",
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // }

  return (
    <React.Fragment>
      {/* {filterService && (
        <ServiceModal
          show={modalState === "service-modal"}
          onHide={() => handleClose()}
          filterService={filterService}
          state={state}
        />
      )} */}

      <UserDashboardLayout>
        <h4>Payments</h4>
        <hr />
        <div className="row">
          <div className="col-lg-12">
            <div className="all_service">
              <div className="service_tab">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-due-tab"
                      data-toggle="pill"
                      href="#pills-due"
                      role="tab"
                      aria-controls="pills-due"
                      aria-selected="true"
                    >
                      Dues
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-complete-tab"
                      data-toggle="pill"
                      href="#pills-complete"
                      role="tab"
                      aria-controls="pills-complete"
                      aria-selected="false"
                    >
                      Completed
                    </a>
                  </li>
                </ul>
                <div
                  className="tab-content service_tabcontent"
                  id="pills-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="pills-due"
                    role="tabpanel"
                    aria-labelledby="pills-due-tab"
                  >
                    {/* {userPendingPaymentList &&
                      userPendingPaymentList.map((u, index) => {
                        const {
                          service_name,
                          price,
                          penalty,
                          benefits,
                          service_id,
                        } = u;
                        return (
                          <div className="service_block" key={index}>
                            <div className="row align-items-center">
                              <div className="fav_icon"></div>
                              <div className="col-md-9 col-lg-9">
                                <div className="service_block_left">
                                  <p className="font-weight-bold">
                                    {service_name}
                                  </p>
                                  <h4 className="price">
                                    ₹{price}/-{" "}
                                    <span> only (Exclusive)</span>{" "}
                                  </h4>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: benefits,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="col-md-3 col-lg-3">
                                <Button
                                  className="btn btn-success btn-block"
                                  onClick={() => {
                                    displayRazor(service_id, price);
                                  }}
                                >
                                  pay now
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })} */}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-complete"
                    role="tabpanel"
                    aria-labelledby="pills-complete-tab"
                  >
                    {userServiceCompleteList &&
                      userServiceCompleteList.map((u, index) => {
                        const { service_name, price, benefits } = u;
                        return (
                          <div className="service_block" key={index}>
                            <div className="row align-items-center">
                              <div className="fav_icon"></div>
                              <div className="col-md-9 col-lg-9">
                                <div className="service_block_left">
                                  <p className="font-weight-bold">
                                    {service_name}
                                  </p>
                                  <h4 className="price">
                                    ₹{price}/- <span> only (Exclusive)</span>{" "}
                                  </h4>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: benefits,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="col-md-3 col-lg-3">
                                {/*<Button className="btn btn-success btn-block">
                                pay now
                      </Button>*/}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UserDashboardLayout>
    </React.Fragment>
  );
};

export default Payment;
