import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiSearchAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
// import { getAllSubCategory } from "../../../features/slices/HomeSlice";

const HomeBanner = props => {
  const [searchItems, setSearchItems] = useState("");
  const [searchParam] = useState(["title"]);

  const { subCategorys } = useSelector(state => state.home);
  console.log("banner_img from props ", props.bannerImage);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getAllSubCategory());
  // }, [dispatch, searchItems]);

  const filteredSubCategories = subCategorys => {
    return subCategorys.filter(item => {
      return searchParam.some(newUser => {
        return (
          item &&
          item[newUser]
            .toString()
            .toLowerCase()
            .indexOf(searchItems.toLowerCase()) > -1
        );
      });
    });
  };
  console.log(
    "check banner ",
    `url(${process.env.REACT_APP_IMAGE_URL + props.bannerImage})`
  );
  const BannerStyle = {
    backgroundImage: `url(${
      process.env.REACT_APP_IMAGE_URL + props.bannerImage
    })`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <section className="banner_sec" style={BannerStyle}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="banner_info">
              <h2>{props.bannerTitle}</h2>
            </div>
          </div>
          <div className="col-sm-7 mx-auto">
            <div className="main">
              <form>
                <input
                  placeholder="Search Now"
                  type="search"
                  value={searchItems}
                  onChange={e => setSearchItems(e.target.value)}
                />
                <Link className="search-button">
                  <div className="icon">
                    <BiSearchAlt size={25} />
                  </div>
                </Link>
              </form>
              {searchItems && (
                <div className="bg-light p-4 search_box">
                  {filteredSubCategories &&
                    filteredSubCategories(subCategorys).map(s => (
                      <h6 key={s.id}>
                        <Link to={s.path}>{s.title}</Link>
                      </h6>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 text-center">
            <p className="mt-3">
              {" "}
              <span> What's popular:&nbsp;</span>
              {props.bannerSubTitle}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
