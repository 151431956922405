import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CommonService from "../services/CommonService";

export const getAllSetting = createAsyncThunk(
  "setting/getAllSetting",
  async () => {
    try {
      const res = await CommonService.getCommonDataService();
      return res.data.data.setting[0];
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllState = createAsyncThunk("setting/getAllState", async () => {
  try {
    const res = await CommonService.getCommonDataService();
    return res.data.data.state;
  } catch (error) {
    console.log(error);
  }
});

export const getAllCityByState = createAsyncThunk(
  "setting/getAllCityByState",
  async id => {
    try {
      const res = await CommonService.getAllCityByState(id);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getDasboardImages = createAsyncThunk(
  "setting/getDasboardImages",
  async id => {
    try {
      const res = await CommonService.getDasboardImages();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPartnerPage = createAsyncThunk(
  "setting/getPartnerPage",
  async id => {
    try {
      const res = await CommonService.getPartnerPage(id);

      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  cities: [],
  states: [],
  settings: [],
  loading: false,
  error: "",
  setting: {
    header_logo: "",
    banner_image: "",
    banner_title: "",
    banner_sub_title: "",
    l_title: "",
    l_service_sub_title: "",
    image: "",
    image_sub_title: "",
    image1: "",
    image_sub_title_1: "",
    image2: "",
    image_sub_title_2: "",
    image3: "",
    image_sub_title_3: "",
    r_title: "",
    r_sub_title: "",
    helpline_no: "",
    helpline_text: "",
    footer_logo: "",
    page_description: "",
    page_title: "",
    google_form_link: "",
  },
  dashboardImages: {},
  partnerPage: {},
};
const settingSlice = createSlice({
  name: "setting",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllSetting.pending, state => {
        state.loading = true;
      })
      .addCase(getAllSetting.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.setting = payload;
      })
      .addCase(getAllSetting.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(getAllState.pending, state => {
        state.loading = true;
      })
      .addCase(getAllState.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.states = payload;
      })
      .addCase(getAllState.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(getAllCityByState.pending, state => {
        state.loading = true;
      })
      .addCase(getAllCityByState.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cities = payload;
      })
      .addCase(getAllCityByState.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(getDasboardImages.pending, state => {
        state.loading = true;
      })
      .addCase(getDasboardImages.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.dashboardImages = payload;
      })
      .addCase(getDasboardImages.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(getPartnerPage.pending, state => {
        state.loading = true;
      })
      .addCase(getPartnerPage.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.partnerPage = payload;
      })
      .addCase(getPartnerPage.rejected, state => {
        state.loading = false;
      });
  },
});
export default settingSlice.reducer;
