import baseAPI from "../../api/userApi";
import { authHeader } from "../../helpers/authHeaders";

const addGetStarted = async (data) => {
  const obj = {
    ...data,
    mobile_number: data.mobileNumber,
  };
  try {
    return await baseAPI.post("/create-getstarted", obj);
  } catch (error) {
    console.log(error);
  }
};

const GetStartedService = {
  addGetStarted,
};
export default GetStartedService;
