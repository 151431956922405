import React, { useState } from "react";
import shape1 from "../../../assets/images/h2-video-shape1.svg";
import Group from "../../../assets/images/Group.png";
import dashicons from "../../../assets/images/dashicons_edit-page.png";
import bxs from "../../../assets/images/bxs_phone-call.png";
import ant from "../../../assets/images/ant-design_mail-filled.png";
import CallUsModal from "../../../components/Modal/CallUsModal";
import { Button } from "react-bootstrap";
const CategorySteps = ({ states }) => {
  const [show, setShow] = useState(false);

  const onHandleClose = () => {
    setShow(!show);
  };

  return (
    <section className="pvt_steps">
      <CallUsModal show={show} onHide={onHandleClose} states={states} />
      <div className="dot_square">
        <img src={shape1} className="img-fluid" alt="shape" />
      </div>
      <div className="circle_1">
        <img src={Group} className="img-fluid" alt="pvt" />
      </div>

      <div className="circle_2">
        <img src={Group} className="img-fluid" alt="pvt" />
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-4 order-2 order-sm-1 order-md-1">
            <div className="expert_block">
              <h4>Talk to an Expert </h4>
              <Button
                className="btn btn-primary"
                onClick={() => setShow(!show)}
              >
                call us now
              </Button>
            </div>
          </div>

          <div className="col-md-12 col-lg-8 order-1 order-sm-2 order-md-2">
            <div className="row">
              <div className="col-md-4 col-lg-4">
                <div className="step_box">
                  <h5>Step 1</h5>
                  <div className="step_img">
                    <img src={dashicons} className="img-fluid" alt="step" />
                  </div>
                  <p>
                    We help you register your directors with the Ministry of
                    Corporate Affairs (MCA).
                  </p>
                </div>
              </div>

              <div className="col-md-4 col-lg-4">
                <div className="step_box">
                  <h5>Step 2</h5>
                  <div className="step_img">
                    <img src={bxs} className="img-fluid" alt="step" />
                  </div>
                  <p>We help you pick the right company name.</p>
                </div>
              </div>

              <div className="col-md-4 col-lg-4">
                <div className="step_box">
                  <h5>Step 3</h5>
                  <div className="step_img">
                    <img src={ant} className="img-fluid" alt="step" />
                  </div>
                  <p>
                    We draft and file the documents required for your company
                    registration (MoA and AoA).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategorySteps;
