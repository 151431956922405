import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CommonService from "../services/CommonService";
import HomeService from "../services/HomeService";

// ----------------------------------------------------------
export const getAllData = createAsyncThunk("home/getAllData", async () => {
  try {
    const res = await CommonService.getCommonDataService();
    return res.data.data;
  } catch (error) {
    console.log("Error : ", error);
  }
});
// export const getAllPlan = createAsyncThunk("home/getAllPlan", async () => {
//   try {
//     const res = await CommonService.getCommonDataService();
//     return res.data.data.plan;
//   } catch (error) {
//     console.log(error);
//   }
// });
// export const getAllSuppoters = createAsyncThunk(
//   "home/getAllSuppoters",
//   async () => {
//     try {
//       const res = await CommonService.getCommonDataService();
//       return res.data.data.suppoter;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );
// export const getAllAchivement = createAsyncThunk(
//   "home/getAllAchivement",
//   async () => {
//     try {
//       const res = await CommonService.getCommonDataService();
//       console.log("res.data.data is ", res.data.data);
//       return res.data.data.achivement;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );
// export const getAllSubCategory = createAsyncThunk(
//   "home/getAllSubCategory",
//   async () => {
//     try {
//       const res = await CommonService.getCommonDataService();
//       return res.data.data.subCategory;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

// export const getAllServices = createAsyncThunk(
//   "home/getAllServices",
//   async () => {
//     try {
//       const res = await CommonService.getCommonDataService();
//       return res.data.data.service;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );
// ===========================================================

export const getAllTestimonial = createAsyncThunk(
  "home/getAllTestimonial",
  async () => {
    try {
      const res = await HomeService.getAllTestimonial();

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
// export const getAllPlan = createAsyncThunk("home/getAllPlan", async () => {
//   try {
//     const res = await CommonService.getCommonDataService();
//     return res.data.data.plan;
//   } catch (error) {
//     console.log(error);
//   }
// });
// export const getAllSuppoters = createAsyncThunk(
//   "home/getAllSuppoters",
//   async () => {
//     try {
//       const res = await CommonService.getCommonDataService();
//       return res.data.data.suppoter;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

// export const getAllAchivement = createAsyncThunk(
//   "home/getAllAchivement",
//   async () => {
//     try {
//       const res = await CommonService.getCommonDataService();
//       console.log("res.data.data is ", res.data.data);
//       return res.data.data.achivement;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

export const getAllLegalService = createAsyncThunk(
  "home/getAllLegalService",
  async () => {
    try {
      const res = await CommonService.fetchLegalService();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// export const getAllSubCategory = createAsyncThunk(
//   "home/getAllSubCategory",
//   async () => {
//     try {
//       const res = await CommonService.getCommonDataService();
//       return res.data.data.subCategory;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

export const getAllServices = createAsyncThunk(
  "home/getAllServices",
  async () => {
    try {
      const res = await CommonService.getCommonDataService();
      return res.data.data.service;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  subCategorys: [],
  testimonials: [],
  legalServices: [],
  plans: [],
  suppoters: [],
  achivements: [],
  settings: [],
  states: [],
  services: [],
  categorys: [],
  occupations: [],
  subCategorys: [],
  subCategory: {},
  category: {},
  setting: {
    header_logo: "",
    banner_image: "",
    banner_title: "",
    banner_sub_title: "",
    l_title: "",
    l_service_sub_title: "",
    image: "",
    image_sub_title: "",
    image1: "",
    image_sub_title_1: "",
    image2: "",
    image_sub_title_2: "",
    image3: "",
    image_sub_title_3: "",
    r_title: "",
    r_sub_title: "",
    helpline_no: "",
    helpline_text: "",
    footer_logo: "",
    page_description: "",
    page_title: "",
    google_form_link: "",
  },
  testimonial: {
    id: undefined,
    name: "",
    description: "",
    image: "",
    designation: "",
  },
  plan: {
    id: undefined,
    title: "",
    price: "",
    discription1: "",
    discription2: "",
    discription3: "",
    discription4: "",
    discription5: "",
    discription6: "",
    discription7: "",
    plan_type: "",
  },
  suppoter: {
    id: undefined,
    image: null,
    status: "",
  },
  achivement: {
    id: undefined,
    title: "",
    description: "",
    sub_title: "",
    achviment_number: "",
  },
  services: [],
};
const HomeSlice = createSlice({
  name: "home",
  initialState,
  extraReducers(builder) {
    // ========================================================================================
    // get all data
    builder
      .addCase(getAllData.pending, state => {
        state.loading = true;
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload.services;
        state.plans = action.payload.plan;
        state.subCategorys = action.payload.subCategory;
        state.achivements = action.payload.achivement;
        state.suppoters = action.payload.suppoter;
        state.settings = action.payload.setting[0];
        state.states = action.payload.state;
        state.services = action.payload.service;
        state.categorys = action.payload.categorys;
        state.occupations = action.payload.occupation;

        // console.log("BANNNER IMAGE", state.settings.banner_image);
      })
      .addCase(getAllData.rejected, state => {
        state.loading = false;
        state.services = [];
        state.plans = [];
        state.subCategorys = [];
        state.achivements = [];
        state.suppoters = [];
        state.states = [];
        state.settings = [];
        state.services = [];
        state.categorys = [];
        state.occupations = [];
      });

    // GET ALL PLAN

    // builder
    //   .addCase(getAllPlan.pending, state => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllPlan.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.plans = action.payload;
    //   })
    //   .addCase(getAllPlan.rejected, state => {
    //     state.loading = false;
    //     state.plans = [];
    //     // state.error = action.payload
    //   });
    //GET ALL SUPPORTERS
    // builder
    //   .addCase(getAllSuppoters.pending, state => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllSuppoters.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.suppoters = payload;
    //   })
    //   .addCase(getAllSuppoters.rejected, state => {
    //     state.loading = false;
    //     state.suppoters = [];
    //   });
    //GET ALL SUB Categories
    // builder
    //   .addCase(getAllSubCategory.pending, state => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllSubCategory.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.subCategorys = payload;
    //     //state.legalServices.push(payload);
    //   })
    //   .addCase(getAllSubCategory.rejected, state => {
    //     state.loading = false;
    //     state.subCategorys = [];
    //   });
    // GET ALL SERVICES

    builder
      .addCase(getAllServices.pending, state => {
        state.loading = true;
      })
      .addCase(getAllServices.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload;
      })
      .addCase(getAllServices.rejected, (state, action) => {
        state.loading = false;
        state.services = [];
      });
    // =======================================================================================================
    // GET ALL SERVICES

    // builder
    //   .addCase(getAllServices.pending, state => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllServices.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.services = action.payload;
    //   })
    //   .addCase(getAllServices.rejected, (state, action) => {
    //     state.loading = false;
    //     state.services = [];
    //   });

    // GET ALL TESTIMONIAL

    builder
      .addCase(getAllTestimonial.pending, state => {
        state.loading = true;
      })
      .addCase(getAllTestimonial.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonials = action.payload;
      })
      .addCase(getAllTestimonial.rejected, (state, action) => {
        state.loading = false;
        state.testimonials = [];
        // state.error = action.payload
      });

    // // GET ALL PLAN

    // builder
    //   .addCase(getAllPlan.pending, state => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllPlan.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.plans = action.payload;
    //   })
    //   .addCase(getAllPlan.rejected, state => {
    //     state.loading = false;
    //     state.plans = [];
    //     // state.error = action.payload
    //   });
    // //GET ALL SUPPORTERS
    // builder
    //   .addCase(getAllSuppoters.pending, state => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllSuppoters.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.suppoters = payload;
    //   })
    //   .addCase(getAllSuppoters.rejected, state => {
    //     state.loading = false;
    //     state.suppoters = [];
    //   });
    //GET ALL ACHIVEMENTS
    // builder
    //   .addCase(getAllAchivement.pending, state => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllAchivement.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.achivements = payload;
    //   })
    //   .addCase(getAllAchivement.rejected, state => {
    //     state.loading = false;
    //     state.achivements = [];
    //   });

    //GET ALL LEGAL SERVICES
    builder
      .addCase(getAllLegalService.pending, state => {
        state.loading = true;
      })
      .addCase(getAllLegalService.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.legalServices = payload;
        //state.legalServices.push(payload);
      })
      .addCase(getAllLegalService.rejected, state => {
        state.loading = false;
        state.legalServices = [];
      });

    // //GET ALL SUB Categories
    // builder
    //   .addCase(getAllSubCategory.pending, state => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllSubCategory.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.subCategorys = payload;
    //     //state.legalServices.push(payload);
    //   })
    //   .addCase(getAllSubCategory.rejected, state => {
    //     state.loading = false;
    //     state.subCategorys = [];
    //   });
  },
});
export default HomeSlice.reducer;
