import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CommonService from "../services/CommonService";
import ServiceService from "../services/ServiceService";

export const getAllServices = createAsyncThunk(
  "service/getAllServices",
  async () => {
    try {
      const res = await ServiceService.getAllServiceService();
      return res.data.data.service_data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addFavServices = createAsyncThunk(
  "service/addFavServices",
  async data => {
    try {
      const res = await ServiceService.addFavServices(data);
      if (res.data.code === 200) {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserFavServices = createAsyncThunk(
  "service/getUserFavServices",
  async () => {
    try {
      const res = await ServiceService.getUserFavServices();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const removeFavService = createAsyncThunk(
  "service/removeFavService",
  async data => {
    try {
      const res = await ServiceService.removeFavService(data);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  services: [],
  service: {},
  favServices: [],
  favService: {},
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  extraReducers(builder) {
    //GET ALL SERVICES
    builder
      .addCase(getAllServices.pending, state => {
        state.loading = true;
      })
      .addCase(getAllServices.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.services = payload;
      })
      .addCase(getAllServices.rejected, (state, payload) => {
        state.loading = false;
        state.error = payload;
      });

    // FAV SERVICES

    builder
      .addCase(addFavServices.pending, state => {
        state.loading = true;
      })
      .addCase(addFavServices.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.favService = payload;
      })
      .addCase(addFavServices.rejected, (state, payload) => {
        state.loading = false;
        state.error = payload;
      });

    //GET USER FAV SERVICES

    builder
      .addCase(getUserFavServices.pending, state => {
        state.loading = true;
      })
      .addCase(getUserFavServices.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.favServices = payload;
      })
      .addCase(getUserFavServices.rejected, (state, payload) => {
        state.loading = false;
        state.error = payload;
      });

    //USER FAV SERVICE

    builder
      .addCase(removeFavService.pending, state => {
        state.loading = true;
      })
      .addCase(removeFavService.fulfilled, (state, { payload }) => {
        const favorite = false;
        state.loading = false;
        state.favService = { ...payload, favorite: !favorite };
      })
      .addCase(removeFavService.rejected, (state, payload) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default serviceSlice.reducer;
