import baseAPI from "../../api/userApi";
import { authHeader, authHeaderWithImage } from "../../helpers/authHeaders";

const serviceAlreadyBooked = async service_id => {
  try {
    return await baseAPI.post(
      "/already-booked-service",
      { service_id },
      {
        headers: authHeader(),
      }
    );
  } catch (error) {
    console.log(error);
  }
};

const getCartItems = async () => {
  try {
    return await baseAPI.get("/get-cart", {
      headers: authHeader(),
    });
  } catch (error) {
    console.log(error);
  }
};
const addCartItem = async item => {
  try {
    return await baseAPI.post("/update-cart", item, {
      headers: authHeaderWithImage(),
    });
  } catch (error) {
    console.log(error);
  }
};

const removeCartItem = async item => {
  try {
    return await baseAPI.post("/remove-cart", item, {
      headers: authHeader(),
    });
  } catch (error) {
    console.log(error);
  }
};

const MyCartService = {
  serviceAlreadyBooked,
  getCartItems,
  addCartItem,
  removeCartItem,
};
export default MyCartService;
