// import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Carousel from "../../../components/OwlCarousel/Carousel";
// import { getAllPlan } from "../../../features/slices/HomeSlice";
import { authHeader } from "../../../helpers/authHeaders";
import Swal from "sweetalert2";
import baseAPI from "../../../api/userApi";
import Loading from "../../../components/GIF/Loading";

const HomeServicePackage = ({ title, sub_title }) => {
  // const dispatch = useDispatch();

  const { plans } = useSelector(state => state.home);

  const formRef = useRef();

  const [encRequestRes, setEncRequestRes] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const access_code = process.env.REACT_APP_ACCESS_CODE;

  // useEffect(() => {
  //   dispatch(getAllPlan());
  // }, [dispatch]);

  const options = {
    loop: true,
    margin: 10,
    items: 3,
    dots: true,
    autoplay: true,
    autoWidth: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      2000: {
        items: 3,
      },
    },
  };
  const navigate = useNavigate();

  const email = localStorage.getItem("email");
  const token = localStorage.getItem("token");

  const showPlans = plans?.filter(p => p.deleted_by === null);

  async function displayPayment(planId) {
    if (!token || !email) {
      navigate("/login");
      return;
    }

    try {
      setIsLoading(true);
      const result = await baseAPI.post(
        "/plan-booking",
        {
          plan_id: planId,
          payment_status: "pending",
          status: "OnGoing",
        },
        { headers: authHeader() }
      );

      if (result.data.code === 404) {
        setIsLoading(false);
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "You Have Already Buy This Plan",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      const payload = {
        merchant_id: process.env.REACT_APP_MERCHENT_ID,
        order_id: result.data.data.order_id,
        currency: "INR",
        amount: result.data.data.total,
        redirect_url: `${process.env.REACT_APP_ENSUREKAR_SITE_URL}/thank-you-plan`,
        cancel_url: `${process.env.REACT_APP_ENSUREKAR_SITE_URL}/cancel-payment`,
        language: "EN",
        plan_id: result.data.data.planId,
        billing_name: "",
        billing_state: "",
        billing_country: "India",
        billing_tel: "",
        billing_email: email,
      };

      const response = await baseAPI.post("/plan-payments", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEncRequestRes(response.data.data);
      setTimeout(() => {
        formRef.current.submit();
      }, 3000);
      setIsLoading(false);
    } catch (error) {
      console.log("Error occurred:", error);
    }
  }

  return (
    <>
      <form
        ref={formRef}
        id="nonseamless"
        action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
        method="POST"
      >
        <div className="col-sm-12 col-lg-8">
          <input
            type="hidden"
            id="encRequestRes"
            name="encRequestRes"
            value={encRequestRes}
          />
          <input
            type="hidden"
            name="access_code"
            id="access_code"
            value={access_code}
          />
        </div>
      </form>
      <section className="service_package">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <div className="title">
                <h2>{title}</h2>
                <p>{sub_title}</p>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <Carousel options={options}>
              {showPlans?.map((item, index) => {
                const {
                  id,
                  title,
                  price,
                  description1,
                  description2,
                  description3,
                  description4,
                  description5,
                  description6,
                  description7,
                  plan_type,
                } = item;
                return (
                  <div className="package_box text-center" key={index}>
                    <div className="circle-shape-1"></div>
                    <div className="circle-shape-2"></div>

                    <p>{title}</p>
                    <div className="pricing">
                      <span>₹{price}</span>/{plan_type}
                    </div>
                    <hr />
                    <ul className="list-unstyled">
                      <li>{description1}</li>
                      <li>{description2}</li>
                      <li>{description3}</li>
                      <li>{description4}</li>
                      <li>{description5}</li>
                      <li>{description6}</li>
                      <li>{description7}</li>
                    </ul>

                    <Button
                      className="btn btn-primary"
                      onClick={() => displayPayment(id)}
                      disabled={isLoading}
                    >
                      Buy Now
                    </Button>

                    <div className="circle-shape-3"></div>
                    <div className="circle-shape-4"></div>
                  </div>
                );
              })}
            </Carousel>
          )}
        </div>
      </section>
    </>
  );
};

export default HomeServicePackage;
