import React, { useEffect } from "react";
import BlogTitle from "./Sections/BlogTitle";
import BlogList from "./Sections/BlogList";

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BlogTitle />
      <BlogList />
    </div>
  );
};

export default Blog;
