import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../features/slices/authSlice";
import * as Yup from "yup";

import Swal from "sweetalert2";
import ShowError from "../../errors/ShowError";
import { getUserProfileById } from "../../features/slices/userProfileSlice";
import { ConsoleSqlOutlined } from "@ant-design/icons";
const ResetPassword = ({ show, onHide }) => {
  const { userProfile } = useSelector((state) => state.userProfile);
  const { status, error } = useSelector((state) => state.auth);
  const mobile_number = userProfile.mobile_number;
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // old_password: "",
      new_password: "",
      confirm_password: "",
      mobile_number: userProfile && userProfile.mobile_number,
    },
    validationSchema: Yup.object({
      // old_password: Yup.string().required("Old Password is required"),
      new_password: Yup.string().required("New Password is required"),
      confirm_password: Yup.string().oneOf(
        [Yup.ref("new_password"), null],
        "Passwords must match"
      ),
    }),

    onSubmit: (values, { resetForm, setSubmitting }) => {
      dispatch(resetPassword(values));
      if (!mobile_number) {
        // alert("STATUS-else", status);
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Add Mobile Number for Reset Password",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        onHide();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Password Reset Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      resetForm({});
      setSubmitting(false);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Modal
      show={show}
      size="md-4"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <h2>Reset Password</h2>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* <div className="col-lg-12 my-2">
                  <Form.Group>
                    <Form.Control
                      type="password"
                      className="form-control"
                      value={formik.values.old_password}
                      name="old_password"
                      placeholder="Old Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.old_password &&
                      formik.errors.old_password && (
                        <ShowError>{formik.errors.old_password}</ShowError>
                      )}
                  </Form.Group>
                </div> */}
                <div className="col-lg-12 my-2">
                  <Form.Group>
                    <Form.Control
                      type="password"
                      className="form-control"
                      value={formik.values.new_password}
                      name="new_password"
                      placeholder="New Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.new_password &&
                      formik.errors.new_password && (
                        <ShowError>{formik.errors.new_password}</ShowError>
                      )}
                  </Form.Group>
                </div>
                <div className="col-lg-12 my-2">
                  <Form.Group>
                    <Form.Control
                      type="password"
                      className="form-control"
                      value={formik.values.confirm_password}
                      name="confirm_password"
                      placeholder="Confirm Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.confirm_password &&
                      formik.errors.confirm_password && (
                        <ShowError>{formik.errors.confirm_password}</ShowError>
                      )}
                  </Form.Group>
                  <ShowError>{error}</ShowError>
                </div>
              </div>

              <button className="btn btn-success" type="submit">
                reset password
              </button>
              {/* <p>
                    Already have an account? <Link to="/login">Login</Link>{" "}
                  </p> */}
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPassword;
