import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserProfileService from "../services/UserProfileService";

export const updateUserProfile = createAsyncThunk(
  "user-profile/updateUserProfile",
  async data => {
    try {
      const res = await UserProfileService.upadteUserProfile(data);
      if (res.data.code === 200) {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateUserAddress = createAsyncThunk(
  "user-profile/updateUserAddress",
  async data => {
    try {
      const res = await UserProfileService.upadteUserAddress(data);
      if (res.data.code === 200) {
        return data;
      }
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateKYC = createAsyncThunk(
  "user-profile/updateKYC",
  async data => {
    try {
      const res = await UserProfileService.upadteKYC(data);
      if (res.data.code === 200) {
        return data;
      }
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserProfileById = createAsyncThunk(
  "user-profile/getUserProfileById",
  async () => {
    try {
      const res = await UserProfileService.getUserProfileById();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  userProfile: {},
  userAddress: {},
  error: "",
  loading: false,
};

const userProfileSlice = createSlice({
  name: "user-profile",
  initialState,
  extraReducers(builder) {
    // UPDATE USER PROFILE
    builder
      .addCase(updateUserProfile.pending, state => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userProfile = { ...state.userProfile, payload };
      })
      .addCase(updateUserProfile.rejected, state => {
        state.loading = false;
        state.error = "Something Went Wrong";
      });

    //UPDATE USER PROFILE ADDRESS

    builder
      .addCase(updateUserAddress.pending, state => {
        state.loading = true;
      })
      .addCase(updateUserAddress.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userProfile = { ...state.userProfile, payload };
      })
      .addCase(updateUserAddress.rejected, state => {
        state.loading = false;
        state.error = "Something Went Wrong";
      });

    //UPDATE USER PROFILE ADDRESS

    builder
      .addCase(updateKYC.pending, state => {
        state.loading = true;
      })
      .addCase(updateKYC.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userProfile = { ...state.userProfile, payload };
      })
      .addCase(updateKYC.rejected, state => {
        state.loading = false;
        state.error = "Something Went Wrong";
      });

    //GET USER PROFILE BY ID
    builder
      .addCase(getUserProfileById.pending, state => {
        state.loading = true;
      })
      .addCase(getUserProfileById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userProfile = payload;
      })
      .addCase(getUserProfileById.rejected, state => {
        state.loading = false;
        state.error = "Something Went Wrong";
      });
  },
});
export default userProfileSlice.reducer;
