import React, { useEffect, useState } from "react";
import help from "../../../assets/images/help.png";
import Carousel from "../../../components/OwlCarousel/Carousel";
import { useSelector, useDispatch } from "react-redux";
import {
  addFavServices,
  getAllServices,
  removeFavService,
} from "../../../features/slices/serviceSlice";
import Servcies from "../../../components/Services/Servcies";
import ServiceModal from "../../../components/Modal/ServiceModal";
import UserDashboardLayout from "../UserDashboardLayout";
import { Button } from "react-bootstrap";
import StateModal from "../../../components/Modal/StateModal";

const AllService = () => {
  const options = {
    loop: true,
    margin: 10,
    callbacks: true,
    items: 2,
    autoplay: false,
    autoWidth: false,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,
      },
      2000: {
        items: 2,
      },
    },
  };
  const [svrs, setSvrs] = useState([]);
  const [getId, setGetId] = useState();
  const [state, setState] = useState("");
  const [modalState, setModalState] = useState("close");
  const [serviceModalShow, setServiceModalShow] = useState(false);

  const dispatch = useDispatch();
  const serviceModal = id => {
    setServiceModalShow(!serviceModalShow);
    setGetId(id);
  };

  const { services } = useSelector(state => state.services);

  const filterService = services.find(s => s.id === getId);

  const topServices = services.filter(s => s.top_service === true);

  const findTopService = topServices.find(s => s.id === getId);

  const handleShowServiceModal = (id, state) => {
    setModalState("service-modal");
    setState(state);
  };

  const handleShowStateModal = id => {
    setModalState("state-modal");
    setGetId(id);
  };

  const handleClose = () => {
    setModalState("close");
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   setSvrs(filterService);
  // }, [JSON.stringify(filterService)]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSvrs(topServices);
  }, [JSON.stringify(topServices)]);

  function handleFavorite(id, favorite) {
    let newServices;
    if (favorite === 1) {
      newServices =
        svrs &&
        svrs.map(item => {
          return item.id === id ? { ...item, is_fav: 0 } : item;
        });
      dispatch(removeFavService(id));
      setSvrs(newServices);
    } else {
      newServices =
        svrs &&
        svrs.map(item => {
          return item.id === id ? { ...item, is_fav: 1 } : item;
        });
      dispatch(addFavServices(id));
      setSvrs(newServices);
    }
  }

  return (
    <React.Fragment>
      {modalState === "state-modal" && (
        <StateModal
          showServiceModal={handleShowServiceModal}
          show={modalState === "state-modal"}
          onHide={() => handleClose()}
          id={getId}
        />
      )}

      {findTopService
        ? findTopService && (
            <ServiceModal
              show={modalState === "service-modal"}
              onHide={() => handleClose()}
              filterService={findTopService}
              state={state}
            />
          )
        : filterService && (
            <ServiceModal
              show={modalState === "service-modal"}
              onHide={() => handleClose()}
              filterService={filterService}
              state={state}
            />
          )}
      <UserDashboardLayout>
        <h4>All Service</h4>
        <hr />
        <div className="row">
          <div className="col-lg-12">
            <div className="services">
              <h5>Top Services</h5>
              <div className="row">
                <div className="col-lg-12">
                  {/* <div id="service" className="owl-carousel owl-theme"> */}
                  <Carousel options={options}>
                    {svrs.map(s => {
                      //const { id, price, title, description } = s;
                      return (
                        <div className="item" key={s.id}>
                          <div className="dashboard_service_box">
                            <h5>{s.title}</h5>
                            <h3 className="price">₹{s.price}/-</h3>
                            <p>(Exclude)</p>
                            <div
                              className="fav_icon"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i
                                className={
                                  !s.is_fav ? "fa fa-heart-o" : "fa fa-heart"
                                }
                                id={s.id}
                                onClick={() => handleFavorite(s.id, s.is_fav)}
                              ></i>
                            </div>
                            <p className="desc">{s.description}</p>
                            <Button
                              onClick={() => handleShowStateModal(s.id)}
                              className="btn btn-success"
                            >
                              buy now
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            </div>

            <Servcies serviceModal={handleShowStateModal} />

            <div className="help_dashboard">
              <div className="row">
                <div className="col-lg-9">
                  <h3>Need Help?</h3>
                  <p>
                    Got questions regarding our services? Contact us, we are
                    happy to help you. You can also write to us at
                    support@insurkar.com
                  </p>
                </div>
                <div className="col-lg-3">
                  <img src={help} className="img-fluid" alt="help" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </UserDashboardLayout>
    </React.Fragment>
  );
};

export default AllService;
