import React from "react";
import videoshape2 from "../../../assets/images/h2-video-shape2.svg";
import videoshape1 from "../../../assets/images/h2-video-shape11.svg";
// import dashboard from "../../../assets/images/charm_layout-dashboard.png";
// import multiple from "../../../assets/images/carbon_user-multiple.png";
// import services from "../../../assets/images/carbon_settings-services.png";
// import agent from "../../../assets/images/ic_sharp-support-agent.png";
import { Link } from "react-router-dom";

const HomeReasonSection = props => {
  return (
    <section className="resaon_sec">
      <div className="shape3">
        <img src={videoshape2} className="img-fluid" alt="video" />
      </div>

      <div className="shape4">
        <img src={videoshape1} className="img-fluid" alt="video" />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-6 col-md-12 col-lg-6">
            <div className="info">
              <h2>{props.ReasonTitle}</h2>
              <p>{props.ReasonSubTitle}</p>
              <Link to="/aboutus" className="btn btn-primary">
                Read More
              </Link>
            </div>
          </div>

          <div className="col-sm-6 col-md-12 col-lg-6">
            <div className="row">
              <div className="col-sm-6 left_col">
                <div className="reason_box">
                  <div className="media">
                    <div className="img_box">
                      {/* <img src={dashboard} className="mr-3" alt="reason" /> */}
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL + `${props.image1}`
                        }
                        className="mr-3"
                        alt="res-img"
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0">{props.SubTitle1}</h5>
                    </div>
                  </div>
                  <p>{props.Description1}</p>
                </div>

                <div className="reason_box">
                  <div className="media">
                    <div className="img_box">
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL + `${props.image2}`
                        }
                        className="mr-3"
                        alt="res-img"
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0">{props.SubTitle2}</h5>
                    </div>
                  </div>
                  <p>{props.Description2}</p>
                </div>
              </div>
              <div className="col-sm-6 right_col">
                <div className="reason_box">
                  <div className="media">
                    <div className="img_box">
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL + `${props.image3}`
                        }
                        className="mr-3"
                        alt="res-img"
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0">{props.SubTitle3}</h5>
                    </div>
                  </div>
                  <p>{props.Description3}</p>
                </div>

                <div className="reason_box">
                  <div className="media">
                    <div className="img_box">
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL + `${props.image4}`
                        }
                        className="mr-3"
                        alt="res-img"
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0">{props.SubTitle4}</h5>
                    </div>
                  </div>
                  <p>{props.Description4}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeReasonSection;
