import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ResetPassword from "../../../components/Modal/ResetPassword";
import { getUserProfileById } from "../../../features/slices/userProfileSlice";
import UserDashboardLayout from "../UserDashboardLayout";
import AddressForm from "./AddressForm";
import KYCForm from "./KYCForm";
import PersonalDetailsForm from "./PersonalDetailsForm";
import DownloadDocuments from "./DownloadDocuments";
import { getAllPaymentByUser } from "../../../features/slices/paymentSlice";

const Profile = () => {
  const [userData, setUserData] = useState({});
  const [show, setShow] = useState(false);

  const { userServiceList } = useSelector(state => state.payments);

  const dispatch = useDispatch();

  const { userProfile } = useSelector(state => state.userProfile);

  const showModal = () => {
    setShow(!show);
  };

  const hideModal = () => {
    setShow(false);
  };
  useEffect(() => {
    dispatch(getAllPaymentByUser());
    dispatch(getUserProfileById());

    setUserData(userProfile);
  }, [dispatch, JSON.stringify(userProfile)]);

  const email = localStorage.getItem("email");

  return (
    <React.Fragment>
      <ResetPassword show={show} onHide={hideModal} />
      <UserDashboardLayout>
        <h4>My Profile</h4>
        <hr />
        <div className="row">
          <div className="col-lg-12">
            <div className="all_service">
              <div className="service_tab">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-personal-tab"
                      data-toggle="pill"
                      href="#pills-personal"
                      role="tab"
                      aria-controls="pills-personal"
                      aria-selected="true"
                    >
                      Personal Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-address-tab"
                      data-toggle="pill"
                      href="#pills-address"
                      role="tab"
                      aria-controls="pills-address"
                      aria-selected="false"
                    >
                      Address
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-kyc-tab"
                      data-toggle="pill"
                      href="#pills-kyc"
                      role="tab"
                      aria-controls="pills-kyc"
                      aria-selected="false"
                    >
                      KYC
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-download-tab"
                      data-toggle="pill"
                      href="#pills-download"
                      role="tab"
                      aria-controls="pills-download"
                      aria-selected="false"
                    >
                      Download Documents
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-personal"
                    role="tabpanel"
                    aria-labelledby="pills-personal-tab"
                  >
                    <div className="personal_info">
                      <PersonalDetailsForm userData={userData} />
                    </div>

                    <div className="personal_info">
                      <div className="row">
                        <div className="col-md-4 col-lg-4">
                          <p className="font-weight-bold">Email Address</p>
                        </div>

                        <div className="col-md-4 col-lg-4">
                          <div className="email_address">
                            {email ? (
                              <>
                                <p className="font-weight-bold mb-0">{email}</p>
                                <span className="invalid text">Verified</span>
                              </>
                            ) : (
                              <>
                                {" "}
                                <p className="font-weight-bold mb-0">
                                  NOT ENTERED
                                </p>
                                <span className="invalid text-danger">
                                  Not Verified
                                </span>
                              </>
                            )}
                          </div>
                        </div>

                        {/* <div className="col-md-4 col-lg-4">
                          {email ? (
                            <div className="varify_btn">
                              <a href="#">Verified</a>
                            </div>
                          ) : (
                            <div className="varify_btn">
                              <a href="#">Verify Now</a>
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>

                    <div className="personal_info">
                      <div className="row">
                        <div className="col-md-4 col-lg-4">
                          <p className="font-weight-bold">Mobile Number</p>
                        </div>

                        <div className="col-md-4 col-lg-4">
                          <div className="mobile">
                            {userProfile.mobile_number ? (
                              <p className="font-weight-bold mb-0">
                                +91-{userProfile.mobile_number}
                              </p>
                            ) : (
                              <p>Not Verified</p>
                            )}

                            {userProfile.mobile_number ? (
                              <span className="invalid text">Verified</span>
                            ) : (
                              <span className="invalid text-danger">
                                Not Verified
                              </span>
                            )}
                          </div>
                        </div>

                        {/* <div className="col-md-4 col-lg-4">
                          {userProfile.mobile_number ? (
                            <div className="varify_btn">
                              <a href="#">Verified </a>
                            </div>
                          ) : (
                            <div className="varify_btn">
                              <a href="#">Verify Now</a>
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>

                    <div className="personal_info">
                      <div className="row">
                        <div className="col-md-6 col-lg-6">
                          <p className="font-weight-bold">Password</p>
                        </div>

                        <div className="col-md-6 col-lg-6">
                          <div className="varify_btn">
                            <Button
                              // to="/reset-password"
                              onClick={showModal}
                              className="btn btn-success"
                            >
                              reset password
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-address"
                    role="tabpanel"
                    aria-labelledby="pills-address-tab"
                  >
                    <div className="personal_info">
                      <div className="row">
                        <div className="col-lg-3">
                          <p className="font-weight-bold">My Address</p>
                        </div>

                        <div className="col-lg-9">
                          <AddressForm userData={userData} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-kyc"
                    role="tabpanel"
                    aria-labelledby="pills-kyc-tab"
                  >
                    <div className="personal_info">
                      <div className="row">
                        <div className="col-lg-3">
                          <p className="font-weight-bold">KYC</p>
                        </div>

                        <div className="col-lg-9">
                          <KYCForm userData={userData} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-download"
                    role="tabpanel"
                    aria-labelledby="pills-download-tab"
                  >
                    <div className="personal_info">
                      <div className="row">
                        <div className="col-lg-3">
                          <p className="font-weight-bold">
                            DOWNLOAD DOCUMENTS FOR SERVICES{" "}
                          </p>
                        </div>

                        <div className="col-lg-9">
                          <DownloadDocuments
                            userServiceList={userServiceList}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UserDashboardLayout>
    </React.Fragment>
  );
};

export default Profile;
