import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import NavigationBar from "../components/Navbar/NavigationBar";

const Layout = ({ children }) => {
  // const { error, token, errorCode } = useSelector(state => state.auth);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (token) {
  //     navigate("/dashboard");
  //     window.location.reload();
  //   } else {
  //     return navigate("/login");
  //   }
  // }, [token]);

  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER_ENSUREKAR;

  const hrefLink = `https://api.whatsapp.com/send?phone=${phoneNumber}`;

  return (
    <React.Fragment>
      <NavigationBar />
      <main>{children}</main>
      <a
        className="btn btn-success"
        href={hrefLink}
        target="_blank"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "99999",
          borderRadius: "50%",
          padding: "16px",
        }}
      >
        <i className="fa-2x fa fa-whatsapp "></i>
      </a>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
