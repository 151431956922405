import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CommonService from "../services/CommonService";
import PartnerService from "../services/PartnerUsService";

export const createInquiryForPartner = createAsyncThunk(
  "partner/createInquiryForPartner",
  async data => {
    try {
      const res = await PartnerService.createInquiryForPartner(data);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// export const getAllOccupations = createAsyncThunk(
//   "partner/getAllOccupations",
//   async data => {
//     try {
//       const res = await CommonService.getCommonDataService();
//       return res.data.data.occupation;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );
const initialState = {
  partners: [],
  loading: false,
  error: "",
  partner: {},
  occupations: [],
};

const partnerUsSlice = createSlice({
  name: "partner",
  initialState,
  extraReducers(builder) {
    // CREATE PARTNER INQUIRY
    builder
      .addCase(createInquiryForPartner.pending, state => {
        state.loading = true;
      })
      .addCase(createInquiryForPartner.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.partner = [...state.partners, payload];
      })
      .addCase(createInquiryForPartner.rejected, state => {
        state.loading = false;
      });

    // GET ALL OCCUPATIONS
    // builder
    //   .addCase(getAllOccupations.pending, state => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllOccupations.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.occupations = payload;
    //   })
    //   .addCase(getAllOccupations.rejected, state => {
    //     state.loading = false;
    //   });
  },
});

export default partnerUsSlice.reducer;
