import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const becomePartnerSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Enter a valid email").required("Please enter your email").max(35),
  mobile_number: Yup.string()
    .required("Mobile number is Required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Add 10 digit mobile number")
    .max(10, "Add 10 digit mobile number"),
  // massage: Yup.string().required("Enter Message"),
  // state: Yup.string().required('State is Required'),
  // city: Yup.string().required('City is Required'),
  // occupation_id: Yup.string().required('Enter your occupation'),
  termsAndConditions: Yup.bool().oneOf(
    [true],
    "You need to accept the terms and conditions"
  ),
});
