import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MyCartService from "../services/MyCartService";

// const cartItems =
//   localStorage.getItem("cart") !== null
//     ? JSON.parse(localStorage.getItem("cart"))
//     : [];

// const total =
//   localStorage.getItem("totalAmount") !== null
//     ? JSON.parse(localStorage.getItem("totalAmount"))
//     : 0;

export const allreadyBooked = createAsyncThunk(
  "myCart/allreadyBooked",
  async data => {
    try {
      const res = await MyCartService.serviceAlreadyBooked(data);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCartItems = createAsyncThunk(
  "myCart/getCartItems",
  async () => {
    try {
      const res = await MyCartService.getCartItems();
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addCartItem = createAsyncThunk(
  "myCart/addCartItem",
  async data => {
    try {
      const res = await MyCartService.addCartItem(data);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const removeCartItem = createAsyncThunk(
  "myCart/removeCartItem",
  async data => {
    try {
      const res = await MyCartService.removeCartItem(data);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  cart: [],
  cartTotal: 0,
  message: 1,
  cartTotalItems: 0,
};

const myCartSlice = createSlice({
  name: "myCart",
  initialState,
  reducers: {
    // addToCart(state, { payload }) {
    //   state.cart.push(payload);
    //   let ttl = 0;
    //   for (var i = 0; i < state.cart.length; i++) {
    //     ttl = ttl + state.cart[i].serviceObj.price;
    //   }
    //   state.cartTotal = ttl;
    //   localStorage.setItem(
    //     "cart",
    //     JSON.stringify(state.cart.map(item => item))
    //   );
    //   localStorage.setItem("totalAmount", JSON.stringify(state.cartTotal));
    // },
    // removeItem(state, { payload }) {
    //   const items = state.cart.filter(
    //     item => item.serviceObj.id !== payload.serviceObj.id
    //   );
    //   let ttl = 0;
    //   state.cart = items;
    //   for (var i = 0; i < state.cart.length; i++) {
    //     ttl = ttl + state.cart[i].serviceObj.price;
    //   }
    //   state.cartTotal = ttl;
    //   localStorage.setItem(
    //     "cart",
    //     JSON.stringify(state.cart.map(item => item))
    //   );
    //   localStorage.setItem("totalAmount", JSON.stringify(state.cartTotal));
    // },
    // removeAllCartItems() {
    //   localStorage.removeItem("cart");
    //   localStorage.removeItem("totalAmount");
    // },
    // makeMessageToZero(state) {
    //   state.message = 1;
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(allreadyBooked.pending, state => {
        state.loading = true;
      })
      .addCase(allreadyBooked.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      })
      .addCase(allreadyBooked.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(getCartItems.pending, state => {
        state.loading = true;
      })
      .addCase(getCartItems.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cart = payload;

        let ttl = 0;

        for (var i = 0; i < state?.cart?.length; i++) {
          ttl = ttl + Number(state.cart[i]?.serviceObj?.price);
        }

        state.cartTotal = ttl;
      })
      .addCase(getCartItems.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(addCartItem.pending, state => {
        state.loading = true;
      })
      .addCase(addCartItem.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.cart.push(payload);

        let ttl = 0;

        for (var i = 0; i < state?.cart?.length; i++) {
          ttl = ttl + Number(state.cart[i].serviceObj?.price);
        }

        state.cartTotal = ttl;
      })
      .addCase(addCartItem.rejected, state => {
        state.loading = false;
      });

    builder
      .addCase(removeCartItem.pending, state => {
        state.loading = true;
      })
      .addCase(removeCartItem.fulfilled, (state, { payload }) => {
        state.loading = false;

        const items = state.cart.filter(
          item => item.serviceObj.id !== payload?.serviceObj?.id
        );

        state.cart = items;

        let ttl = 0;

        for (var i = 0; i < state?.cart?.length; i++) {
          ttl = ttl + Number(state.cart[i]?.serviceObj?.price);
        }

        state.cartTotal = ttl;
      })
      .addCase(removeCartItem.rejected, state => {
        state.loading = false;
      });
  },
});

export const { addToCart, removeItem, removeAllCartItems, makeMessageToZero } =
  myCartSlice.actions;
export default myCartSlice.reducer;
