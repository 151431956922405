import React, { useEffect } from "react";
import Carousel from "../../../components/OwlCarousel/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { getAllTestimonial } from "../../../features/slices/HomeSlice";

const HomeTestimonialSection = () => {
  const { testimonials } = useSelector(state => state.home);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllTestimonial());
  }, [dispatch]);

  const options = {
    nav: true,
    navText: [
      "<div class='nav-button owl-prev'>‹</div>",
      "<div class='nav-button owl-next'>›</div>",
    ],
    loop: true,
    margin: 10,
    items: 1,
    dots: false,
    autoplay: true,
    autoWidth: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
      2000: {
        items: 1,
      },
    },
  };
  return (
    <section className="testimonial_sec">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="title">
              <h2>10,000+ Happy Customers</h2>
            </div>
          </div>
        </div>
        <Carousel options={options}>
          {testimonials.map((item, index) => {
            const { name, description, designation, image } = item;
            return (
              <div className="testimonial_item" key={index}>
                <div className="justify-content-center">
                  <p>{description}</p>
                  <div className="info">
                    <div className="profile_icon">
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + `${image}`}
                        className="img-full-responsive s-rounded"
                        alt="testimonial"
                      />
                    </div>
                    <h3>{name}</h3>
                    <p>{designation}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default HomeTestimonialSection;
