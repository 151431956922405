import { Helmet } from "react-helmet";

const HelmetBase = ({ title, description, link }) => {
  return (
    <Helmet>
      <title>{title ? `Ensurekar | ${title}` : "Ensurekar"}</title>

      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={"https://ensurekar.com/" + link} />
      <meta name="google-site-verification" content="" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content=""></meta>
      <meta
        name="robots"
        content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
      />
      <meta
        name="keywords"
        content="Online Legal Services, Startups, SMEs, India, Ensurekar, Trusted Partner, Legal Support, Streamlined Legal Solutions, Business Journey, company registration process, business registration , mca company search , mca company , inc registration"
      ></meta>

      <link rel="canonical" href={"https://ensurekar.com/" + link} />
    </Helmet>
  );
};

export default HelmetBase;
