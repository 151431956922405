import React, { useEffect } from "react";
import logo from "../../../assets/images/logo.png";
import acount from "../../../assets/images/acount.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Form, FormControl } from "react-bootstrap";
import {
  authEmail,
  authRegister,
  resetError,
} from "../../../features/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import axios from "axios";
import ShowError from "../../../errors/ShowError";

const Register = () => {
  const navigate = useNavigate();

  const { error } = useSelector(state => state.auth);
  const { settings } = useSelector(state => state.home);

  const dispatch = useDispatch();

  const { header_logo } = settings;

  const registerUser = values => {
    const eml = values.email;
    dispatch(authEmail(values));
    navigate(`/registerpassword`, {
      state: { email: eml },
    });
    // if (error === null) {

    // } else {
    //   navigate("/register");
    // }
  };

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch, error]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email is not Proper")
        .required("Email is required")
        .max(40),
    }),
    onSubmit: values => {
      dispatch(resetError());
      registerUser(values);
    },
  });
  return (
    <section className="login_sec forgot_password">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 text-center ">
            <div className="logo">
              <img
                src={process.env.REACT_APP_IMAGE_URL + header_logo}
                className="img-fluid"
                alt="logo"
              />
            </div>

            <div className="login_info">
              <div className="col-lg-8 mx-auto">
                <h1>Create an account</h1>
                <p>
                  Already have an account? <Link to="/login">Login</Link>{" "}
                </p>

                <Form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <FormControl
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <ShowError>{formik.errors.email}</ShowError>
                        )}
                      </div>
                    </div>

                    {/* <ShowError>{error}</ShowError> */}
                  </div>

                  <p>
                    By signing up you agree to Privacy Policy and Terms of
                    Service
                  </p>
                  <button className="btn btn-success" type="submit">
                    set password
                  </button>
                </Form>
              </div>
            </div>
          </div>

          <div className="col-lg-6 text-right px-0">
            <div className="login_img">
              <img src={acount} className="img-fluid" alt="login" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
