import React from "react";
// import { useEffect } from "react";
import { useSelector } from "react-redux";
// import { getAllAchivement } from "../../../features/slices/HomeSlice";

const HomeAchivement = props => {
  const { achivements } = useSelector(state => state.home);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getAllAchivement());
  // }, [dispatch]);

  return (
    <section className="achievements_sec">
      <div className="container">
        <div className="row">
          <div className="col-sm-7 ">
            <div className="row justify-content-center">
              {achivements.map((item, index) => {
                const { sub_title, achviment_number } = item;
                if (sub_title && achviment_number) {
                  return (
                    <div className="col-sm-4 col-md-6 col-lg-4" key={index}>
                      <div className="count_box">
                        <h2>{achviment_number}</h2>
                        <p>{sub_title}</p>
                      </div>
                    </div>
                  );
                } else {
                  return;
                }
              })}
            </div>
          </div>
          <div className="col-sm-5">
            <div className="title">
              <h2 className="mb-0">{props.title}</h2>
              <h4></h4>
            </div>
            <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeAchivement;
