import baseAPI from "../../api/userApi";
import { authHeader } from "../../helpers/authHeaders";

const getAllEvent = async () => {
  try {
    return await baseAPI.get("/event-list");
  } catch (error) {
    console.log(error);
  }
};

const getEventById = async id => {
  try {
    return await baseAPI.post(`/eventById/${id}`, {});
  } catch (error) {
    console.log(error);
  }
};

const userEventInterested = async id => {
  try {
    return await baseAPI.post(
      `/intrest-event`,
      { master_event_id: id },
      { headers: authHeader() }
    );
  } catch (error) {
    console.log(error);
  }
};
const EventService = {
  getAllEvent,
  getEventById,
  userEventInterested,
};
export default EventService;
