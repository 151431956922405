import React from "react";
import handshake from "../../../assets/images/handshake(1).png";
import solutions from "../../../assets/images/solutions.png";
import customer from "../../../assets/images/customer-support(1).png";
import { toLocalImageUrl } from "../../../helpers/AssetsHelpers";
const PartnerExperience = ({
  ourPartner_title,
  image1,
  image1_subtitle,
  image2,
  image2_subtitle,
  image3,
  image3_subtitle,
}) => {
  return (
    <>
      <section className="section_content partner_experience">
        <div className="container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="title">
                  <h2>{ourPartner_title}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4 text-center">
                <div className="experience_box">
                  <div className="img">
                    <img
                      src={toLocalImageUrl(image1)}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>{image1_subtitle}</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center">
                <div className="experience_box">
                  <div className="img">
                    <img
                      src={toLocalImageUrl(image2)}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>{image2_subtitle}</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center">
                <div className="experience_box">
                  <div className="img">
                    <img
                      src={toLocalImageUrl(image3)}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>{image3_subtitle}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartnerExperience;
