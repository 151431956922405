import React from "react";
import { Modal } from "react-bootstrap";
import loadScript from "../../helpers/razorpay/loadscript";
import axios from "axios";
import { authHeader } from "../../helpers/authHeaders";
import { useNavigate } from "react-router-dom";
import { getAllServices } from "../../features/slices/serviceSlice";
import { useSelector } from "react-redux";
const ServiceDisplayModal = ({ show, onHide, filterService }) => {
  const {
    id,
    benefits,
    deliverables,
    description,
    documents,
    duration,
    penalty,
    price,
    title,
  } = filterService;
  //   const navigate = useNavigate();

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        // size="sm"
        centered
        className="modal_buy2"
      >
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 col-lg-8">
              <h5 className="modal-title" id="buynowTitle">
                {title}
              </h5>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div className="price">
                <h3>₹{price}/-</h3>
                <p> only (Exclusive)</p>
              </div>
            </div>
          </div>
          <div className="service_tab">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="overview-tab"
                  data-toggle="pill"
                  href="#overview"
                  role="tab"
                  aria-controls="overview"
                  aria-selected="true"
                >
                  Overview
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="benefits-tab"
                  data-toggle="pill"
                  href="#benefits"
                  role="tab"
                  aria-controls="benefits"
                  aria-selected="false"
                >
                  Benefits
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="documents-tab"
                  data-toggle="pill"
                  href="#documents"
                  role="tab"
                  aria-controls="documents"
                  aria-selected="false"
                >
                  Documents
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="deliverables-tab"
                  data-toggle="pill"
                  href="#deliverables"
                  role="tab"
                  aria-controls="deliverables"
                  aria-selected="false"
                >
                  Deliverables
                </a>
              </li>
            </ul>
            <div
              className="tab-content service_tabcontent"
              id="pills-tabContent"
            >
              <div
                className="tab-pane fade active show"
                id="overview"
                role="tabpanel"
                aria-labelledby="overview-tab"
              >
                <p className="font-weight-bold">Description</p>
                <p>{description}</p>

                {/* <p>
                  Private limited company is the best option for entrepreneurs
                  who have big dreams for their business. We can help you
                  register your Pvt ltd company without hassle! With us, the
                  entire process is online and you get guidance throughout the
                  process. All you need to do is provide a few details and
                  upload some documents, we will take care of the rest. We even
                  get you PAN and TAN. Make sure you have a minimum of 2
                  directors to get started.{" "}
                </p> */}

                <p className="font-weight-bold">Duration</p>
                <div dangerouslySetInnerHTML={{ __html: duration }}></div>

                <p className="font-weight-bold">Penalty</p>
                <p>{penalty}</p>
              </div>
              <div
                className="tab-pane fade"
                id="benefits"
                role="tabpanel"
                aria-labelledby="benefits-tab"
              >
                <p className="font-weight-bold">Benefits</p>
                <div dangerouslySetInnerHTML={{ __html: benefits }} />
              </div>
              <div
                className="tab-pane fade"
                id="documents"
                role="tabpanel"
                aria-labelledby="documents-tab"
              >
                <p className="font-weight-bold">Documents</p>
                <div dangerouslySetInnerHTML={{ __html: documents }} />
              </div>
              <div
                className="tab-pane fade"
                id="deliverables"
                role="tabpanel"
                aria-labelledby="deliverables-tab"
              >
                <p className="font-weight-bold">Deliverables</p>
                <div dangerouslySetInnerHTML={{ __html: deliverables }} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              displayRazor(id, state);
            }}
          >
            Buy Now
          </button> */}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ServiceDisplayModal;
