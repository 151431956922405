import "./App.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/theme.css";
import "./assets/css/layout.css";
// import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "./layouts/Layout";
import React, { useEffect } from "react";
import AppRoutes from "./routing/AppRoutes";
import { useDispatch } from "react-redux";
import { getAllSetting } from "./features/slices/settingSlice";
import { getAllMenuService } from "./features/slices/menuServiceSlice";
import { getAllService } from "./features/slices/contactusSlice";
import { getAllData } from "./features/slices/HomeSlice";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllData());
    // dispatch(getAllSetting());
    dispatch(getAllMenuService());
    // dispatch(getAllService());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Layout>
        <AppRoutes />
      </Layout>
    </React.Fragment>
  );
}

export default App;
