import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CategoryService from "../services/CategoryService";
import CommonService from "../services/CommonService";

export const getCategoryById = createAsyncThunk(
  "category/getCategoryById",
  async id => {
    try {
      const res = await CategoryService.getCategoryById(id);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// export const getAllCategories = createAsyncThunk(
//   "category/getAllCategories",
//   async () => {
//     try {
//       const res = await CommonService.getCommonDataService();
//       return res.data.data.category;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

export const getFaqByCategoryId = createAsyncThunk(
  "category/getFaqByCategoryId",
  async id => {
    try {
      const res = await CategoryService.getFaqByCategoryId(id);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  categorys: [],

  category: {},
  faqs: [],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getCategoryById.pending, state => {
        state.loading = true;
      })
      .addCase(getCategoryById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.category = payload;
      })
      .addCase(getCategoryById.rejected, state => {
        state.loading = false;
      });

    // builder
    //   .addCase(getAllCategories.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllCategories.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.categorys = payload;
    //   })
    //   .addCase(getAllCategories.rejected, (state) => {
    //     state.loading = false;
    //   });

    builder
      .addCase(getFaqByCategoryId.pending, state => {
        state.loading = true;
      })
      .addCase(getFaqByCategoryId.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.faqs = payload;
      })
      .addCase(getFaqByCategoryId.rejected, state => {
        state.loading = false;
      });
  },
});

export default categorySlice.reducer;
