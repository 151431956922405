import React from "react";
// import help from "../../../assets/images/help.png";
import help from "../../assets/images/help.png";
const HelpDashboard = () => {
  return (
    <React.Fragment>
      <div className="help_dashboard">
        <div className="row">
          <div className="col-lg-9">
            <h3>Need Help?</h3>
            <p>
              Got questions regarding our services? Contact us, we are happy to
              help you. You can also write to us at support@insurkar.com
            </p>
          </div>
          <div className="col-lg-3">
            <img src={help} className="img-fluid" alt="help" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HelpDashboard;
