import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { removeFavService } from "../../../../features/slices/serviceSlice";
// import loadScript from "../../../helpers/razorpay/loadscript";
// import Swal from "sweetalert2";
const Fav = ({ favServices }) => {
  const [svrs, setSvrs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setSvrs(favServices);
  }, [JSON.stringify(favServices)]);

  function unlikeService(id) {
    let newServices;

    // newServices = svrs.map(item => {
    //   return item.id === id ? { ...item, is_fav: 0 } : item;
    // });
    newServices = svrs.filter(s => s.service_id !== id);
    dispatch(removeFavService(id));
    setSvrs(newServices);
  }

  return (
    <>
      {svrs &&
        svrs.map((fav, index) => {
          const { title, price, description, service_id } = fav;
          return (
            <div className="service_block" key={index}>
              <div className="row align-items-center">
                <div className="fav_icon"></div>
                <div className="col-lg-9">
                  <div className="service_block_left">
                    <p className="font-weight-bold">{title}</p>
                    <p>{description}</p>
                    <h4 className="price">
                      ₹{price}/- <span> only (Exclusive)</span>{" "}
                    </h4>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Button
                    className="btn btn-success"
                    onClick={() => {
                      unlikeService(service_id);
                    }}
                  >
                    Unlike
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default Fav;
