import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../features/slices/categorySlice";
import {
  addFavServices,
  getAllServices,
  removeFavService,
} from "../../features/slices/serviceSlice";

const Servcies = ({ serviceModal }) => {
  const [heart, setHeart] = useState(false);

  const { categorys } = useSelector(state => state.menuService);
  const { services, favService } = useSelector(state => state.services);
  const [currentTab, setCurrentTab] = useState("1");
  const [svrs, setSvrs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getAllCategories());f
    dispatch(getAllServices());
    setSvrs(services);
  }, [dispatch, JSON.stringify(services)]);

  const handleTabClick = e => {
    setCurrentTab(e.target.id);
  };

  function handleFavorite(id, favorite) {
    let newServices;
    if (favorite === 1) {
      newServices = svrs.map(item => {
        return item.id === id ? { ...item, is_fav: 0 } : item;
      });
      dispatch(removeFavService(id));
      setSvrs(newServices);
    } else {
      newServices = svrs.map(item => {
        return item.id === id ? { ...item, is_fav: 1 } : item;
      });
      dispatch(addFavServices(id));
      setSvrs(newServices);
    }
  }

  return (
    <div className="all_service">
      <h5>All Services</h5>
      <div className="service_tab">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          {categorys
            .filter(c => c.status == 1)
            .map(category => {
              const { id, title } = category;
              return (
                <li className="nav-item" key={id}>
                  <a
                    className="nav-link"
                    id={id}
                    data-toggle="pill"
                    href={id}
                    role="tab"
                    aria-controls="pills-allhome"
                    aria-selected="true"
                    disabled={currentTab !== `${id}`}
                    onClick={handleTabClick}
                  >
                    {title}
                  </a>
                </li>
              );
            })}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {categorys
            .filter(c => c.status == 1)
            .map(category => {
              const { id, title } = category;
              return (
                <React.Fragment key={id}>
                  {currentTab === `${id}` && (
                    <div
                      className="tab-pane fade show active"
                      id={id}
                      role="tabpanel"
                      aria-labelledby="pills-home1-tab"
                    >
                      {svrs
                        .filter(s => s.category_id === id)
                        .map((d, index) => {
                          return (
                            <div className="service_block" key={d.id}>
                              <div className="fav_icon">
                                <i
                                  className={
                                    !d.is_fav ? "fa fa-heart-o" : "fa fa-heart"
                                  }
                                  onClick={() => handleFavorite(d.id, d.is_fav)}
                                  id={d.id}
                                ></i>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-lg-9">
                                  <div className="service_block_left">
                                    <p className="font-weight-bold">
                                      {d.title}
                                    </p>
                                    <p>{d.description}</p>
                                    <h4 className="price">
                                      ₹ {d.price} /-{" "}
                                      <span> only (Exclude)</span>{" "}
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <Button
                                    className="btn btn-success"
                                    onClick={() => serviceModal(d.id)}
                                  >
                                    Buy now
                                  </Button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Servcies;
