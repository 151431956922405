import baseAPI from "../../api/userApi"
import { authHeader } from "../../helpers/authHeaders"
const addContact = async (formData) => {
    try {
        return await baseAPI.post('/Create-ContactUs', formData)
    } catch (error) {
        console.log(error)
    }
}

const fetchContactUs = async (id) => {
    try {
        return await baseAPI.get(`/contactPageById/${id}`, { headers: authHeader() })
    } catch (error) {
        console.log(error)
    }
}
const ContactusService = {
    addContact,
    fetchContactUs
}
export default ContactusService