import ContactusService from "../services/ContactusService";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CommonService from "../services/CommonService";

export const addContact = createAsyncThunk("contact/addContact", async data => {
  try {
    const res = await ContactusService.addContact(data);
    return res.data.data;
  } catch (error) {
    console.log(error);
  }
});
// export const getAllService = createAsyncThunk(
//   "contact/getAllService",
//   async () => {
//     try {
//       const res = await CommonService.getCommonDataService();
//       return res.data.data.service;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );
export const fetchContactUs = createAsyncThunk(
  "contact/fetchContactUs",
  async id => {
    try {
      const res = await ContactusService.fetchContactUs(id);
      return res.data.data;
    } catch (error) {}
  }
);
const initialState = {
  loading: false,
  error: "",
  contacts: [],
  services: [],
  contact: {
    id: undefined,
    name: "",
    email: "",
    mobile_number: "",
    massage: "",
    service_id: undefined,
  },
};

const contactusSlice = createSlice({
  name: "contact",
  initialState,
  extraReducers(builder) {
    //ADD CONTACT
    builder
      .addCase(addContact.pending, state => {
        state.loading = true;
      })
      .addCase(addContact.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.contacts = [...state.contacts, payload];
      })
      .addCase(addContact.rejected, (state, { payload }) => {
        state.loading = false;
        state.contact = {
          id: undefined,
          name: "",
          email: "",
          mobile_number: "",
          massage: "",
          service_id: undefined,
        };
        state.error = payload;
      });
    //GET ALL SERVICE
    // builder
    //   .addCase(getAllService.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(getAllService.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.services = payload;
    //   })
    //   .addCase(getAllService.rejected, (state) => {
    //     state.loading = false;
    //     state.contacts = [];
    //   })
    //FETCH ALL CONTACT US
    builder
      .addCase(fetchContactUs.pending, state => {
        state.loading = true;
      })
      .addCase(fetchContactUs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.contact = payload;
      })
      .addCase(fetchContactUs.rejected, state => {
        state.loading = false;
        state.contacts = [];
      });
  },
});
export default contactusSlice.reducer;
