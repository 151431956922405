import React from 'react'
const AboutInfo = ({ shortDescription, infoImage }) => {
    return (
        <section className="about_info">
            <div className="container">
                <div className="row">
                    <div className="col-sm-5">
                        <img src={process.env.REACT_APP_IMAGE_URL + infoImage} className="img-fluid" alt="" />
                    </div>
                    <div className="col-sm-7" dangerouslySetInnerHTML={{ __html: shortDescription }}>
                        {/* <p>{props.shortDescription}</p> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutInfo