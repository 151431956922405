import React, { useEffect } from "react";

import PartnerAchivment from "./Sections/PartnerAchivment";
import PartnerExperience from "./Sections/PartnerExperience";
import PartnerBanner from "./Sections/PartnerBanner";
import PartnerSection from "./Sections/PartnerSection";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerPage } from "../../features/slices/settingSlice";
import HelmetBase from "../../components/Helmet/Helmetbase";

const Partner = () => {
  const dispatch = useDispatch();

  const { partnerPage } = useSelector(state => state.settings);

  const { settings } = useSelector(state => state.home);
  const { achivement_title, achivement_description } = settings;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPartnerPage(1));
  }, [dispatch]);

  return (
    <React.Fragment>
      <HelmetBase
        title={partnerPage?.page_title}
        description={partnerPage?.page_description}
        link={"partner"}
      />

      <PartnerBanner
        banner_image={partnerPage?.banner_image}
        banner_title={partnerPage?.banner_title}
      />
      <PartnerSection />
      <PartnerExperience
        ourPartner_title={partnerPage?.ourPartner_title}
        image1={partnerPage?.image1}
        image2={partnerPage?.image2}
        image3={partnerPage?.image3}
        image1_subtitle={partnerPage?.image1_subtitle}
        image2_subtitle={partnerPage?.image2_subtitle}
        image3_subtitle={partnerPage?.image3_subtitle}
      />
      <PartnerAchivment
        title={achivement_title}
        description={achivement_description}
      />
    </React.Fragment>
  );
};

export default Partner;
