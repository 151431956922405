import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { getAllState } from "../../features/slices/settingSlice";
import * as Yup from "yup";

const StateModal = ({ showServiceModal, onHide, show, id }) => {
  const { states } = useSelector(state => state.home);
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getAllState());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    state: Yup.string().required("State Selection is Required"),
  });
  const formik = useFormik({
    initialValues: {
      state: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      let state = values.state;
      // onHide();
      showServiceModal(id, state);

      resetForm({});
      setSubmitting(false);
    },
  });
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-4">
          <h5 className="modal-title mb-3" id="buynowTitle">
            You are almost there..
          </h5>
          <p className="text-center mb-5">
            Thanks for showing interest in GST Filings service{" "}
          </p>
          <Row>
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-12 text-center">
                    <Form.Select
                      name="state"
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      aria-label="Default select example"
                      className="form-control"
                    >
                      <option>State</option>
                      {states.map(s => {
                        return (
                          <option key={s.id} value={s.name}>
                            {s.name}
                          </option>
                        );
                      })}
                    </Form.Select>

                    <p>
                      {" "}
                      <a href="#"> Confirm your location to continue.</a>
                    </p>
                  </div>
                </div>
                <Button
                  type="submit"
                  className="btn btn-success mx-2 btn-block"
                >
                  Continue
                </Button>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default StateModal;
