import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../services/AuthService";
import Swal from "sweetalert2";

// export const authRegister = createAsyncThunk("auth/register", async (data) => {
//     try {
//         const response = await AuthService.authRegister(data);
//         return response.data.data;
//     } catch (error) {
//         console.log(error)
//     }

// });

export const authEmail = createAsyncThunk("auth/authEmail", async data => {
  try {
    const response = await AuthService.authEmail(data);
    if (response.data.status === false) {
      return response.data;
    }
    if (response.data.status === true) {
      return response.data.data;
    }
  } catch (error) {
    console.log(error);
  }
});

export const authRegister = createAsyncThunk(
  "auth/authRegister",
  async data => {
    try {
      const response = await AuthService.authRegister(data);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const authLogin = createAsyncThunk(
  "auth/authLogin",
  async (data, thunkAPI) => {
    try {
      const response = await AuthService.login(data);
      if (response.data.status === true) {
        localStorage.setItem("token", response.data.data.token.authToken);
        localStorage.setItem("role", response.data.data.token.userToken.role);
        localStorage.setItem("email", response.data.data.token.userToken.email);
        return {
          ...data,
          token: response.data.data.token.authToken,
          role: response.data.data.token.userToken.role,
        };
      }
      if (response.data.status === false) {
        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async data => {
    try {
      const response = await AuthService.forgetPassword(data);
      if (response.data.status === true) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Mail Sent Successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        return { ...data, status: response.data.status };
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Not A Valid Email",
          showConfirmButton: false,
          timer: 3000,
        });
        return { ...data, status: response.data.status };
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async data => {
    try {
      const response = await AuthService.resetPassword(data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const googleLoginService = createAsyncThunk(
  "auth/googleLoginService",
  async data => {
    try {
      const response = await AuthService.googleLoginService(data);
      if (response.data.status === true) {
        localStorage.setItem("token", response.data.data.token.authToken);
        localStorage.setItem("role", response.data.data.token.userToken.role);
        localStorage.setItem("email", response.data.data.token.userToken.email);
        return {
          ...data,
          token: response.data.data.token.authToken,
          role: response.data.data.token.userToken.role,
        };
      }
      if (response.data.status === false) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    dob: "",
    gender: "",
    address1: "",
    address2: "",
    pincode: "",
    city: "",
    state: "",
    district: "",
    role: "",
    token: "",
    loading: false,
    error: null,
    passsword: "",
    confirmPassword: "",
    isChecked: false,
    errorCode: null,
    status: false,
  },

  reducers: {
    // Reducer comes here]
    resetError(state) {
      state.error = null;
      state.errorCode = null;
    },
  },
  extraReducers: {
    //REGISTER USER

    [authEmail.pending]: state => {
      state.loading = true;
    },
    [authEmail.fulfilled]: (state, { payload }) => {
      if (payload.status === false) {
        state.loading = false;
        state.error = payload.message;
      } else {
        state.loading = false;
        state.error = null;
        state.email = payload.email;
      }
    },
    [authEmail.rejected]: (state, { payload }) => {
      state.loading = false;

      state.error = payload;
    },

    ///Register With Password

    [authRegister.pending]: state => {
      state.loading = true;
    },
    [authRegister.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.email = payload.email;
      // state.passsword = payload.passsword;
      // state.confirmPassword = payload.confirmPasswords;
      state.error = null;
    },
    [authRegister.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    //LOGIN USER

    [authLogin.pending]: state => {
      state.loading = true;
    },
    [authLogin.fulfilled]: (state, { payload }) => {
      if (payload.status === false) {
        state.loading = false;
        state.error = payload.message;
        state.errorCode = payload.code;
      } else {
        state.email = payload.email;
        state.token = payload.authToken;
        state.role = payload.role;
        state.loading = false;
        state.error = null;
        // state.errorCode = payload.code
      }
    },
    [authLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    ///FORGET PASSWORD
    [forgetPassword.pending]: state => {
      state.loading = true;
    },
    [forgetPassword.fulfilled]: (state, { payload }) => {
      if (payload.status === false) {
        state.loading = false;
        state.error = payload.message;
      } else {
        state.email = payload.email;
        state.loading = false;
        state.error = null;
      }
    },
    [forgetPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    ///Reset PASSWORD
    [resetPassword.pending]: state => {
      state.loading = true;
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      if (payload.status === false) {
        state.loading = false;
        state.error = payload.message;
        state.status = payload.status;
      } else {
        state.password = payload.new_password;
      }
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    //GOOGLE LOGIN
    [googleLoginService.pending]: state => {
      state.loading = true;
    },
    [googleLoginService.fulfilled]: (state, { payload }) => {
      if (payload.status === false) {
        state.loading = false;
        state.error = payload.message;
        state.errorCode = payload.code;
      } else {
        state.email = payload.email;
        state.token = payload.authToken;
        state.role = payload.role;
        state.loading = false;
        state.error = null;
        // state.errorCode = payload.code
      }
    },
    [googleLoginService.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { resetError } = authSlice.actions;
export default authSlice.reducer;
