import React from "react";
import { Button } from "react-bootstrap";
import videoshape2 from "../../../assets/images/h2-video-shape2.svg";
import partner from "../../../assets/images/img_group.png";
import { toLocalImageUrl } from "../../../helpers/AssetsHelpers";
import PartnerModal from "./PartnerModal";

const PartnerBanner = ({ banner_image, banner_title }) => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <section className="partner_banner">
      <div className="shape1">
        <img src={videoshape2} className="img-fluid" alt="spahe" />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <div className="title">
              <h2>{banner_title}</h2>
            </div>
            <Button variant="primary" onClick={() => setModalShow(true)}>
              Become A Partner
            </Button>
            <PartnerModal show={modalShow} onHide={() => setModalShow(false)} />
          </div>
          <div className="col-sm-6">
            <img
              src={toLocalImageUrl(banner_image)}
              className="img-fluid"
              alt="partner"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerBanner;
