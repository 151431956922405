import React from "react";
import { Button } from "react-bootstrap";

const Closed = ({ userServiceListClosed }) => {
  return (
    <div>
      {userServiceListClosed.map((userervice, index) => {
        const { service_name, price, duration } = userervice;
        return (
          <div className="service_block" key={index}>
            <div className="row align-items-center">
              <div className="fav_icon"></div>
              <div className="col-lg-9">
                <div className="service_block_left">
                  <p className="font-weight-bold">{service_name}</p>
                  <p>{duration}</p>
                  <h4 className="price">
                    ₹{price}/- <span> only (Exclusive)</span>{" "}
                  </h4>
                </div>
              </div>
              <div className="col-lg-3">
                <Button className="btn btn-success">closed</Button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Closed;
