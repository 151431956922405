import React from "react";
import ContactHelp from "./Sections/ContactHelp";
import ContactTitle from "./Sections/ContactTitle";
import ContactContent from "./Sections/ContactContent";
import ContactLocation from "./Sections/ContactLocation";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchContactUs } from "../../features/slices/contactusSlice";
import { getAllState } from "../../features/slices/settingSlice";
import HelmetBase from "../../components/Helmet/Helmetbase";

const ContactUs = () => {
  const { contact } = useSelector(state => state.contacts);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getAllState());
    dispatch(fetchContactUs(1));
  }, [dispatch]);

  return (
    <>
      <HelmetBase
        title={contact?.page_title}
        description={contact?.page_description}
        link={"contactus"}
      />

      <ContactTitle bannerImage={contact?.image} />
      <ContactContent
        Title={contact?.title}
        Description={contact?.description}
      />
      <ContactHelp
        Title={contact?.email_title}
        Email={contact?.email}
        Title1={contact?.email_title1}
        Email1={contact?.email1}
        Title2={contact?.email_title2}
        Email2={contact?.email2}
        // Title3={contact.email_title3}
        // Email3={contact.email3}
        // Title4={contact.email_title4}
        // Email4={contact.email4}
        // Title5={contact.email_title5}
        // Email5={contact.email5}
      />
      <ContactLocation
        Address1={contact?.location1}
        Address2={contact?.location2}
        Address3={contact?.location3}
      />
    </>
  );
};

export default ContactUs;
