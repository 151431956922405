import React from 'react'
import { Modal } from 'react-bootstrap'

const ContactModal = (props) => {
    return (<>
        <Modal
            {...props}
            size="md-4"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div class="thankyou_wrap">
                <div class="text-center ">
                    <div class="checkmark mb-4">
                        <i class="fa fa-check " id="checkmark"></i>
                    </div>
                    <h1>THANK YOU! FOR CONTACT US</h1>
                </div>
            </div>
        </Modal></>)
}

export default ContactModal