import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 3px solid #c4c4c4;
`;
const Button = styled.input`
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #c4c4c4;
    border: 1px solid #c4c4c4;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12.56px;
      height: 12.56px;
      margin: 3px;
      background: #359784;
    }
  }
  ${({ checked, theme }) =>
    checked &&
    `&:checked + ${RadioButtonLabel} {
      background: #C4C4C4;
      border: 1px solid #C4C4C4;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12.56px;
        height: 12.56px;
        margin: 3px;
        background: #359784;
      }
    }
  `}
`;
const TextError = styled.span`
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  color: #ff0000;
  line-height: 14px;
  font-weight: bold;
  margin-top: 11px;
  margin-left: 8px;
`;

const RadioButton = ({
  label,
  name,
  value,
  checked,
  handleChange,
  error,
  touched,
}) => {
  const handleChecked = () => {
    console.log("name", name, value);

    handleChange(name, value);
  };
  return (
    <Wrapper>
      <Item onClick={handleChecked}>
        <Button
          type="radio"
          id={name}
          name={name}
          value={value}
          checked={checked}
          onChange={handleChecked}
        />
        <RadioButtonLabel />
        <div>{label}</div>
        {error && touched && <TextError>{error}</TextError>}
      </Item>
    </Wrapper>
  );
};

export default RadioButton;
