import React from 'react'
import partner1 from "../../../assets/images/partner1.png"
import partner2 from "../../../assets/images/partner2.png"
import partner3 from "../../../assets/images/partner3.png"
import partner4 from "../../../assets/images/partner4.png"
import partner5 from "../../../assets/images/partner5.png"
import PartnerModal from './PartnerModal'
const PartnerSection = () => {
    const [modalShow, setModalShow] = React.useState(false);

    return (<>
        <PartnerModal
            show={modalShow}
            onHide={() => setModalShow(false)}
        />
        <section className="section_content partner_sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="title">
                            <h2>	Who can become partners </h2>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="partner_box" onClick={() => setModalShow(!modalShow)}>
                            <div className="media align-items-center">
                                <img src={partner1} className="img-fluid" alt="partner" />
                                <div className="media-body">
                                    <h5 className="mt-0">Chartered
                                        accountants</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="partner_box" onClick={() => setModalShow(!modalShow)}>
                            <div className="media align-items-center">
                                <img src={partner2} className="img-fluid" alt="partner" />
                                <div className="media-body">
                                    <h5 className="mt-0">Company
                                        secretaries</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="partner_box" onClick={() => setModalShow(!modalShow)}>
                            <div className="media align-items-center">
                                <img src={partner3} className="img-fluid" alt="partner" />
                                <div className="media-body">
                                    <h5 className="mt-0">Lawyers</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="partner_box" onClick={() => setModalShow(!modalShow)}>
                            <div className="media align-items-center">
                                <img src={partner4} className="img-fluid" alt="partner" />
                                <div className="media-body">
                                    <h5 className="mt-0">Accountants</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="partner_box" onClick={() => setModalShow(!modalShow)}>
                            <div className="media align-items-center">
                                <img src={partner5} className="img-fluid" alt="partner" />
                                <div className="media-body">
                                    <h5 className="mt-0">Finance
                                        professionals </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
    )
}

export default PartnerSection