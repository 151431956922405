import React, { useEffect } from "react";

const TermsOfServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <section className="terms_title">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title">
                <h2>
                  Please read these Terms and Conditions carefully before using
                  this site
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section_content terms_content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>1. WELCOME TO ENSUREKAR.COM!</h5>
              <p>
                Since we will not be meeting face to face, it is important to
                set out the terms of the agreement clearly in advance. If you
                have any queries about Ensurekar, please do not hesitate to
                contact us. In this agreement, we have referred to the Ensurekar
                service as the "service", to you as the "user" and to our
                agreement as the "agreement". If you wish to use our “Common
                Needs” feature, you affirm that you are more than 18 years of
                age and are fully able and competent to enter into the terms,
                conditions, obligations, affirmations, representations, and
                warranties consequent to the creation of the documents, and are
                aware of the same. Kindly call us for further assistance.
              </p>

              <h5>2. ENSUREKAR.COM ONLY PROVIDES A MEDIUM FOR INTERACTION</h5>
              <p>
                Ensurekar is an internet portal that facilitates communication
                between legal professionals and potential users of legal
                services. Ensurekar acts as a venue for providers and consumers
                of legal services to exchange information with the goal of
                eventually forming a professional relationship. Ensurekar does
                not guarantee that users will successfully find an
                advocate/lawyer/attorney through this system. Ensurekar takes no
                position and offers no opinion on when or if a lawyer-client
                relationship has been formed. In order to provide an optimal
                forum for lawyers and clients, Ensurekar does not involve itself
                in the agreements between lawyers and clients or the actual
                representation of clients. Therefore, we cannot ensure the
                completion of the agreement or the integrity of either party.
                The user, and not Ensurekar, is solely responsible for assessing
                the integrity, honesty, and trustworthiness of all persons with
                whom the user communicates on this service.
              </p>

              <p>
                a) Disclaimer of lawyer-client relationship
                <br />
                Ensurekar is not an agent of lawyers. It only facilitates the
                communication of lawyers and potential clients. Any electronic
                communication sent to Ensurekar alone will not create a
                lawyer-client relationship between the user and Ensurekar, such
                being expressly denied.
              </p>
              <p>
                b) Ensurekar does not promote any User.
                <br />
                Ensurekar seeks to help every needy litigant find lawyers best
                suited to his/her needs. Ensurekar is not intended to be a
                source of advertising or solicitation and the contents of the
                website should not be construed as legal advice. Ensurekar may
                recommend subscribing lawyers if they match a user’s
                requirements, but not otherwise. Transmission, receipt or use of
                Ensurekar does not constitute or create a lawyer-client
                relationship. No recipients of content from this website should
                act, or refrain from acting, based upon any or all of the
                contents of this site. We welcome the user to study the profiles
                of lawyers independently and make an informed choice.
              </p>
              <p>
                c) Specifically, Ensurekar does not provide any avenue for
                solicitation
                <br />
                Ensurekar hides information about clients from lawyers until the
                client communicates with the lawyers directly or online.
                Therefore, the lawyers are not allowed to view private
                information about potential clients.
              </p>

              <p>
                d) Ensurekar does not provide Legal Advice
                <br />
                Ensurekar ‘Common Needs’ feature uses only user supplied content
                to produce basic documents. The information provided in the
                ‘FAQs’ section also does not amount to legal advice, such merely
                being commonly asked queries about Will making, Lease Agreement
                drafting, Cheque Dishonour notices, Money recovery notices,
                Power of Attorney to collect rent and other documents which may
                be added from time to time. Users are advised to consult lawyers
                if they need specialized guidance on any of these documents.
              </p>

              <p>
                e) 'Common Needs'- Resale of Forms Prohibited
                <br />
                Ensurekar grants you a limited, personal, non-exclusive,
                non-transferable license to use our “Common Needs” feature for
                your own personal use, or if you are an attorney or
                professional, for your client. Except as otherwise provided, you
                acknowledge and agree that you have no right to modify, edit,
                copy, reproduce, create derivative works of, reverse engineer,
                alter, enhance or in any way exploit any of the Forms in any
                manner, except for modifications in filling out the Forms for
                your authorized use.
                <br />
                By ordering a document from Ensurekar, you agree that the
                document you purchase may only be used by you for your personal
                or business use or used by you in connection with your client
                and may not be sold or redistributed without the express written
                consent of Ensurekar. Reselling or distributing without
                permission amounts to a violation of Vakilsearch’s exclusive
                copyright and is liable to prosecution.
              </p>

              <p>
                f) Disclaimer of representations by users
                <br />
                Ensurekar makes no representation, guarantee, or warranty
                (express or implied) as to the legal ability, competence, or
                quality of representation which may be provided by any of the
                lawyers or law firms which are listed through this site or any
                affiliate thereof.
                <br />
                Please note that neither Ensurekar, nor any of its subsidies or
                employees are advocates. We are not a law firm and we do not
                provide legal advice. Nothing on our website or material sent to
                you in our communication is to be construed as legal advice.
                <br />
                Our website, blog and other material is only for the purpose of
                spreading information and awareness and are not substitutes for
                the advices or services of an advocate or legal professional.
                <br />
                Wherever required in order to fulfill your needs, we will
                facilitate a connection with a suitable professional such as
                lawyers, chartered accountants or company secretaries. Please
                bear in mind that such professionals are not our
                representatives, agents or employees. Our site and services are
                only one source of information among the many sources that are
                available to you. You may wish to consider multiple sources in
                order to make an informed decision.
                <br />
                The decision to engage any of these professionals is an
                important one, and one that you must make carefully based solely
                on your own judgment. If you agree to avail of the services on
                our website, you are giving us permission to make this selection
                on your behalf. If you disagree with these terms and conditions,
                or do not want us to choose a suitable professional to fulfill
                your request, please do not use our services.
                <br />
                We constantly strive to keep our content and documents accurate,
                current and up-to date. However, because of changes in the law
                and regulations, we cannot and do not guarantee that any or all
                of the information on the site and other communication is
                completely current.
                <br />
                Please be advised that sometimes, the law, legal requirements,
                rules and regulations are location specific and may differ from
                location to location. The general information or other material
                we provide cannot fit every situation or circumstance.
                <br />
                Our sites and services are not intended to create any
                advocate-client relationship, and your use of our sites and
                services does not and will not in any circumstance create any
                such relationship between you and us.
              </p>

              <h5>3. USER GUIDELINES</h5>
              <p>
                The users of Ensurekar are granted a nonexclusive, limited right
                to access and use the service in accordance with the rules that
                are described in this contract. In order to keep this system
                attractive and useful for all users, it is important that users
                follow the rules of the system. Ensurekar reserves the right to
                deny further access to its service to any user who violates
                these rules, is the subject of complaints by other Ensurekar
                users or for any other reason.
              </p>
              <br />
              <p>
                Users engaged in any of the following activities on our system
                will not be tolerated:
              </p>
              <ul>
                <li>Foul or otherwise inappropriate language.</li>
                <li>Racist, hateful, or otherwise offensive comments.</li>
                <li>
                  Promote or provide instructional information about illegal
                  activities, or promoting physical harm or injury against any
                  group or individual.
                </li>
                <li>
                  Defame any person or group which includes people of all ages,
                  races, religions, and nationalities.
                </li>
                <li>
                  Violate the rights of another, including but not limited to
                  the intellectual property rights of another. This includes
                  using the service for acts of copyright, trademark, patent,
                  trade secret, or other intellectual property infringement,
                  including but not limited to offering pirated computer
                  programs or links to such programs, information used to
                  circumvent manufacturer-installed copy-protect devices,
                  including serial or registration numbers for software
                  programs, or any type of cracker utilities (this also includes
                  files which are solely intended for game emulation).
                </li>
                <li>Violate Internet standards.</li>
                <li>
                  Use the service for displaying harassing, abusive,
                  threatening, harmful, vulgar, obscene, or tortuous material or
                  invading other's privacy.
                </li>
                <li>
                  Interfere with or disrupting the service or servers or
                  networks connected to the service by posting advertisements or
                  links to competing services, transmitting "junk mail", "spam",
                  "chain letters", or unsolicited mass distribution of e-mail.
                </li>
                <li>
                  Compromise the security of the service Ensurekar provides.
                  Please do not try to gain access to system areas private to
                  Ensurekar, or to other users.
                </li>
              </ul>

              <h5>
                4. DISCLAIMER OF INFORMATION OBTAINED ON THE SERVICE AND SOME
                USER SUPPLIED CONTENT
              </h5>
              <p>
                a) Disclaimer of information obtained on the Service
                <br />
                Ensurekar provides lawyers and potential clients with a forum
                whereby people who need legal representation or help are
                connected to providers of it. Ensurekar is a resource for
                informational purposes and is intended, but not promised or
                guaranteed to be correct, complete, and up-to-date. The
                accuracy, completeness or adequacy of Ensurekar is not warranted
                or guaranteed. Ensurekar further assumes no liability for the
                interpretation and/or use of the information contained on this
                website. The owner of this website does not intend links from
                this site to other websites to be referrals to, endorsements of,
                or affiliations with the linked entities. Ensurekar is not
                responsible for, and makes no representations or warranties
                about the contents of websites to which links may be provided
                from this website.
                <br />
                Ensurekar will make every effort to ensure that promotional
                material of a user trying to promote himself on the website is
                deleted. Apart from this, the opinions and views expressed are
                those of the individual users of the service and do not reflect
                those of Ensurekar. Data submitted by other users (lay persons)
                is not verified or reviewed in any way before it appears on the
                Ensurekar website. Please use due caution when using this site.
                <br />
                Ensurekar makes every effort to verify that lawyers who
                subscribe to the service are licensed and in good standing with
                the local bar at the time of registration. However, Ensurekar
                cannot track, verify, or monitor the standing of lawyers using
                the Service. Therefore, Ensurekar makes no representation
                regarding the status, standing or ability of lawyers or law firm
                that is listed on the site.
                <br />
                Users are urged to make their own independent investigation and
                evaluation of lawyers or law firm being considered. The
                determination of the need for legal services and the choice of
                lawyers are extremely important decisions and should not be
                based solely on claims of expertise, or on the cost of rendering
                the requested legal services.
                <br />
                Ensurekar is not responsible for, and in no way endorses any
                description or indication of specialization or limitation of
                practice by lawyers or law firm. Efforts will be made to avoid
                false information, but please be aware that no agency or board
                may have certified such lawyers as a specialists or experts in
                any indicated field of law practice. In addition, lawyers
                claiming specialization is not necessarily any more competent
                than other lawyers. It is up to the user to question the lawyers
                on the factual basis of any statement they make, ask for the
                names of the certifying agencies, and verify all information.
                <br />
                Users are encouraged to use caution when reviewing any
                information submitted by lawyers and other parties. Although
                Ensurekar requires lawyers to comply with all regulations
                governing lawyers conduct, it is impossible for Ensurekar to
                monitor lawyers' integrity.
                <br />
                Ensurekar in no way endorses the content or legality of any
                offers, statements, or promises made by lawyers or any other
                parties, on or off this site.
              </p>

              <p>
                b) Disclaimer of content supplied by users in the form of
                reviews, comments, communications, and other content
                <br />
                At various locations on the Site, Ensurekar may permit visitors
                to post reviews, comments, and other content (the "user
                content"). Ensurekar is not the publisher or author of such user
                content. It only stores and disseminates the ideas and opinions
                that Ensurekar members may choose to post and distribute as user
                content. Ensurekar disclaims all responsibility for this
                content. If any offending material is brought to the notice of
                Ensurekar, it will be deleted as soon as is possible. Whether
                such material is indeed offending will be finally be left to the
                discretion of Ensurekar.
              </p>

              <h5>5.LIMITATIONS ON USE</h5>
              <p>
                The contents of Ensurekar are for personal use only and not for
                commercial exploitation. You may not decompile, reverse
                engineer, disassemble, rent, lease, loan, sell, sublicense, or
                create derivative works from Ensurekar. Nor may you use any
                network monitoring or discovery software to determine the site
                architecture, or extract information about usage or users. You
                may not use any robot, spider, other automatic device, or manual
                process to monitor or copy the contents without taking prior
                written permission from Ensurekar. You may not copy, modify,
                reproduce, republish, distribute, display, or transmit for
                commercial, non-profit or public purposes all or any portion of
                Ensurekar, except to the extent permitted above. You may not use
                or otherwise export or re-export Ensurekar or any portion
                available on or through Ensurekar in violation of the export
                control laws and regulations of India. Any unauthorized use of
                Ensurekar or its content is prohibited.
              </p>

              <h5>6. CONFIDENTIALITY</h5>
              <p>
                Ensurekar makes every effort to maintain the confidentiality of
                any information submitted by users to our system and our
                database of lawyers. The user is however warned that the use of
                the internet or e-mail for confidential or sensitive information
                is susceptible to risks that inevitably arise on this medium.
                Additionally, because Ensurekar cannot control the conduct of
                others, we cannot guarantee that this information will remain
                confidential. Please use caution in deciding what information to
                input into the System. Do not make any confessions or
                admissions. The user should preferably describe their issue or
                dispute in the general terms only. Specific information should
                only be revealed after the user has selected an
                advocate/lawyer/attorney and made contact outside the service
                (e.g. via telephone or appointment). Subscribing lawyers using
                this service should refrain from asking any user to reveal any
                specific or confidential information through the service.
                Ensurekar is not responsible for the release or improper use of
                such information by users or any release due to error or failure
                in the System.
              </p>

              <h5>7. INDEMNIFICATION</h5>
              <p>
                The user agrees that Ensurekar is not responsible for any harm
                that his/her use of this service may cause. The user agrees to
                indemnify, defend, and hold Ensurekar harmless from and against
                any and all liability and costs incurred in connection with any
                loss, liability, claim, demand, damage, and expenses arising
                from or in connection with the contents or use of the service.
                The user agrees that this defense and indemnity shall also apply
                to any breach by the user of the agreement or the foregoing
                representations, warranties and covenants. The user further
                agrees that this defense and indemnity shall include without
                limitation of lawyers fees and costs. The user also agrees that
                this defense and indemnity shall apply to Ensurekar, its
                founders, officers and employees. Ensurekar reserves the right,
                at its own expense, to assume the exclusive defense and control
                of any matter otherwise subject to indemnification by the user
                and the user shall not in any event settle any matter without
                the written consent of Ensurekar.
              </p>

              <h5>8.COMMUNICATIONS AND OTHER DATA</h5>
              <p>
                Ensurekar is not responsible for any loss of data resulting from
                accidental or deliberate deletion, network or system outages,
                backup failure, file corruption, or any other reasons.
              </p>

              <h5>9. LICENSE OF YOUR CONTENTS TO Ensurekar</h5>
              <p>
                By uploading content to or submitting any materials for use on
                Ensurekar, you grant (or warrant that the owner of such rights
                has expressly granted) Ensurekar a perpetual, royalty-free,
                irrevocable, non-exclusive right and license to use, reproduce,
                modify, adapt, publish, translate, create derivative works from
                and distribute such materials or incorporate such materials into
                any form, medium, or technology now known or later developed.
                Ensurekar however gives an assurance that any information of a
                sensitive nature will not be intentionally disclosed and
                revealed to any third party.
              </p>
              <h5>10. Ensurekar PROPRIETARY RIGHTS</h5>
              <p>
                Except as expressly provided in these terms and conditions,
                nothing contained herein shall be construed as conferring any
                license or right, by implication, estoppels or otherwise, under
                copyright or other intellectual property rights. The user agrees
                that the content and Web Site are protected by copyright,
                trademark, service marks, patents or other proprietary rights
                and laws. The user acknowledges and agrees that the user is
                permitted to use this material and information only as expressly
                authorized by Ensurekar, and may not copy, reproduce, transmit,
                distribute, or create derivative works of such content or
                information without express authorization. The user acknowledges
                and agrees that Ensurekar can display images and text throughout
                the Service. Users cannot extract and publish any information
                from the system, either electronically or in print, without the
                permission of Ensurekar and the permission of all other
                concerned parties. This is not a complete list - other things on
                the system are also Ensurekar property. Contact Ensurekar before
                copying anything from the system with plans of reproducing it or
                distributing it.
              </p>
              <h5>11. LINKING TO Ensurekar.COMH</h5>
              <p>
                Users are welcome to provide links to the homepage of Ensurekar,
                provided they do not remove or obscure, by framing or otherwise,
                any portion of the homepage and that you discontinue providing
                links to the site if requested by Ensurekar.
              </p>
              <h5>12. ADVERTISERS</h5>
              <p>
                Ensurekar may contain advertising and sponsorship. Advertisers
                and sponsors are responsible for ensuring that material
                submitted for inclusion on Ensurekar is accurate and complies
                with applicable laws. Ensurekar will not be responsible for the
                illegality of or any error or inaccuracy in advertisers' or
                sponsors' materials.
              </p>
              <h5>13. REGISTRATION</h5>
              <p>
                Certain sections of Ensurekar may require you to register. If
                registration is requested, you agree to provide Ensurekar with
                accurate and complete registration information. It is your
                responsibility to inform Ensurekar of any changes to that
                information. Each registration is for a single person only,
                unless specifically designated otherwise on the registration
                page. Ensurekar does not permit a) any other person using the
                registered sections under your name; or b) access through a
                single name being made available to multiple users on a network.
                You are responsible for preventing such unauthorized use. If you
                believe there has been unauthorized use, please notify Ensurekar
                immediately by contacting us. If we find that unauthorized use
                is being made of Ensurekar and the services we provide, the
                right of any or many users may be terminated.
              </p>
              <h5>14. ERRORS AND CORRECTIONS</h5>
              <p>
                Ensurekar does not represent or warrant that the service will be
                error-free, free of viruses or other harmful components, or that
                defects will be corrected. Ensurekar may make improvements
                and/or changes to its features, functionality or service at any
                time.
              </p>
              <h5>15. THIRD PARTY CONTENT</h5>
              <p>
                Third party content may appear on Ensurekar or may be accessible
                via links from Ensurekar. Ensurekar is not responsible for and
                assumes no liability for any mistakes, misstatements of law,
                defamation, slander, libel, omissions, falsehood, obscenity or
                profanity in the statements, opinions, representations or any
                other form of information contained in any third party content
                appearing on Ensurekar. You understand that the information and
                opinions in the third party content is neither endorsed by nor
                does it reflect the belief of Ensurekar.
              </p>
              <h5>16. UNLAWFUL ACTIVITY</h5>
              <p>
                Ensurekar reserves the right to investigate complaints or
                reported violations of the Agreement and to take any action
                Ensurekar deems appropriate including but not limited to
                reporting any suspected unlawful activity to law enforcement
                officials, regulators, or other third parties and disclosing any
                information necessary or appropriate to such persons or entities
                relating to user profiles, e-mail addresses, usage history,
                posted materials, IP addresses and traffic information.
              </p>
              <h5>17. REMEDIES FOR VIOLATIONS</h5>
              <p>
                Ensurekar reserves the right to seek all remedies available at
                law and in equity for violations of the Agreement, including but
                not limited to the right to block access from a particular
                Internet address to Ensurekar and its features.
              </p>
              <h5>18. CONFLICTS CHECKS</h5>
              <p>
                The user understands that registered lawyers will not be able to
                and will not perform a check for conflicts of interest between
                the user and other clients of the registered lawyers prior to
                responding to a request. Conflict checks require the user to
                provide their name and contact information and the identity of
                any affiliated entities, opposing individuals and entities, and
                such other information as lawyers may require. Conflict checks
                by registered lawyers who obtains information from the user
                through this service are not possible since submissions by the
                user to subscribing lawyers are not sufficient to conduct such a
                check.
              </p>
              <h5>19. SEVERABILITY OF PROVISIONS</h5>
              <p>
                The Agreement and the Privacy Policy constitute the entire
                agreement with respect to the use of the service provided by
                Ensurekar. If any provision of these terms and conditions is
                unlawful, void or unenforceable then that provision shall be
                deemed severable from the remaining provisions and shall not
                affect their validity and enforceability.
              </p>
              <h5>20. MODIFICATIONS TO TERMS OF USE</h5>
              <p>
                Ensurekar may change the agreement at any time. The user will be
                responsible for checking the Terms and Conditions before use.
                Use of the service after the change will indicate acceptance of
                the new terms and conditions.
              </p>
              <h5>21. MODIFICATIONS TO SERVICE</h5>
              <p>
                Ensurekar reserves the right to modify or discontinue,
                temporarily or permanently, the service with or without notice
                to the user. The user agrees that Ensurekar shall not be liable
                to the user or any third party for any modification or
                discontinuance of the service. The user acknowledges and agrees
                that any termination of service under any provision of this
                agreement may be effected without prior notice, and acknowledges
                and agrees that Ensurekar may immediately delete data and files
                in the user's account and bar any further access to such files
                or the Service.
              </p>

              <h5>22.DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY </h5>
              <p>
                A great danger for Ensurekar, and for all operators of online
                systems, is that we might be held accountable for the wrongful
                actions of our users. If one user libels another user, the
                injured user might blame us, even though the first user was
                really at fault. If a user uploads a program with a computer
                virus, and the other users' computers are damaged, we might be
                blamed even though a user left the virus on our System. If a
                user transmits illegal or improper information to another user,
                we might be blamed even though we did nothing more than
                unknowingly carry the message from one user to another.
                Accordingly, we need all users to accept responsibility for
                their own acts, and to accept that an act by another user that
                damages them must not be blamed on us, but only on the other
                user.
                <br />
                Although it is the goal of Ensurekar to provide users with
                reliable and quality systems, we may make mistakes or experience
                system failure from time to time. Such problems are inevitable
                in operating a system of this size. We would not be able to make
                this system available to users if we had to accept blame or
                financial liability for any usability problems, system failures
                or errors, or mistakes or damages of any kind. In order to
                continue offering and improving our service, Ensurekar must deny
                any warranties on this service and state that our liability for
                any problems connected with the use of our system is strictly
                limited.
                <br />
                These needs are accomplished by the following disclaimers:
              </p>
              <ul>
                <li>
                  <p>
                    a) Disclaimer of Warranties
                    <br />
                    The user expressly agrees that use of the service is at the
                    user's sole risk. The service is provided on an "as is" and
                    "as available" basis. Ensurekar expressly disclaims all
                    warranties of any kind, whether express or implied,
                    including, but not limited to the implied warranties of
                    merchantability, fitness for a particular purpose and
                    non-infringement. Ensurekar makes no warranty that the
                    service will meet a user's requirements, that the service
                    will be uninterrupted, timely, secure, or error-free; nor
                    does Ensurekar make any warranty as to the results that may
                    be obtained from the use of the service or as to the
                    accuracy or reliability of any information obtained through
                    the service or that defects in the software will be
                    corrected. Ensurekar makes no warranty regarding any goods
                    or services purchased or information obtained through the
                    service or any transactions entered into through the
                    service.
                    <br />
                    No advice or information, whether oral or written, obtained
                    by the User from Ensurekar shall create any warranty not
                    expressly stated herein.
                  </p>
                </li>
                <li>
                  <p>
                    b) Limitation of Liability
                    <br />
                    The user agrees that Ensurekar shall not be liable for any
                    direct, indirect, incidental, special or consequential
                    damages resulting from the use or the inability to use the
                    service or for the cost of procurement of substitute goods
                    and services or resulting from any goods or services
                    purchased or obtained or messages received or transactions
                    entered into through or from the service or resulting from
                    unauthorized access to or alteration of user's transmissions
                    or data, including, but not limited to damages for loss of
                    profits, use, data or other intangibles, even if Ensurekar
                    has been advised of the possibility of such damages. The
                    user further agrees that Ensurekar shall not be liable for
                    any damages arising from interruption, suspension or
                    termination of service, including, but not limited to
                    direct, indirect, incidental, special, consequential or
                    exemplary damages, whether or not such interruption,
                    suspension or termination was justified, negligent,
                    intentional or inadvertent.
                  </p>
                </li>
              </ul>
              <h5>23. ARBITRATION </h5>
              <p>
                Any controversy or claim arising out of or relating to this
                Agreement or Ensurekar services shall be settled by binding
                Arbitration in accordance with laws of India. Any such
                controversy or claim shall be arbitrated on an individual basis,
                and shall not be consolidated in any arbitration with any claim
                or controversy of any other party. Any other dispute or
                disagreement of a legal nature will also be decided in
                accordance with the laws of India, and the Courts of Chennai
                shall have jurisdiction in all such cases.
              </p>
              <h5>24. OWNERSHIP</h5>
              <p>
                This Site is owned and operated by Ensurekar. All right, title
                and interest in and to the materials provided on this Site,
                including but not limited to information, documents, logos,
                graphics, sounds and images (the "Materials") are owned by
                Ensurekar. Except as otherwise expressly provided by Ensurekar,
                none of the materials may be copied, reproduced, republished,
                downloaded, uploaded, posted, displayed, transmitted or
                distributed in any way and nothing on this Site shall be
                construed to confer any license under any of Ensurekar's
                intellectual property rights, whether by estoppel, implication
                or otherwise. Contact us if you have any questions about
                obtaining such licenses. Ensurekar does not sell, license, lease
                or otherwise provide any of the materials other than those
                specifically identified as being provided by Ensurekar. Any
                rights not expressly granted herein are reserved by Ensurekar.
              </p>
              <h5>25. ENTIRE AGREEMENT</h5>
              <p>
                This agreement constitutes the entire and whole agreement
                between user and Ensurekar, and is intended as a complete and
                exclusive statement of the terms of the agreement. This
                agreement shall supersede all other communications between
                Ensurekar and its users with respect to the subject matter
                hereof and supersedes and replaces all prior or contemporaneous
                understandings or agreements, written or oral, regarding such
                subject matter. If at any time you find these Terms and
                Conditions unacceptable or if you do not agree to these Terms
                and Conditions, please do not use this Site. We may revise these
                Terms and Conditions at any time without notice to you. It is
                your responsibility to review these Terms and Conditions
                periodically.
                <br />
                By using Ensurekar services or accessing the Ensurekar site, you
                acknowledge that you have read these terms and conditions and
                agree to be bound by them.
              </p>
              <h5>26. INDEMNIFICATION</h5>
              <p>
                You agree to defend, indemnify and hold harmless Ensurekar, our
                officers, directors, shareholders, employees and agents from and
                against any and all claims, liabilities, damages, losses or
                expenses, including reasonable attorneys' fees and costs,
                arising out of or in any way connected with your access to or
                use of the site and the materials.
              </p>
              <h5>27. CANCELLATION AND REFUND POLICY</h5>
              <p>
                We strive to ensure that the services you avail through our
                website are to your full satisfaction, and are the best in the
                Industry at extremely reasonable and affordable rates.
                <br />
                However, there may arise situations when you desire a refund.
                Firstly, when you pay for the services but later on decide that
                you do not wish to avail them. Secondly, when there is a delay
                in the services offered from our side, beyond the time frame we
                have intimated to you, due to human error i.e., factors for
                which we are solely responsible. Thirdly, although we highly
                doubt it, you might find our services unsatisfactory. In all
                three situations, kindly send in an e-mail to on the Ticket that
                has been created in your name, marking a copy to
                attention@Ensurekar.com. We would like to clarify that only
                refunds of the professional fees component of the charges paid
                by you shall be considered for a refund.
                <br />
                Upon receiving your mail, the Senior Management at Ensurekar
                shall decide on whether your request for a refund should be
                processed, contingent on the reasons for such a request. Please
                note that we reserve the right to take the final and binding
                decision with regard to requests for refund.
                <br />
                Most importantly, we wish to clarify that in cases outside our
                control, including but not limited to national holidays,
                department holidays, delays on the part of the Government of
                India, the respective State Governments, Our affiliates or
                elsewhere, acts of war, acts of God, earthquake, riot, sabotage,
                labour shortage or dispute, internet interruption, power
                disruption, lack of phone network connectivity, technical
                failures, breakage of sea cable, hacking, piracy, we shall not
                liable for any delays.
                <br />
                If we confirm your request for refund, subject to the terms and
                conditions mentioned herein or elsewhere, we will send you an
                e-mail seeking the details required to refund the amount which
                may include your Bank Account details such as the account number
                and the IFS code of the branch in question. Kindly note that it
                will take us a minimum of about 48-72 working hours from the
                receipt of all such information to process the refund and
                initate the transfer.
                <br />
                We reiterate once again that only the professional fees paid for
                our services shall be refunded, subject to the discretion of the
                Senior Management at Ensurekar.com.
                <br />
                We assure you that we are continuously working to improve our
                services and are we are welcome to any suggestions from your
                end. For any other queries please contact out customer service
                desk at Ensurekar.com.
                <br />
                We appreciate your interest and support and we welcome you to
                our community!
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default TermsOfServices;
