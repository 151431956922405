import React, { useEffect } from "react";
import baseAPI from "../../api/userApi";
const ThankYouPlan = () => {
  return (
    <div class="thankyou_wrap">
      <div class="container">
        <div class="card text-center ">
          <div class="circle-shape-1"></div>
          <div class="circle-shape-2"></div>
          <div class="circle-shape-3"></div>
          <div class="circle-shape-4"></div>
          <div class="checkmark mb-4">
            <i class="fa fa-check " id="checkmark"></i>
          </div>
          <h1>THANK YOU!</h1>

          <p>
            Thanks a bunch for filling that out. It means a lot to us, just like
            you do! We really appreciate you giving us a moment of your time
            today. Thanks for being you.
          </p>
          {/* <Link to="/my-services" className="btn btn-success">
            My Services
          </Link> */}
          <a href={process.env.REACT_APP_ENSUREKAR_SITE_URL}>Go To Home</a>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPlan;
