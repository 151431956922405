import React from "react";
import logo from "../../../assets/images/logo.png";
import forgot from "../../../assets/images/forgotpassword.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import ShowError from "../../../errors/ShowError";
import { useDispatch, useSelector } from "react-redux";
import { authRegister, resetError } from "../../../features/slices/authSlice";
const RegisterPassword = () => {
  //let params = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const { settings } = useSelector(state => state.home);
  const { header_logo } = settings;
  const { error } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const registerUser = values => {
    const email = location.state.email;
    const obj = {
      ...values,
      email,
    };
    dispatch(resetError());
    dispatch(authRegister(obj));
    navigate("/login");
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: values => {
      registerUser(values);
    },
  });

  return (
    <section className="login_sec forgot_password create_acc_2">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 text-center ">
            <div className="logo">
              <img
                src={process.env.REACT_APP_IMAGE_URL + header_logo}
                className="img-fluid"
                alt="logo"
              />
            </div>

            <div className="login_info">
              <div className="col-lg-8 mx-auto">
                <h1>Create an account</h1>
                <p>
                  Already have an account? <Link to="/login">Login</Link>{" "}
                </p>

                {error && (
                  <p className="text-danger">
                    {error} ... and Go Back and enter new email{" "}
                    <Link to="/register">Go back</Link>
                  </p>
                )}

                <Form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <Form.Control
                          type="password"
                          className="form-control"
                          id="password"
                          name="password"
                          placeholder="Enter password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <ShowError>{formik.errors.password}</ShowError>
                        )}
                      </div>

                      <div className="form-group">
                        <Form.Control
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Enter Confirm Password"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword && (
                            <ShowError>
                              {formik.errors.confirmPassword}
                            </ShowError>
                          )}
                      </div>
                    </div>
                  </div>

                  {/*<div className="custom_checkbox">
                    <input type="checkbox" id="agree" />
                    <label htmlFor="agree">Remember Me</label>
                </div>*/}
                  {error ? (
                    <Button className="btn btn-success" type="submit" disabled>
                      Sign up
                    </Button>
                  ) : (
                    <Button className="btn btn-success" type="submit">
                      Sign up
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>

          <div className="col-lg-6 text-right px-0">
            <div className="login_img">
              <img src={forgot} className="img-fluid" alt="login" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterPassword;
