import baseAPI from "../../api/userApi";
import { authHeader } from "../../helpers/authHeaders";

const getAllServiceService = async () => {
  try {
    return await baseAPI.post("/service-list", {}, { headers: authHeader() });
  } catch (error) {
    console.log(error);
  }
};

const addFavServices = async data => {
  try {
    return await baseAPI.post(
      "/create-favourite",
      { service_id: data },
      {
        headers: authHeader(),
      }
    );
  } catch (error) {
    console.log(error);
  }
};

const getUserFavServices = async () => {
  try {
    return await baseAPI.post(
      "/get-favourite-service",
      {},
      {
        headers: authHeader(),
      }
    );
  } catch (error) {
    console.log(error);
  }
};

const removeFavService = async data => {
  const service_id = data;
  try {
    return await baseAPI.delete(`/delete-favourite/${service_id}`, {
      headers: authHeader(),
    });
  } catch (error) {
    console.log(error);
  }
};
const ServiceService = {
  getAllServiceService,
  addFavServices,
  getUserFavServices,
  removeFavService,
};
export default ServiceService;
