import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { userEventInterested } from "../../../features/slices/eventSlice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const confirmDelete = withReactContent(Swal);
const EventListModal = ({ eventData, onHide, show }) => {
  const dispatch = useDispatch();

  const onInterestedClick = (id) => {
    onHide();
    confirmDelete
      .fire({
        title: `Are you sure you have interest in this Event ?`,
        text: "You won't be able to revert this!",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#359784",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I'm Interrested!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(userEventInterested(id));
          confirmDelete.fire(
            "You Have Interest in This Event",
            `Thank You`,
            "success"
          );
        }
      });
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="modal-title text-center" id="exampleModalCenterTitle">
            {eventData.title}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <p className="font-weight-bold">Due date: Within 180 days from DOI</p>
          <p className="font-weight-bold">Description</p>
          <p>{eventData.description}</p>

          <div className="row">
            <div className="col-lg-6">
              <p className="font-weight-bold">Legal entity</p>
              <ul className="list-unstyled pl-0">
                <li>Private Limited Company (PVT)</li>
                <li>One Person Company (OPC)</li>
                <li>Public Limited Company (public)</li>
                <li>Section 8</li>
                <li>NBFC Company</li>
                <li>Nidhi Company</li>
                <li>Producer Company</li>
              </ul>
            </div>

            <div className="col-lg-6">
              <div className="right_info_modal">
                <p className="font-weight-bold mb-0">Applicable for</p>
                <p>Incorporated after 02nd Nov 2018</p>
              </div>

              <div className="right_info_modal">
                <p className="font-weight-bold mb-0">Penalty</p>
                <p>2/4/6/10/12 times of normal fees</p>
              </div>

              <div className="right_info_modal">
                <p className="font-weight-bold mb-0">Law</p>
                <p>Company Act 2013</p>
              </div>
              <div className="right_info_modal">
                <p className="font-weight-bold mb-0">Interested Persons</p>
                <p>{eventData.interested}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-lg-14 text-center">
          <button
            className="btn btn-success mx-2"
            onClick={() => {
              onInterestedClick(eventData.id);
            }}
          >
            I'm Intrested
          </button>
          <button onClick={onHide} type="button" className="btn btn-success">
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EventListModal;
