import React from "react";

const ContactTitle = (props) => {
  const BannerStyle = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL + props.bannerImage})`,
    backgroundPosition: "center",
    backgroundRepeat: " no - repeat",
    backgroundSize: "cover",
    padding: " 60px 0",
    position: "relative"
  }
  return (
    <section className="page_title" style={BannerStyle}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="title">
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactTitle;
