import React from "react";

const Description = props => {
  return (
    <>
      <section className="blog_inner pb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-10 mx-auto">
              <div className="blog_img">
                <img
                  src={process.env.REACT_APP_IMAGE_URL + `${props?.blogImage}`}
                  className="img-fluid"
                  alt="blog"
                />
              </div>

              <div
                className="blog_info"
                dangerouslySetInnerHTML={{ __html: props?.blogDescription }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Description;
