import React, { useEffect } from "react";
import UserDashboardLayout from "../UserDashboardLayout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCartItems,
  removeAllCartItems,
  removeCartItem,
  removeItem,
} from "../../../features/slices/myCartSlice";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import Swal from "sweetalert2";
import baseAPI from "../../../api/userApi";

export let encReq;

const MyCart = () => {
  const formRef = useRef();

  const [encRequest, setEncRequest] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const access_code = process.env.REACT_APP_ACCESS_CODE;

  const user = localStorage.getItem("token");
  const email = localStorage.getItem("email");

  const { cart, cartTotal } = useSelector(state => state.myCart);

  const dispatch = useDispatch();

  const onRemoveItem = item => {
    dispatch(removeCartItem(item));
  };

  useEffect(() => {
    dispatch(getCartItems());
  }, []);

  // //
  // CCAvenue Function Call
  //
  // ***
  async function displayPayment(item) {
    // const { serviceObj, state, values } = item;

    if (item.length > 0) {
      setIsLoading(true);
      const result = await baseAPI.post("/service-booking", cart, {
        headers: {
          Authorization: `Bearer ${user}`,
          // "Content-Type": "multipart/form-data",
        },
      });

      if (!result) {
        console.log("No Data.. Some Error Occured");
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Something Went Wrong",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      if (result.data.code === 404) {
        setIsLoading(false);
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "You have already Buy this Service",
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (result.data.code === 500 || result.data.code === false) {
        setIsLoading(false);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Something Went Wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        const { order_id, state } = await result.data.data;
        let payload = {
          merchant_id: process.env.REACT_APP_MERCHENT_ID,
          order_id: order_id,
          currency: "INR",
          amount: result.data.data.gstTotal.toString(),
          redirect_url: `${process.env.REACT_APP_API_BASE_URL}/handle-response`,
          cancel_url: `${process.env.REACT_APP_API_BASE_URL}/handle-response`,
          language: "EN",
          billing_name: "",
          billing_state: state,
          billing_country: "India",
          billing_tel: "",
          billing_email: email,
        };

        if (payload) {
          await baseAPI
            .post("/payments", payload, {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            })
            .then(response => {
              setEncRequest(response.data.data);
              encReq = response.data.data;
              localStorage.setItem("encReq", encReq);
              setTimeout(() => {
                formRef.current.submit();
              }, 3000);
              return response;
            });
          setIsLoading(false);
        }
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please Add Services To Cart",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }

  // **

  return (
    <>
      <UserDashboardLayout>
        <form
          ref={formRef}
          id="nonseamless"
          action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
          method="POST"
        >
          <div className="col-sm-12 col-lg-8">
            <input
              type="hidden"
              id="encRequest"
              name="encRequest"
              value={encRequest}
            />
            <input
              type="hidden"
              name="access_code"
              id="access_code"
              value={access_code}
            />
          </div>
        </form>

        <h4>My Cart</h4>
        <hr />
        <div className="row">
          <div className="col-lg-12">
            {cart?.length !== 0 ? (
              cart?.map((item, index) => {
                return (
                  <div className="service_block m-4 p-3 shadow" key={index}>
                    <div className="row align-items-center ">
                      <div className="fav_icon"></div>
                      <div className="col-lg-9">
                        <div className="service_block_left">
                          <p className="font-weight-bold">
                            {item?.serviceObj?.title}
                          </p>
                          <p>{item?.serviceObj?.description}</p>
                          <h4 className="price">
                            ₹{item?.serviceObj?.price}/-{" "}
                            <span> only (Exclusive)</span>{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        {/* <div className="mb-2">
                          <Button
                            className="btn btn-success"
                            onClick={() => {
                              displayPayment(item);
                            }}
                          >
                            Buy Now
                          </Button>
                        </div> */}
                        <div className="mb-2">
                          <button
                            className="btn btn-outline-success"
                            onClick={() => {
                              onRemoveItem(item);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <h3 className="font-weight-light">
                    No Service Added To cart
                  </h3>
                </div>
                <div>
                  <Link className="btn btn-success" to="/all-services">
                    Go To Services
                  </Link>
                </div>
              </div>
            )}
          </div>
          {cart.length !== 0 && (
            <div className="row">
              <div className="col-lg-12 m-4 ">
                <div className="mx-3 d-flex justify-content-between align-items-center">
                  <div>
                    <h5>
                      Payable Amount :{" "}
                      <span>
                        <b>
                          {" "}
                          ₹{cartTotal} {`(Exclude)`}{" "}
                        </b>
                      </span>
                    </h5>
                  </div>
                  <div>
                    <Button
                      className="btn btn-success"
                      onClick={() => {
                        displayPayment(cart);
                      }}
                    >
                      Pay Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </UserDashboardLayout>
    </>
  );
};

export default MyCart;
