import React from "react";
import { useSelector } from "react-redux";
// import { getAllSuppoters } from "../../../features/slices/HomeSlice";

const CategoryPartner = () => {
  // const dispatch = useDispatch();
  const { suppoters } = useSelector(state => state.home);
  const patners = suppoters.filter(
    suppoter => suppoter.suppoter === "Partners"
  );

  // useEffect(() => {
  //   dispatch(getAllSuppoters());
  // }, [dispatch]);

  return (
    <section className="partners_logo">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="title">
              <h2>Our Partners</h2>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center align-items-center">
          {/* <div className="col-6 col-sm-2">
            <div className="support_img">
              <img src={s1} className="img-fluid" alt="supporter" />
            </div>
          </div> */}

          {patners.map((item, index) => {
            const { suppoter_name, image } = item;
            return (
              <div className="col-6 col-sm-2" key={index}>
                <div className="support_img">
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + `${image}`}
                    className="img-fluid"
                    alt={suppoter_name}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CategoryPartner;
