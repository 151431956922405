import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllBlogs } from "../../../features/slices/blogSlice";
import moment from "moment";
import { Link } from "react-router-dom";

const BlogList = () => {
  const { blogs } = useSelector(state => state.blogs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  return (
    <>
      <section className="blog_wrap pt-50 pb-50">
        <div className="container">
          <div className="row">
            {blogs.length == 0 ? (
              <div className="text-center">
                <h2>No Blogs Uploaded </h2>
              </div>
            ) : (
              blogs?.map((blog, i) => {
                return (
                  <div className="col-sm-4" key={i}>
                    <div className="blog_box">
                      <div className="blog_img">
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL + `${blog?.image}`
                          }
                          className="img-fluid"
                          alt="blog"
                        />
                      </div>
                      <div className="blog_info">
                        <p className="date">
                          Post Date :{" "}
                          {moment(blog?.createdAt).format("MMMM DD, YYYY")}
                        </p>
                        <h4>{blog?.title}</h4>
                        <Link to={`/blog/${blog?.slug}`}>
                          Read More <i className="fa fa-plus"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogList;
