import React from "react";
import MainComponent from "../../../components/Form/MainComponent";
const CategoryBanner = ({
  id,
  title,
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  states,
  ensurekarPrice,
  marketPrice,
  categoryId,
  service,
}) => {
  return (
    <section className="pvt_banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-7 col-md-12 col-lg-7">
            <div className="title">
              <h1>
                {title} {heading1}
              </h1>
            </div>

            <div className="info">
              <h3>{heading2}</h3>
              <h4>{heading3}</h4>
              <h3 className="consultant">{heading4}</h3>
              <p>{heading5}</p>
            </div>
          </div>
          <div className="col-sm-5 col-md-12 col-lg-5">
            <div className="partner_form">
              <MainComponent
                id={id}
                states={states}
                ensurekarPrice={ensurekarPrice}
                marketPrice={marketPrice}
                categoryId={categoryId}
                service={service}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryBanner;
