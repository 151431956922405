import baseAPI from "../../api/userApi";

const getCommonDataService = async () => {
  try {
    return await baseAPI.get("/common-data");
  } catch (error) {
    console.log(error);
  }
};

const fetchLegalService = async () => {
  try {
    return await baseAPI.get("/list-legal-service");
  } catch (error) {
    console.log(error);
  }
};

const getAllCityByState = async id => {
  try {
    return await baseAPI.post("/get-city", { state_id: id });
  } catch (error) {
    console.log(error);
  }
};

const getDasboardImages = async id => {
  try {
    return await baseAPI.get(`/dashboardBannerById/${1}`);
  } catch (error) {
    console.log(error);
  }
};

const getPartnerPage = async id => {
  try {
    return await baseAPI.get(`/becomePatnerPageById/${id}`);
  } catch (error) {
    console.log(error);
  }
};
const CommonService = {
  getCommonDataService,
  fetchLegalService,
  getAllCityByState,
  getDasboardImages,
  getPartnerPage,
};
export default CommonService;
