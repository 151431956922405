import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { getAllMenuService } from "../features/slices/menuServiceSlice";
import AboutUs from "../pages/AboutUs/AboutUs";
import ForgotPassword from "../pages/Authentication/Forgot/ForgotPassword";
import Login from "../pages/Authentication/Login/Login";
import Register from "../pages/Authentication/Register/Register";
import RegisterPassword from "../pages/Authentication/Register/RegisterPassword";
import ResetPassword from "../pages/Authentication/ResetPassword/ResetPassword";
import Category from "../pages/category/Category";
import ContactUs from "../pages/ContactUs/ContactUs";
import CookiePolicy from "../pages/CookiePolicy/CookiePolicy";
import { Home } from "../pages/Home/Home";
import Partner from "../pages/Partner/Partner";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "../pages/RefundPolicy/RefundPolicy";
import TermsOfServices from "../pages/TermsOfServices/TermsOfServices";
import CancelPayment from "../pages/ThankYou/CancelPayment";
import ThankYou from "../pages/ThankYou/ThankYou";
import ThankYouPlan from "../pages/ThankYou/ThankYouPlan";
import AllService from "../pages/UserBoard/AllServices/AllServices";
import Dashboard from "../pages/UserBoard/Dashboard/Dashboard";
import EventList from "../pages/UserBoard/EventList/EventList";
import MyServices from "../pages/UserBoard/MyServices/MyServices";
import Payment from "../pages/UserBoard/Payment/Payment";
import Profile from "../pages/UserBoard/Profile/Profile";
// import UserDashboardLayout from "../pages/UserBoard/UserDashboardLayout";
import PrivateRoutes from "./PrivateRoutes";
import MyCart from "../pages/UserBoard/MyCart/MyCart";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import Blog from "../pages/Blog/Blog";
import { getAllBlogs } from "../features/slices/blogSlice";
import BlogDetails from "../pages/BlogDetails/BlogDetails";

const AppRoutes = () => {
  const { categorys } = useSelector(state => state.menuService);
  const { blogs } = useSelector(state => state.blogs);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllMenuService());
    dispatch(getAllBlogs());
  }, [dispatch]);

  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="registerpassword" element={<RegisterPassword />} />
        <Route path="forgot" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="/" element={<Home />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="contactus" element={<ContactUs />} />
        <Route path="partner" element={<Partner />} />
        <Route path="terms-of-service" element={<TermsOfServices />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="blogs" element={<Blog />} />
        <Route path="*" element={<PageNotFound />} />
        {categorys?.map(category => {
          return category.sub_categories.map((subCat, index) => {
            const { id, path } = subCat;

            const route = `/${path}`;
            return (
              <Route path={route} element={<Category id={id} />} key={index} />
            );
          });
        })}

        {blogs?.map((blog, index) => {
          return (
            <Route
              path={`blog/${blog?.slug}`}
              element={<BlogDetails id={blog?.id} />}
              key={index}
            />
          );
        })}

        {/* Privates Routes  */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoutes>
              <Dashboard />
            </PrivateRoutes>
          }
        />
        <Route
          path="/all-services"
          element={
            <PrivateRoutes>
              <AllService />
            </PrivateRoutes>
          }
        />
        <Route
          path="/my-services"
          element={
            <PrivateRoutes>
              <MyServices />
            </PrivateRoutes>
          }
        />
        <Route
          path="/event-list"
          element={
            <PrivateRoutes>
              <EventList />
            </PrivateRoutes>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoutes>
              <Profile />
            </PrivateRoutes>
          }
        />
        <Route
          path="/payment"
          element={
            <PrivateRoutes>
              <Payment />
            </PrivateRoutes>
          }
        />

        <Route
          path="/my-cart"
          element={
            <PrivateRoutes>
              <MyCart />
            </PrivateRoutes>
          }
        />

        <Route
          path="/thank-you"
          element={
            <PrivateRoutes>
              <ThankYou />
            </PrivateRoutes>
          }
        />
        <Route
          path="/thank-you-plan"
          element={
            <PrivateRoutes>
              <ThankYouPlan />
            </PrivateRoutes>
          }
        />
        <Route
          path="/cancel-payment"
          element={
            <PrivateRoutes>
              <CancelPayment />
            </PrivateRoutes>
          }
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
