import React from "react";
import aboutus_banner from "../../../assets/images/about_banner.jpg";
const SectionContent = ({ description, contentImage }) => {
  return (
    <section className="section_content">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-6">
            {/* <div class="info"> */}
            {/* <div className="title">
              <h2>What is Ensurekar About ?</h2>
            </div> */}
            <div>
              <p>{description}</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-6">
            <div className="right_info">
              <div className="about_img">
                <img
                  src={process.env.REACT_APP_IMAGE_URL + contentImage}
                  className="img-fluid"
                  alt="about"
                />
                {/* <img src={aboutus_banner} className="img-fluid" alt="about" /> */}
              </div>
              {/* <div className="count">
                <h3>12</h3>
                <p>years of experiences</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionContent;
