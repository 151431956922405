import baseAPI from "../../api/userApi";
import { authHeader } from "../../helpers/authHeaders";

const authEmail = async data => {
  try {
    return await baseAPI.post("/create-email", data);
  } catch (error) {
    console.log(error);
  }
};

const authRegister = async data => {
  try {
    return await baseAPI.post("/create-password", data);
  } catch (error) {
    console.log(error);
  }
};

const login = async data => {
  try {
    return await baseAPI.post("/login", data);
  } catch (error) {
    console.log(error);
  }
};

const forgetPassword = async data => {
  try {
    return await baseAPI.post("/forget-Password", data);
  } catch (error) {
    console.log(error);
  }
};

const resetPassword = async data => {
  const obj = {
    newPassword: data.new_password,
    contact_no: data.mobile_number,
    // oldPassword: data.old_password,
  };
  try {
    return await baseAPI.post("/changePassword", obj, {
      headers: authHeader(),
    });
  } catch (error) {
    console.log(error);
  }
};

const googleLoginService = async data => {
  const obj = {
    ...data,
    googleUser: true,
  };
  try {
    return await baseAPI.post("/google-login", obj);
  } catch (error) {
    console.log(error);
  }
};

const AuthService = {
  authRegister,
  login,
  authEmail,
  forgetPassword,
  resetPassword,
  googleLoginService,
};
export default AuthService;
