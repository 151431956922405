import React from "react";
import logo from "../../../assets/images/logo.png";
import forgot from "../../../assets/images/forgotpassword.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import ShowError from "../../../errors/ShowError";
import { forgotPasswordValidationSchema } from "../../../helpers/validators/forgotPassword";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "../../../features/slices/authSlice";

const ForgotPassword = () => {
  const { setting } = useSelector(state => state.settings);

  const { header_logo } = setting;

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      dispatch(forgetPassword(values));
      resetForm({});
      setSubmitting(false);
    },
  });

  return (
    <section className="login_sec forgot_password">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 text-center ">
            <div className="logo">
              <img
                src={process.env.REACT_APP_IMAGE_URL + header_logo}
                className="img-fluid"
                alt="logo"
              />
            </div>

            <div className="login_info">
              <div className="col-lg-8 mx-auto">
                <h1>Forgot Password</h1>
                <p>
                  Enter the email address associated with your Ensurekar
                  account. We will email you a link to reset your password
                </p>

                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          value={formik.values.email}
                          name="email"
                          placeholder="Email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <ShowError>{formik.errors.email}</ShowError>
                        )}
                      </div>
                    </div>
                  </div>

                  <button className="btn btn-success" type="submit">
                    send reset link
                  </button>
                  <p>
                    Already have an account? <Link to="/login">Login</Link>{" "}
                  </p>
                </form>
              </div>
            </div>
          </div>

          <div className="col-lg-6 text-right px-0">
            <div className="login_img">
              <img src={forgot} className="img-fluid" alt="login" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
